<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <div class="px-6 pt-2">
      <h5 class="text-h5 font-weight-bold text-typo">
        {{ title }}
      </h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <div class="d-sm-flex align-center ms-2">
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          @click="onResetClick"
          class="font-weight-bolder btn-outline-secondary py-5 px-6 ms-auto"
          small
        >
          {{ $t("Reset") }}
        </v-btn>
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-primary
            bg-gradient-primary
            py-5
            px-6
            ms-2
          "
          small
          @click="onAddClick"
        >
          {{ btn_title }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "customer-add-account",
  props: {
    title: {
      type: String,
      default: "",
    },
    btn_title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    onAddClick() {
      this.$emit("onAdd");
    },
    onResetClick() {
      this.$emit("onClear");
    },
  },
};
</script>
