<template>
  <v-card class="card-shadow border-radius-xl pb-2">
    <div class="px-2 py-4">
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("Basic Info") }}</h5>
    </div>

    <v-row class="px-2 py-4">
      <v-col sm="2" cols="12" class="py-0">
        <common-label label="全名"></common-label>
        <v-text-field
          hide-details
          outlined
          placeholder="ex:錢多多"
          :class="common_text_edit_class"
          v-model="info.full_name"
          ref="fullname"
        >
        </v-text-field>
      </v-col>
      <v-col sm="1" cols="12" class="py-0">
        <common-label label="性別"></common-label>
        <v-select
          :items="genders"
          v-model="info.gender"
          color="white"
          class="input-style font-size-input border-radius-md select-style mt-2"
          outlined
          single-line
          height="36"
          clearable
        >
        </v-select>
      </v-col>
      <v-col sm="3" cols="12" class="py-0">
        <common-label label="行業"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="info.industry"
        >
        </v-text-field>
      </v-col>
      <v-col sm="3" cols="12" class="py-0">
        <!-- <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("Company")
          }}</label> -->
        <common-label label="公司"></common-label>
        <v-text-field
          hide-details
          outlined
          :placeholder="$t('Company')"
          class="font-size-input border border-radius-md mt-2"
          v-model="info.company"
        >
        </v-text-field>
      </v-col>
      <v-col sm="3" cols="12" class="py-0">
        <common-label label="職稱"></common-label>
        <v-text-field
          hide-details
          outlined
          :placeholder="$t('Job Title')"
          class="font-size-input border border-radius-md mt-2"
          v-model="info.job"
        >
        </v-text-field>
      </v-col>

      <v-col sm="3" cols="12" class="py-0">
        <!-- <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("Customer Type")
          }}</label> -->
        <common-label label="客戶類型"></common-label>
        <v-select
          :items="customer_type_enum_str"
          v-model="info.customer_type"
          color="white"
          class="input-style font-size-input border-radius-md select-style mt-2"
          outlined
          single-line
          height="36"
        >
        </v-select>
      </v-col>

      <v-col sm="3" cols="12" class="py-0">
        <common-label label="身份證/統編"></common-label>
        <v-text-field
          hide-details
          outlined
          :placeholder="info.id_partial"
          class="font-size-input border border-radius-md mt-2"
          v-model="info.id_full"
        >
        </v-text-field>
      </v-col>
      <v-col sm="3" cols="12" class="py-0">
        <common-label label="生日"></common-label>
        <v-menu
          v-model="info.menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              outlined
              class="font-size-input border border-radius-md mt-2"
              :value="displayChineseYear(info.birth)"
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="info.birth"
            :year-format="formatChineseYear"
            locale="zh-tw"
            @input="info.menu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col sm="3" cols="12" class="py-0">
        <common-label label="Email"></common-label>
        <v-text-field
          hide-details
          outlined
          placeholder="ex:example@email.com"
          class="font-size-input border border-radius-md mt-2"
          v-model="info.email"
        >
        </v-text-field>
      </v-col>
      <v-col sm="3" cols="12" class="py-0">
        <common-label label="客戶來源"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="info.customer_source"
        >
        </v-text-field>
      </v-col>
      <v-col sm="3" cols="12" class="py-0">
        <common-label label="資料來源"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="info.data_source"
        >
        </v-text-field>
      </v-col>
      <v-col class="py-0">
        <common-label label="群組"></common-label>
        <!-- <v-text-field
            hide-details
            outlined
            class="font-size-input border border-radius-md mt-2"
            v-model="info.data_source"
          >
          </v-text-field> -->
        <treeselect
          v-model="selected"
          :multiple="true"
          :flat="true"
          :autoSelectAncestors="true"
          :autoDeselectAncestors="true"
          :default-expand-level="0"
          :options="group_options"
          :searchable="searchable"
          class="font-size-input border border-radius-md mt-2"
          placeholder="資料來源"
          noChildrenText="無子群組"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import {
  customer_type_enum,
  customer_type_enum_str,
  common_text_edit_class,
} from "@/definitions.js";
import CommonLabel from "@/components/CRM/CommonLabel.vue";
import CommonUtility from "@/util/CommonUtility.js";
import CustomerGroupMixin from "@/views/Pages/CRM/CustomerGroupMixin.vue";
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "customer-basic-info",
  props: {
    info: {
      type: Object,
      default: () => ({
        id: null,
        gender: null,
        full_name: null,
        id_full: null,
        id_partial: null,
        email: null,
        company: null,
        job: null,
        customer_source: null,
        customer_type: null,
        data_source: null,
        birth: null,
        menu: false,
        groups: [],
      }),
    },
  },
  data() {
    return {
      customer_type_enum_str: customer_type_enum_str,
      genders: [
        { text: "未知", value: 0 },
        { text: "女", value: 1 },
        { text: "男", value: 2 },
      ],
      common_text_edit_class: common_text_edit_class,

      // ["Female", "Male", "UnKnown"],
      searchable: true,
      selected: [],
    };
  },

  mounted() {
    this.getAllGroups();
  },
  mixins: [CustomerGroupMixin],
  computed: {
    readOnly() {
      // return this.edit_mode == edit_mode_enum.View;
      return false;
    },
  },
  components: {
    CommonLabel,
    Treeselect,
  },
  watch: {
    "info.groups": {
      handler: function (val) {
        this.parseGroup(val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setDefaultFocus() {
      this.$refs.fullname.focus();
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
    parseGroup(group) {
      if (group == null || group == undefined || group.length == 0) {
        this.selected = [];
        return [];
      }
      // get all id in the group and append to this.selected
      let group_ids = [];
      group.forEach((item) => {
        group_ids.push(item.group_id);
      });
      this.selected = group_ids;
    },

    getGroupInfo() {
      //  iterate over the groups and get the group info
      let group_info = [];
      // get group id and id from group_options and exist in selected array
      this.selected.forEach((selected_id) => {
        // find group_id = group_id in this.info.groups
        let group = this.findGroupById(selected_id);
        // console.log("group", group);
        if (group != null) {
          group_info.push({
            group_id: selected_id,
            owner_id: this.info.id,
            id: group.id,
          });
        } else {
          group_info.push({
            group_id: selected_id,
            owner_id: this.info.id,
            id: null,
          });
        }
      });
      return group_info;
    },
    findGroupById(id) {
      let group = null;
      const findGroup = (options) => {
        if (options == null || options == undefined || options.length == 0) {
          return;
        }
        options.forEach((item) => {
          if (item.group_id == id && item.owner_id == this.info.id) {
            group = item;
          } else {
            findGroup(item.children);
          }
        });
      };
      findGroup(this.info.groups);
      return group;
    },
  },
};
</script>
