<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <customer-detail-dialog
      :edit_mode="edit_customer_mode"
      :edited_item="edited_customer_item"
      v-on:close="close"
      v-on:create_customer="onCreateCustomerDone"
      v-on:update_customer="getCustomers"
    ></customer-detail-dialog>
    <v-card-text>
      <h6 class="text-h6 font-weight-bold text-typo">{{ title }}</h6>
      <v-divider></v-divider>
      <v-col cols="12" v-if="related_info_count > 0">
        <v-data-table
          :headers="related_headers"
          :options.sync="options"
          :items="related_info"
          :server-items-length="related_info_count"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.comment="{ item }">
            <v-text-field
              v-model="item.comment"
              hide-details
              outlined
              placeholder="註解"
              class="font-size-input placeholder-er border border-radius-md"
              :disabled="item.readonly"
            >
            </v-text-field>
          </template>
          <template v-slot:item.inquire_date="{ item }">
            <v-menu
              v-model="item.inquire_date_menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="item.readonly"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  outlined
                  class="font-size-input border border-radius-md"
                  :value="displayChineseYear(item.inquire_date)"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.readonly"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="item.inquire_date"
                :year-format="formatChineseYear"
                locale="zh-tw"
                @input="item.inquire_date_menu = false"
              >
              </v-date-picker>
            </v-menu>
          </template>
          <template v-slot:item.total_price="{ item }">
            <v-text-field
              v-model="item.total_price"
              hide-details
              outlined
              placeholder="總價"
              class="font-size-input placeholder-er border border-radius-md"
              :disabled="item.readonly"
              suffix="萬"
            >
            </v-text-field>
          </template>
          <template v-slot:item.signing_date="{ item }">
            <v-menu
              v-model="item.inquire_date_menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="item.readonly"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  outlined
                  class="font-size-input placeholder-er border border-radius-md"
                  :value="displayChineseYear(item.signing_date)"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.readonly"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="item.signing_date"
                :year-format="formatChineseYear"
                locale="zh-tw"
                @input="item.signing_date_menu = false"
              >
              </v-date-picker>
            </v-menu>
          </template>
          <template v-slot:item.singing_price="{ item }">
            <v-text-field
              v-model="item.singing_price"
              hide-details
              outlined
              placeholder="簽約金額"
              class="font-size-input placeholder-er border border-radius-md"
              :disabled="item.readonly"
              suffix="萬"
            >
            </v-text-field>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-default
                px-6
                mx-2
                my-2
                text-end
              "
              @click="onViewCustomer(item)"
            >
              檢視
            </v-btn>
            <v-btn
              v-if="!item.readonly"
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-danger
                px-6
                mx-2
                my-2
                text-end
              "
              @click="removeRelated(item)"
            >
              移除
            </v-btn>
          </template>
        </v-data-table>
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#cb0c9f"
          v-model="page"
          :length="getPageCount()"
          circle
          :total-visible="15"
        ></v-pagination>
      </v-col>
    </v-card-text>

    <v-card-text>
      <h6 class="text-h6 font-weight-bold text-typo">搜尋客戶</h6>
      <v-row>
        <v-col>
          <v-text-field
            v-model="customerInfo.full_name"
            hide-details
            outlined
            placeholder="姓名"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="customerInfo.full_id"
            hide-details
            outlined
            placeholder="身份證字號"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="customerInfo.company"
            hide-details
            outlined
            :placeholder="$t('Company')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="customerInfo.address"
            hide-details
            outlined
            :placeholder="$t('Address')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="customerInfo.phone"
            hide-details
            outlined
            :placeholder="$t('Phone')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn
            class="font-weight-bolder btn-default bg-gradient-default mr-2"
            :ripple="false"
            elevation="0"
            @click="searchCustomers()"
          >
            {{ $t("Search Customer") }}
            <v-icon right dark> mdi-account-search-outline </v-icon>
          </v-btn>
          <v-btn
            class="font-weight-bolder btn-outline-secondary mr-2"
            :ripple="false"
            elevation="0"
            @click="resetSearch()"
          >
            {{ $t("Reset") }}
            <v-icon right dark> mdi-restart </v-icon>
          </v-btn>
          <v-btn
            :ripple="false"
            elevation="0"
            class="font-weight-bolder btn-default bg-gradient-primary"
            @click="onCreateCustomer()"
          >
            新增客戶
            <v-icon right dark> mdi-account-plus-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="items"
            :server-items-length="items_count"
            :loading="loading"
            hide-default-footer
            disable-pagination
            v-model="selected_customer"
            @click:row="rowClick"
            single-select
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-primary
                  px-6
                  mx-2
                  my-2
                  text-end
                "
                @click="addRelatedItem(item, false)"
              >
                {{ add_title }}
              </v-btn>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-default
                  px-6
                  mx-2
                  my-2
                  text-end
                "
                @click="onViewCustomer(item)"
              >
                檢視
              </v-btn>
            </template>
          </v-data-table>
          <v-pagination
            prev-icon="fa fa-angle-left"
            next-icon="fa fa-angle-right"
            class="pagination"
            color="#cb0c9f"
            v-model="page"
            :length="getPageCount()"
            circle
            :total-visible="10"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  case_related_type,
  inquire_headers,
  transaction_headers,
} from "@/case.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { defaultCustomer } from "@/customers.js";
import { edit_mode_enum } from "@/definitions.js";
import CommonUtility from "@/util/CommonUtility.js";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import CustomerMixin from "./CustomerMixin.vue";
import _ from "lodash";

Vue.use(VueSweetalert2);
export default {
  name: "case-related-customer",
  props: {
    propInfo: {
      type: Array,
      default: () => [],
    },
    propType: {
      type: Number,
      default: 0,
    },
    propCaseName: {
      type: String,
      default: "",
    },
    propCaseType: {
      type: String,
      default: "",
    },
  },
  mixins: [CustomerMixin, HttpCommonMixin],
  components: {
    CustomerDetailDialog: () => import("../CustomerDetailDialog.vue"),
  },
  computed: {
    title() {
      switch (this.propType) {
        case case_related_type.Inquires:
          return "客戶詢問";
        case case_related_type.Transactions:
          return "交易紀錄";
        case case_related_type.Owners:
          return "所有權人";
      }
      return "客戶詢問";
    },
    add_title() {
      switch (this.propType) {
        case case_related_type.Inquires:
          return "詢問";
        case case_related_type.Transactions:
          return "交易";
        case case_related_type.Owners:
          return "新增";
      }
      return "詢問";
    },
    related_headers() {
      switch (this.propType) {
        case case_related_type.Inquires:
          return this.inquire_headers;
        case case_related_type.Transactions:
          return this.transaction_headers;
        case case_related_type.Owners:
          return [];
      }
      return this.inquire_headers;
    },
  },
  data() {
    return {
      case_related_type: case_related_type,
      inquire_headers: inquire_headers,
      transaction_headers: transaction_headers,
      selected_customer: [],
      customerInfo: {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
      },
      // edit
      edit_mode_enum: edit_mode_enum,
      edit_customer_mode: edit_mode_enum.View,
      defaultCustomer: defaultCustomer,
      edited_customer_item: {}, // for customer
      // inquires, transactions,..etc
      related_info: [],
      related_info_count: 0,
      // debug
      debug: true,
    };
  },
  mounted() {
    this.parseRelatedInfo(this.propInfo);
  },
  watch: {
    propInfo: {
      handler: function (val) {
        this.parseRelatedInfo(val);
      },
      deep: true,
    },
  },
  methods: {
    parseRelatedInfo(val) {
      this.related_info = [];
      if (val != null) {
        console.log("[", this.title, "] parse related info", val);
        for (let i = 0; i < val.length; i++) {
          // find the case_id and customer
          const customer = val[i].customer;
          //merge all attributes of customer into val[i] except id
          for (let key in customer) {
            if (key != "id") {
              val[i][key] = customer[key];
            } else {
              val[i]["customer_id"] = customer[key];
            }
          }
          this.addRelatedItem(val[i], true);
        }
      }
      this.related_info_count = this.related_info.length;
    },
    resetSearch() {
      this.customerInfo = {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
      };
      this.items = [];
      this.items_count = 0;
    },
    searchCustomers() {
      this.getCustomerByCondition(this.customerInfo);
    },
    close() {
      this.edit_customer_mode = this.edit_mode_enum.View;
      console.log("close edit mode");
    },
    rowClick(item, row) {
      this.selected_customer = [item];
    },
    onCreateCustomerDone(item) {
      this.items.push(item);
      this.items_count = this.items.length;
    },
    onCreateCustomer() {
      this.edited_customer_item = _.cloneDeep(this.defaultCustomer);
      this.edit_customer_mode = this.edit_mode_enum.Create;
    },
    onViewCustomer(item) {
      console.log("onViewCustomer", item);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/${item.id}`;
      this.doHttpGet(url, null, (res) => {
        console.log("get customer", res);
        this.edited_customer_item = res;
        this.edit_customer_mode = this.edit_mode_enum.View;
      });
    },
    addRelatedItem(item, readonly) {
      console.log("addRelatedItem", item, readonly);
      if (
        readonly == false &&
        this.related_info.find((x) => x.customer_id === item.id)
      ) {
        console.log("already exist");
        return;
      }

      item.readonly = readonly;
      item.inquire_date_menu = false;
      item.signing_date_menu = false;
      this.related_info.push(item);
      this.related_info_count = this.related_info.length;
    },
    removeRelated(item) {
      this.related_info = this.related_info.filter((x) => x.id != item.id);
      this.related_info_count = this.related_info.length;
    },
    getFillFields(customer) {
      switch (this.propType) {
        case case_related_type.Inquires:
          return {
            comment: customer.comment,
            inquire_date: customer.inquire_date,
          };
        case case_related_type.Transactions:
          return {
            customer_id: customer.id,
            total_price: customer.total_price,
            signing_date: customer.signing_date,
            singing_price: customer.singing_price,
          };
        case case_related_type.Owners:
          return {};
      }
      return this.inquire_headers;
    },
    getRelatedInfo() {
      return this.related_info
        .filter((x) => x.readonly == false)
        .map((customer) => {
          let rid = this.related_info.find(
            (x) => x.customer_id === customer.id
          );
          rid = rid ? rid.id : null;
          let extra = this.getFillFields(customer);
          let rObj = {
            id: rid,
            customer_id: customer.id,
            case_name: this.propCaseName,
            type: this.propCaseType,
          };
          rObj = { ...rObj, ...extra };
          return rObj;
        });
    },
    print_log(...args) {
      if (this.debug) {
        console.log("[", this.title, "]", ...args);
      }
    },
    clear() {
      this.print_log("clear case related customer");
      this.items = [];
      this.items_count = 0;
      this.selected_customer = [];
      this.related_info = [];
      this.related_info_count = 0;
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
  },
};
</script>

<style scoped>
/deep/ tr.v-data-table__selected {
  background: #7d92f5 !important;
}
</style>
