<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <customer-detail-dialog
      :propEditMode="edit_mode_enum.View"
      :propEditItem="view_customer_item"
      :propDisplay="view_dialog"
      v-on:close="close"
    ></customer-detail-dialog>
    <v-card-title>
      <h5 class="text-h5 font-weight-bold text-typo">案件</h5>
      <v-spacer></v-spacer>

      <v-btn
        :ripple="false"
        elevation="0"
        color="#fff"
        class="font-size-input btn-outline-secondary mt-2"
        @click="resetFilter()"
      >
        {{ $t("Reset") }}
        <v-icon right dark> mdi-close-circle-outline </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="related_headers"
        :options.sync="options"
        :items="related_people"
        hide-default-footer
        disable-pagination
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="12" sm="1" class="py-0 px-0">
              <v-text-field
                hide-details
                outlined
                class="text-md font-weight-bolder mt-2"
                :value="getCaseNumber()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2" class="py-0">
              <v-select
                :items="clients"
                v-model="client_id"
                class="font-size-input border border-radius-md mt-2"
                outlined
                single-line
                hide-details
                @change="onClientChange(client_id)"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="1" class="py-0 px-0 text-right">
              <v-text-field
                hide-details
                outlined
                class="text-md font-weight-bolder mt-2"
                :value="getOwnerCases()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2" class="py-0">
              <v-select
                :items="owners"
                v-model="owner_id"
                class="font-size-input border border-radius-md mt-2"
                outlined
                single-line
                hide-details
                @change="onOwnerChange(owner_id)"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="1" class="py-0 px-0">
              <v-text-field
                hide-details
                outlined
                class="text-md font-weight-bolder mt-2"
                :value="getAgentCases()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2" class="py-0">
              <v-select
                :items="agents"
                v-model="agent_id"
                class="font-size-input border border-radius-md mt-2"
                outlined
                single-line
                hide-details
                @change="onAgentChange(agent_id)"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="1" class="py-0 px-0">
              <v-text-field
                hide-details
                outlined
                class="text-md font-weight-bolder mt-2"
                :value="getBuyerCases()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="2" class="py-0">
              <v-select
                :items="buyers"
                v-model="buyer_id"
                class="font-size-input border border-radius-md mt-2"
                outlined
                single-line
                hide-details
                @change="onBuyerChange(buyer_id)"
              >
              </v-select>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.parent_relationship="{ item }">
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.parent_relationship"
          >
          </v-text-field>
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.type | getText(related_person_type_enum_str) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div>
            <v-btn
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-default
                py-5
                px-6
                mt-2
                mb-2
                me-2
                text-end
              "
              @click="viewCustomer(item.customer_id)"
            >
              {{ $t("View") }}
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  object_type_enum,
  object_type_enum_str,
  related_person_type_enum_str,
} from "@/case.js";
import { edit_mode_enum } from "@/definitions.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
export default {
  name: "customer-case",
  props: {
    propCases: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CustomerDetailDialog: () => import("../CustomerDetailDialog.vue"),
  },
  mixins: [HttpCommonMixin],
  data(vm) {
    return {
      object_type_enum: object_type_enum,
      object_type_enum_str: object_type_enum_str,
      related_person_type_enum_str: related_person_type_enum_str,
      edit_mode_enum: edit_mode_enum,
      phone_type_title: vm.$i18n.t("PhoneType"),
      transactions: [],
      options: {},
      case_id: null,
      related_headers: [
        {
          text: "姓名",
          value: "name",
          width: "10%",
        },
        {
          text: "類型",
          value: "type",
          width: "10%",
        },
        {
          text: "電話",
          value: "phone",
          width: "20%",
        },
        {
          text: "手機",
          value: "mobile",
          width: "20%",
        },
        {
          text: "地址",
          value: "address",
          width: "20%",
        },
        {
          text: "動作",
          value: "actions",
          width: "10%",
        },
      ],
      related_people: [],
      clients: [], //委託
      client_id: null,
      owners: [], // 屋主
      owner_id: null,
      agents: [], //仲介
      agent_id: null,
      buyers: [], //買家
      buyer_id: null,
      view_customer_item: {},
      view_dialog: false,
    };
  },
  watch: {
    propCases: {
      handler: function (val, oldVal) {
        this.setCases(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.setCases(this.prop_ids);
  },

  methods: {
    setCases(val) {
      this.reset();
      if (val != null) {
        // console.log("set cases", val)
        // iterate the array if type is 0  put into  this.clients , if type is 1 put into this.owners
        // if type is 2 put into this.agents
        for (let i = 0; i < val.length; i++) {
          if (val[i].type == 0) {
            this.clients.push(val[i]);
          } else if (val[i].type == 1) {
            this.owners.push(val[i]);
          } else if (val[i].type == 2) {
            this.agents.push(val[i]);
          } else if (val[i].type == 3) {
            this.buyers.push(val[i]);
          }
        }
      }
      this.related_people = [];
    },
    resetFilter() {
      this.client_id = null;
      this.owner_id = null;
      this.agent_id = null;
      this.buyer_id = null;
      this.related_people = [];
    },
    reset() {
      this.clients = [];
      this.owners = [];
      this.agents = [];
      this.buyers = [];
      this.resetFilter();
    },
    resetIDsAndFetch(case_id, keepID) {
      const ids = ["client_id", "owner_id", "agent_id", "buyer_id"];
      ids.forEach((id) => {
        if (id !== keepID) {
          this[id] = null;
        }
      });
      this.getRelatedPeopleByID(case_id);
    },
    onClientChange(case_id) {
      this.resetIDsAndFetch(case_id, "client_id");
    },
    onOwnerChange(case_id) {
      this.resetIDsAndFetch(case_id, "owner_id");
    },
    onAgentChange(case_id) {
      this.resetIDsAndFetch(case_id, "agent_id");
    },
    onBuyerChange(case_id) {
      this.resetIDsAndFetch(case_id, "buyer_id");
    },
    getRelatedPeopleByID(case_id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/${case_id}/case-related`;
      this.doHttpGet(url, null, this.setResult);
    },
    setResult(response) {
      console.log("setResult", response);
      this.related_people = response;
    },
    getCaseNumber() {
      return `委託案件: ${this.clients.length}`;
    },
    getOwnerCases() {
      return `屋主案件: ${this.owners.length}`;
    },
    getAgentCases() {
      return `中人案件: ${this.agents.length}`;
    },
    getBuyerCases() {
      return `買家案件: ${this.buyers.length}`;
    },
    viewCustomer(customer_id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/${customer_id}`;
      this.doHttpGet(url, null, (res) => {
        console.log("get customer", res);
        this.view_customer_item = res;
        this.view_dialog = true;
      });
    },
    close() {
      this.view_customer_item = {};
      this.view_dialog = false;
    },
  },
};
</script>
<style scoped>
.v-data-table-header-mobile {
  display: none !important;
}
</style>
