<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <v-card-title>
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("Phone") }}</h5>
      <v-spacer></v-spacer>

      <v-btn
        v-if="propEditMode > 1"
        :ripple="false"
        elevation="0"
        color="#fff"
        class="py-5 px-6 btn-danger bg-gradient-primary"
        small
        @click="addPhone()"
      >
        {{ $t("Add Phone") }}
        <v-icon right dark> mdi-phone-plus </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-6 pb-2 pt-0">
      <v-row v-for="(item, index) in phoneInfo" :key="index" class="py-0 my-0">
        <v-col cols="1" class="py-0">
          <v-text-field
            hide-details
            outlined
            class="text-md font-weight-bolder mx-2"
            :value="phone_type_title"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" class="py-0">
          <v-select
            v-model="item.type"
            :items="phone_type"
            class="input-style font-size-input border-radius-md select-style"
            outlined
            single-line
            height="36"
          >
          </v-select>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            hide-details
            outlined
            class="font-size-input border border-radius-md"
            v-model="item.number"
          >
          </v-text-field>
        </v-col>
        <v-col class="text-right px-1 py-0" v-show="!readonly">
          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="py-5 px-6 btn-danger bg-gradient-danger"
            small
            @click="removePhone(item)"
          >
            刪除電話
            <v-icon right dark> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  phone_type,
  common_text_edit_class,
  edit_mode_enum,
} from "@/definitions.js";
export default {
  name: "customer-phone-info",
  props: {
    propEditMode: {
      type: Number,
      default: 0,
    },
    phoneInfo: {
      type: Array,
      default: () => [
        {
          number: null,
          type: 0,
        },
        {
          number: null,
          type: 2,
        },
      ],
    },
  },

  data(vm) {
    return {
      edit_mode_enum: edit_mode_enum,
      phone_type: phone_type,
      phone_type_title: vm.$i18n.t("PhoneType"),
      common_text_edit_class: common_text_edit_class,
    };
  },
  computed: {
    readonly() {
      return (
        this.propEditMode === edit_mode_enum.View ||
        this.propEditMode === edit_mode_enum.Hidden
      );
    },
  },
  methods: {
    reset() {},
    addPhone() {
      this.$emit("addPhone");
    },
    removePhone(item) {
      // get the index of the item in array (phoneInfo)
      const index = this.phoneInfo.indexOf(item);

      console.log("remove phone index", index);
      this.$emit("removePhone", index);
    },
  },
  mounted() {},
};
</script>
