<template></template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "UserMixin",
  mixins: [HttpCommonMixin],
  data() {
    return {
      items: [],
      items_count: 0,
      itemsPerPage: 10,
      loading: false,
      page: 1,
      options: {},
      headers: [
        {
          text: "帳號",
          cellClass: "border-bottom",
          sortable: false,
          value: "username",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "姓",
          cellClass: "border-bottom",
          sortable: false,
          value: "last_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "名",
          cellClass: "border-bottom",
          sortable: false,
          value: "first_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "職位",
          cellClass: "border-bottom",
          sortable: false,
          value: "job_title",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "手機",
          cellClass: "border-bottom",
          sortable: false,
          value: "mobile_phone",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Email",
          cellClass: "border-bottom",
          sortable: false,
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "actions",
          cellClass: "border-bottom",
          sortable: false,
          align: "end",
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
      ],
      userInfo: {
        username: null,
        email: null,
        first_name: null,
        last_name: null,
        exclude_super_user: false,
      },
    };
  },
  methods: {
    getUser() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/`;
      let config = {
        params: {
          page: this.page,
          size: this.itemsPerPage,
        },
      };
      this.doHttpGet(url, config, this.setResult);
    },
    setResult(result) {
      this.items_count = result.total;
      this.items = result.items;
    },
    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    getUserByCondition() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };
      const info = this.userInfo;
      for (const [key, value] of Object.entries(info)) {
        if (value != null) {
          params[key] = value;
        }
      }
      this.loading = true;
      this.doHttpGet(
        url,
        params,
        this.setResult,
        (error) => console.log(error),
        () => (this.loading = false)
      );
    },
    getSalesByCaseID(case_id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/${case_id}/sales`;
      this.doHttpGet(url, null, this.setSales, null);
    },
    setSales(data) {
      console.log("msetSales", data);
    },
  },
};
</script>
