

const city_info = [
  { text: "臺北市", value: "A" },
  { text: "臺中市", value: "B" },
  { text: "基隆市", value: "C" },
  { text: "臺南市", value: "D" },
  { text: "高雄市", value: "E" },
  { text: "新北市", value: "F" },
  { text: "宜蘭縣", value: "G" },
  { text: "桃園市", value: "H" },
  { text: "嘉義市", value: "I" },
  { text: "新竹縣", value: "J" },
  { text: "苗栗縣", value: "K" },
  { text: "南投縣", value: "M" },
  { text: "彰化縣", value: "N" },
  { text: "新竹市", value: "O" },
  { text: "雲林縣", value: "P" },
  { text: "嘉義縣", value: "Q" },
  { text: "屏東縣", value: "T" },
  { text: "花蓮縣", value: "U" },
  { text: "臺東縣", value: "V" },
  // { text: "[W] 金門縣", value: "W" },
  // { text: "[X] 澎湖縣", value: "X" },
  // { text: "[Z] 連江縣", value: "Z" },
];

const district_info =
{
  'A': [{ 'value': '01', 'text': '松山區' }, { 'value': '02', 'text': '大安區' }, { 'value': '03', 'text': '中正區' }, { 'value': '05', 'text': '萬華區' }, { 'value': '09', 'text': '大同區' }, { 'value': '10', 'text': '中山區' }, { 'value': '11', 'text': '文山區' }, { 'value': '13', 'text': '南港區' }, { 'value': '14', 'text': '內湖區' }, { 'value': '15', 'text': '士林區' }, { 'value': '16', 'text': '北投區' }, { 'value': '17', 'text': '信義區' }],
  'B': [{ 'value': '01', 'text': '中區' }, { 'value': '02', 'text': '東區' }, { 'value': '03', 'text': '南區' }, { 'value': '04', 'text': '西區' }, { 'value': '05', 'text': '北區' }, { 'value': '06', 'text': '西屯區' }, { 'value': '07', 'text': '南屯區' }, { 'value': '08', 'text': '北屯區' }, { 'value': '09', 'text': '豐原區' }, { 'value': '10', 'text': '東勢區' }, { 'value': '11', 'text': '大甲區' }, { 'value': '12', 'text': '清水區' }, { 'value': '13', 'text': '沙鹿區' }, { 'value': '14', 'text': '梧棲區' }, { 'value': '15', 'text': '后里區' }, { 'value': '16', 'text': '神岡區' }, { 'value': '17', 'text': '潭子區' }, { 'value': '18', 'text': '大雅區' }, { 'value': '19', 'text': '新社區' }, { 'value': '20', 'text': '石岡區' }, { 'value': '21', 'text': '外埔區' }, { 'value': '22', 'text': '大安區' }, { 'value': '23', 'text': '烏日區' }, { 'value': '24', 'text': '大肚區' }, { 'value': '25', 'text': '龍井區' }, { 'value': '26', 'text': '霧峰區' }, { 'value': '27', 'text': '太平區' }, { 'value': '28', 'text': '大里區' }, { 'value': '29', 'text': '和平區' }],
  'C': [{ 'value': '01', 'text': '中正區' }, { 'value': '02', 'text': '七堵區' }, { 'value': '03', 'text': '暖暖區' }, { 'value': '04', 'text': '仁愛區' }, { 'value': '05', 'text': '中山區' }, { 'value': '06', 'text': '安樂區' }, { 'value': '07', 'text': '信義區' }],
  'D': [{ 'value': '01', 'text': '東區' }, { 'value': '02', 'text': '南區' }, { 'value': '04', 'text': '北區' }, { 'value': '06', 'text': '安南區' }, { 'value': '07', 'text': '安平區' }, { 'value': '08', 'text': '中西區' }, { 'value': '09', 'text': '新營區' }, { 'value': '10', 'text': '鹽水區' }, { 'value': '11', 'text': '柳營區' }, { 'value': '12', 'text': '白河區' }, { 'value': '13', 'text': '後壁區' }, { 'value': '14', 'text': '東山區' }, { 'value': '15', 'text': '麻豆區' }, { 'value': '16', 'text': '下營區' }, { 'value': '17', 'text': '六甲區' }, { 'value': '18', 'text': '官田區' }, { 'value': '19', 'text': '大內區' }, { 'value': '20', 'text': '佳里區' }, { 'value': '21', 'text': '西港區' }, { 'value': '22', 'text': '七股區' }, { 'value': '23', 'text': '將軍區' }, { 'value': '24', 'text': '北門區' }, { 'value': '25', 'text': '學甲區' }, { 'value': '26', 'text': '新化區' }, { 'value': '27', 'text': '善化區' }, { 'value': '28', 'text': '新市區' }, { 'value': '29', 'text': '安定區' }, { 'value': '30', 'text': '山上區' }, { 'value': '31', 'text': '左鎮區' }, { 'value': '32', 'text': '仁德區' }, { 'value': '33', 'text': '歸仁區' }, { 'value': '34', 'text': '關廟區' }, { 'value': '35', 'text': '龍崎區' }, { 'value': '36', 'text': '玉井區' }, { 'value': '37', 'text': '楠西區' }, { 'value': '38', 'text': '南化區' }, { 'value': '39', 'text': '永康區' }],
  'E': [{ 'value': '01', 'text': '鹽埕區' }, { 'value': '02', 'text': '鼓山區' }, { 'value': '03', 'text': '左營區' }, { 'value': '04', 'text': '楠梓區' }, { 'value': '05', 'text': '三民區' }, { 'value': '06', 'text': '新興區' }, { 'value': '07', 'text': '前金區' }, { 'value': '08', 'text': '苓雅區' }, { 'value': '09', 'text': '前鎮區' }, { 'value': '10', 'text': '旗津區' }, { 'value': '11', 'text': '小港區' }, { 'value': '12', 'text': '鳳山區' }, { 'value': '13', 'text': '林園區' }, { 'value': '14', 'text': '大寮區' }, { 'value': '15', 'text': '大樹區' }, { 'value': '16', 'text': '大社區' }, { 'value': '17', 'text': '仁武區' }, { 'value': '18', 'text': '鳥松區' }, { 'value': '19', 'text': '岡山區' }, { 'value': '20', 'text': '橋頭區' }, { 'value': '21', 'text': '燕巢區' }, { 'value': '22', 'text': '田寮區' }, { 'value': '23', 'text': '阿蓮區' }, { 'value': '24', 'text': '路竹區' }, { 'value': '25', 'text': '湖內區' }, { 'value': '26', 'text': '茄萣區' }, { 'value': '27', 'text': '永安區' }, { 'value': '28', 'text': '彌陀區' }, { 'value': '29', 'text': '梓官區' }, { 'value': '30', 'text': '旗山區' }, { 'value': '31', 'text': '美濃區' }, { 'value': '32', 'text': '六龜區' }, { 'value': '33', 'text': '甲仙區' }, { 'value': '34', 'text': '杉林區' }, { 'value': '35', 'text': '內門區' }, { 'value': '36', 'text': '茂林區' }, { 'value': '37', 'text': '桃源區' }, { 'value': '38', 'text': '那瑪夏區' }],
  'F': [{ 'value': '01', 'text': '新莊區' }, { 'value': '02', 'text': '林口區' }, { 'value': '03', 'text': '五股區' }, { 'value': '04', 'text': '蘆洲區' }, { 'value': '05', 'text': '三重區' }, { 'value': '06', 'text': '泰山區' }, { 'value': '07', 'text': '新店區' }, { 'value': '08', 'text': '石碇區' }, { 'value': '09', 'text': '深坑區' }, { 'value': '10', 'text': '坪林區' }, { 'value': '11', 'text': '烏來區' }, { 'value': '14', 'text': '板橋區' }, { 'value': '15', 'text': '三峽區' }, { 'value': '16', 'text': '鶯歌區' }, { 'value': '17', 'text': '樹林區' }, { 'value': '18', 'text': '中和區' }, { 'value': '19', 'text': '土城區' }, { 'value': '21', 'text': '瑞芳區' }, { 'value': '22', 'text': '平溪區' }, { 'value': '23', 'text': '雙溪區' }, { 'value': '24', 'text': '貢寮區' }, { 'value': '25', 'text': '金山區' }, { 'value': '26', 'text': '萬里區' }, { 'value': '27', 'text': '淡水區' }, { 'value': '28', 'text': '汐止區' }, { 'value': '30', 'text': '三芝區' }, { 'value': '31', 'text': '石門區' }, { 'value': '32', 'text': '八里區' }, { 'value': '33', 'text': '永和區' }],
  'G': [{ 'value': '01', 'text': '宜蘭市' }, { 'value': '02', 'text': '頭城鎮' }, { 'value': '03', 'text': '礁溪鄉' }, { 'value': '04', 'text': '壯圍鄉' }, { 'value': '05', 'text': '員山鄉' }, { 'value': '06', 'text': '羅東鎮' }, { 'value': '07', 'text': '五結鄉' }, { 'value': '08', 'text': '冬山鄉' }, { 'value': '09', 'text': '蘇澳鎮' }, { 'value': '10', 'text': '三星鄉' }, { 'value': '11', 'text': '大同鄉' }, { 'value': '12', 'text': '南澳鄉' }],
  'H': [{ 'value': '01', 'text': '桃園區' }, { 'value': '02', 'text': '大溪區' }, { 'value': '03', 'text': '中壢區' }, { 'value': '04', 'text': '楊梅區' }, { 'value': '05', 'text': '蘆竹區' }, { 'value': '06', 'text': '大園區' }, { 'value': '07', 'text': '龜山區' }, { 'value': '08', 'text': '八德區' }, { 'value': '09', 'text': '龍潭區' }, { 'value': '10', 'text': '平鎮區' }, { 'value': '11', 'text': '新屋區' }, { 'value': '12', 'text': '觀音區' }, { 'value': '13', 'text': '復興區' }],
  'I': [{ 'value': '01', 'text': '嘉義市' }],
  'J': [{ 'value': '02', 'text': '竹東鎮' }, { 'value': '03', 'text': '關西鎮' }, { 'value': '04', 'text': '新埔鎮' }, { 'value': '05', 'text': '竹北市' }, { 'value': '06', 'text': '湖口鄉' }, { 'value': '08', 'text': '橫山鄉' }, { 'value': '09', 'text': '新豐鄉' }, { 'value': '10', 'text': '芎林鄉' }, { 'value': '11', 'text': '寶山鄉' }, { 'value': '12', 'text': '北埔鄉' }, { 'value': '13', 'text': '峨眉鄉' }, { 'value': '14', 'text': '尖石鄉' }, { 'value': '15', 'text': '五峰鄉' }],
  'K': [{ 'value': '01', 'text': '苗栗市' }, { 'value': '02', 'text': '苑裡鎮' }, { 'value': '03', 'text': '通霄鎮' }, { 'value': '04', 'text': '公館鄉' }, { 'value': '05', 'text': '銅鑼鄉' }, { 'value': '06', 'text': '三義鄉' }, { 'value': '07', 'text': '西湖鄉' }, { 'value': '08', 'text': '頭屋鄉' }, { 'value': '09', 'text': '竹南鎮' }, { 'value': '10', 'text': '頭份市' }, { 'value': '11', 'text': '造橋鄉' }, { 'value': '12', 'text': '後龍鎮' }, { 'value': '13', 'text': '三灣鄉' }, { 'value': '14', 'text': '南庄鄉' }, { 'value': '15', 'text': '大湖鄉' }, { 'value': '16', 'text': '卓蘭鎮' }, { 'value': '17', 'text': '獅潭鄉' }, { 'value': '18', 'text': '泰安鄉' }],
  'M': [{ 'value': '01', 'text': '南投市' }, { 'value': '02', 'text': '埔里鎮' }, { 'value': '03', 'text': '草屯鎮' }, { 'value': '04', 'text': '竹山鎮' }, { 'value': '05', 'text': '集集鎮' }, { 'value': '06', 'text': '名間鄉' }, { 'value': '07', 'text': '鹿谷鄉' }, { 'value': '08', 'text': '中寮鄉' }, { 'value': '09', 'text': '魚池鄉' }, { 'value': '10', 'text': '國姓鄉' }, { 'value': '11', 'text': '水里鄉' }, { 'value': '12', 'text': '信義鄉' }, { 'value': '13', 'text': '仁愛鄉' }],
  'N': [{ 'value': '01', 'text': '彰化市' }, { 'value': '02', 'text': '鹿港鎮' }, { 'value': '03', 'text': '和美鎮' }, { 'value': '04', 'text': '北斗鎮' }, { 'value': '05', 'text': '員林市' }, { 'value': '06', 'text': '溪湖鎮' }, { 'value': '07', 'text': '田中鎮' }, { 'value': '08', 'text': '二林鎮' }, { 'value': '09', 'text': '線西鄉' }, { 'value': '10', 'text': '伸港鄉' }, { 'value': '11', 'text': '福興鄉' }, { 'value': '12', 'text': '秀水鄉' }, { 'value': '13', 'text': '花壇鄉' }, { 'value': '14', 'text': '芬園鄉' }, { 'value': '15', 'text': '大村鄉' }, { 'value': '16', 'text': '埔鹽鄉' }, { 'value': '17', 'text': '埔心鄉' }, { 'value': '18', 'text': '永靖鄉' }, { 'value': '19', 'text': '社頭鄉' }, { 'value': '20', 'text': '二水鄉' }, { 'value': '21', 'text': '田尾鄉' }, { 'value': '22', 'text': '埤頭鄉' }, { 'value': '23', 'text': '芳苑鄉' }, { 'value': '24', 'text': '大城鄉' }, { 'value': '25', 'text': '竹塘鄉' }, { 'value': '26', 'text': '溪州鄉' }],
  'O': [{ 'value': '01', 'text': '新竹市' }],
  'P': [{ 'value': '01', 'text': '斗六市' }, { 'value': '02', 'text': '斗南鎮' }, { 'value': '03', 'text': '虎尾鎮' }, { 'value': '04', 'text': '西螺鎮' }, { 'value': '05', 'text': '土庫鎮' }, { 'value': '06', 'text': '北港鎮' }, { 'value': '07', 'text': '古坑鄉' }, { 'value': '08', 'text': '大埤鄉' }, { 'value': '09', 'text': '莿桐鄉' }, { 'value': '10', 'text': '林內鄉' }, { 'value': '11', 'text': '二崙鄉' }, { 'value': '12', 'text': '崙背鄉' }, { 'value': '13', 'text': '麥寮鄉' }, { 'value': '14', 'text': '東勢鄉' }, { 'value': '15', 'text': '褒忠鄉' }, { 'value': '16', 'text': '台西鄉' }, { 'value': '17', 'text': '元長鄉' }, { 'value': '18', 'text': '四湖鄉' }, { 'value': '19', 'text': '口湖鄉' }, { 'value': '20', 'text': '水林鄉' }],
  'Q': [{ 'value': '02', 'text': '朴子市' }, { 'value': '03', 'text': '布袋鎮' }, { 'value': '04', 'text': '大林鎮' }, { 'value': '05', 'text': '民雄鄉' }, { 'value': '06', 'text': '溪口鄉' }, { 'value': '07', 'text': '新港鄉' }, { 'value': '08', 'text': '六腳鄉' }, { 'value': '09', 'text': '東石鄉' }, { 'value': '10', 'text': '義竹鄉' }, { 'value': '11', 'text': '鹿草鄉' }, { 'value': '12', 'text': '太保市' }, { 'value': '13', 'text': '水上鄉' }, { 'value': '14', 'text': '中埔鄉' }, { 'value': '15', 'text': '竹崎鄉' }, { 'value': '16', 'text': '梅山鄉' }, { 'value': '17', 'text': '番路鄉' }, { 'value': '18', 'text': '大埔鄉' }, { 'value': '20', 'text': '阿里山鄉' }],
  'T': [{ 'value': '01', 'text': '屏東市' }, { 'value': '02', 'text': '潮州鎮' }, { 'value': '03', 'text': '東港鎮' }, { 'value': '04', 'text': '恆春鎮' }, { 'value': '05', 'text': '萬丹鄉' }, { 'value': '06', 'text': '長治鄉' }, { 'value': '07', 'text': '麟洛鄉' }, { 'value': '08', 'text': '九如鄉' }, { 'value': '09', 'text': '里港鄉' }, { 'value': '10', 'text': '鹽埔鄉' }, { 'value': '11', 'text': '高樹鄉' }, { 'value': '12', 'text': '萬巒鄉' }, { 'value': '13', 'text': '內埔鄉' }, { 'value': '14', 'text': '竹田鄉' }, { 'value': '15', 'text': '新埤鄉' }, { 'value': '16', 'text': '枋寮鄉' }, { 'value': '17', 'text': '新園鄉' }, { 'value': '18', 'text': '崁頂鄉' }, { 'value': '19', 'text': '林邊鄉' }, { 'value': '20', 'text': '南州鄉' }, { 'value': '21', 'text': '佳冬鄉' }, { 'value': '22', 'text': '琉球鄉' }, { 'value': '23', 'text': '車城鄉' }, { 'value': '24', 'text': '滿州鄉' }, { 'value': '25', 'text': '枋山鄉' }, { 'value': '26', 'text': '三地門鄉' }, { 'value': '27', 'text': '霧臺鄉' }, { 'value': '28', 'text': '瑪家鄉' }, { 'value': '29', 'text': '泰武鄉' }, { 'value': '30', 'text': '來義鄉' }, { 'value': '31', 'text': '春日鄉' }, { 'value': '32', 'text': '獅子鄉' }, { 'value': '33', 'text': '牡丹鄉' }],
  'U': [{ 'value': '01', 'text': '花蓮市' }, { 'value': '02', 'text': '光復鄉' }, { 'value': '03', 'text': '玉里鎮' }, { 'value': '04', 'text': '新城鄉' }, { 'value': '05', 'text': '吉安鄉' }, { 'value': '06', 'text': '壽豐鄉' }, { 'value': '07', 'text': '鳳林鎮' }, { 'value': '08', 'text': '豐濱鄉' }, { 'value': '09', 'text': '瑞穗鄉' }, { 'value': '10', 'text': '富里鄉' }, { 'value': '11', 'text': '秀林鄉' }, { 'value': '12', 'text': '萬榮鄉' }, { 'value': '13', 'text': '卓溪鄉' }],
  'V': [{ 'value': '01', 'text': '台東市' }, { 'value': '02', 'text': '成功鎮' }, { 'value': '03', 'text': '關山鎮' }, { 'value': '04', 'text': '卑南鄉' }, { 'value': '05', 'text': '大武鄉' }, { 'value': '06', 'text': '太麻里鄉' }, { 'value': '07', 'text': '東河鄉' }, { 'value': '08', 'text': '長濱鄉' }, { 'value': '09', 'text': '鹿野鄉' }, { 'value': '10', 'text': '池上鄉' }, { 'value': '11', 'text': '綠島鄉' }, { 'value': '12', 'text': '延平鄉' }, { 'value': '13', 'text': '海端鄉' }, { 'value': '14', 'text': '達仁鄉' }, { 'value': '15', 'text': '金峰鄉' }, { 'value': '16', 'text': '蘭嶼鄉' }],
  'W': [{ 'value': '01', 'text': '金湖鎮' }, { 'value': '02', 'text': '金沙鎮' }, { 'value': '03', 'text': '金城鎮' }, { 'value': '04', 'text': '金寧鄉' }, { 'value': '05', 'text': '烈嶼鄉' }, { 'value': '06', 'text': '烏坵鄉' }],
  'X': [{ 'value': '01', 'text': '馬公市' }, { 'value': '02', 'text': '湖西鄉' }, { 'value': '03', 'text': '白沙鄉' }, { 'value': '04', 'text': '西嶼鄉' }, { 'value': '05', 'text': '望安鄉' }, { 'value': '06', 'text': '七美鄉' }], 'Z': [{ 'value': '01', 'text': '南竿鄉' }, { 'value': '02', 'text': '北竿鄉' }, { 'value': '03', 'text': '莒光鄉' }, { 'value': '04', 'text': '東引鄉' }]
};

export function getCityName(city_code) {
  const city = city_info.find((item) => {
    if (item.value == city_code) {
      return item.text;
    }
  });
  return city;
};
export function getDistrictName(city_code, district_code) {
  if (city_code == null || district_code == null) {
    return null;
  }
  const district = district_info[city_code].find((item) => {
    if (item.value == district_code) {
      return item.text;
    }
  });
  return district;
};
export function getRegionName(regions_array, region_code) {
  if (regions_array == null || region_code == null) {
    return null;
  }
  // iterator regions array , if item.value == region_code , return item.text
  const region = regions_array.find((item) => {
    if (item.region_code == region_code) {

      return item;
    }
  });
  if (region == null) {
    return null;
  }

  return region.region_name;
};
export function getRoadName(roads_array, road_code) {
  if (roads_array == null || road_code == null) {
    return null;
  }
  // iterator regions array , if item.value == region_code , return item.text
  const road = roads_array.find((item) => {
    if (item.road_code == road_code) {
      return item;
    }
  });
  if (road == null) {
    return null;
  }
  return road.road_name;
};


export {
  city_info,
  district_info,
};
