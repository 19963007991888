<template>
  <v-card class="card-shadow border-radius-xl py-1 my-2">
    <!-- lbkey -->
    <v-card-title class="justify-center ml-2 mr-4 py-2">
      <h4 class="text-md text-grt font-weight-bolder">
        建物 ({{ prop_index + 1 }})
      </h4>
      <v-spacer></v-spacer>
      <v-btn v-if="prop_index > 0" icon @click="remove_building()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="mx-2 py-2">
      <v-col md="1" cols="12" class="py-1 px-1">
        <common-label label="縣市"></common-label>
        <v-autocomplete
          :items="city_info"
          v-model="building_info.city"
          class="input-style font-size-input border-radius-md select-style mt-2"
          hide-details
          outlined
          single-line
          @change="onSelectCity(building_info.city)"
          :disabled="readOnly"
        >
        </v-autocomplete>
      </v-col>
      <v-col md="1" cols="12" class="py-1 px-1">
        <common-label label="行政區"></common-label>
        <v-autocomplete
          :items="districts"
          v-model="building_info.district"
          class="input-style font-size-input border-radius-md select-style mt-2"
          hide-details
          outlined
          single-line
          @change="
            onSelectDistrict(building_info.city, building_info.district, false)
          "
          :disabled="readOnly"
        >
        </v-autocomplete>
      </v-col>
      <v-col md="2" cols="12" class="py-1 px-1">
        <common-label label="路段"></common-label>
        <v-autocomplete
          :items="roads"
          v-model="building_info.road"
          class="input-style font-size-input border-radius-md select-style mt-2"
          outlined
          hide-details
          item-value="road_code"
          item-text="road_name"
          :disabled="readOnly"
        >
        </v-autocomplete>
      </v-col>
      <v-col md="3" cols="12" class="py-1 px-1">
        <v-row class="py-0">
          <v-col cols="11">
            <common-label label="地址"></common-label>
            <v-text-field
              hide-details
              outlined
              class="font-size-input border border-radius-md mt-2"
              v-model="building_info.address"
              :disabled="readOnly"
            ></v-text-field>
          </v-col>
          <v-col cols="1" align-self="end" class="px-0 ma-0">
            <v-tooltip bottom v-if="!readOnly">
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  color="primary"
                  v-on="on"
                  @click="getAddress()"
                  :disabled="!building_info.address"
                  >mdi-map-search</v-icon
                >
              </template>
              <span>查詢座標</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="1" cols="4" class="py-1 px-1">
        <common-label label="北緯"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.latitude"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="py-1 px-1">
        <common-label label="東經"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.longitude"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="2" class="py-1" align-self="end">
        <v-icon
          large
          :color="getColor(building_info)"
          :disabled="!isAllowLocation"
          @click="openMap(building_info.latitude, building_info.longitude)"
          >mdi-map-marker</v-icon
        >
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col md="3" cols="12" class="px-1">
        <common-label label="建物名稱"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.object_name"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="車位(萬)"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.parking_price"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="3" cols="12" class="px-1">
        <common-label label="鑰匙"></common-label>
        <v-row class="ml-2">
          <v-radio-group
            v-model="building_info.has_key"
            row
            :disabled="readOnly"
          >
            <v-radio
              v-for="(item, i) in has_keys_enum"
              :key="i"
              :label="item.text"
              :value="item.value"
              @change="checkHasKey(item.value)"
            ></v-radio>
          </v-radio-group>
          <v-btn
            class="mt-3"
            icon
            @click="
              building_info.has_key = null;
              checkHasKey(null);
            "
            :disabled="readOnly"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-col>
            <v-text-field
              hide-details
              outlined
              class="font-size-input border border-radius-md mx-2"
              :disabled="!building_info.has_key || readOnly"
              v-model="building_info.has_key_extra"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col md="6" cols="12" class="px-1">
        <common-label label="建物形式"></common-label>
        <v-row class="mx-2">
          <v-checkbox
            class="px-1"
            v-for="(item, index) in building_style_enum_str"
            :key="index"
            v-model="styles"
            :label="item.text"
            :value="item.value"
            :disabled="readOnly"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-col md="6" cols="12" class="px-1">
        <common-label label="建物用途"></common-label>
        <v-row class="mx-2">
          <v-checkbox
            class="px-1"
            v-for="(item, index) in building_purpose_enum_str"
            :key="index"
            v-model="purposes"
            :label="item.text"
            :value="item.value"
            :disabled="readOnly"
          ></v-checkbox>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col md="2" cols="6" class="px-1">
        <common-label label="主建物坪數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.main_building"
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="6" class="px-1">
        <common-label label="附屬坪數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.sub_building"
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="6" class="px-1">
        <common-label label="公設坪數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.public_space"
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="6" class="px-1">
        <common-label label="車位坪數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.parking_space"
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="6" class="px-1">
        <common-label label="建物總坪數（含車位）"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="total_building_area_with_parking"
          disabled
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="6" class="px-1">
        <common-label label="建物總坪數（不含車位）"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="total_building_area"
          disabled
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col md="2" cols="6" class="px-1">
        <common-label label="使用坪數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.use_space"
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="6" class="px-1">
        <common-label label="增建"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.increase"
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row class="mx-2">
      <v-col md="1" cols="4" class="px-1">
        <common-label label="總樓層數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.floors"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="px-1">
        <common-label label="地下樓數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.basements"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="px-1">
        <common-label label="所在樓層"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.floor"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="px-1">
        <common-label label="房間數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.rooms"
          suffix="房"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="px-1">
        <common-label label="廳數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.living_rooms"
          suffix="廳"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="px-1">
        <common-label label="衛浴數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.bathrooms"
          suffix="衛"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="px-1">
        <common-label label="路寬"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.road_width"
          type="number"
          suffix="米"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>

      <v-col md="2" cols="8" class="px-1">
        <common-label label="是否活巷"></common-label>
        <v-row class="ml-2">
          <v-radio-group v-model="building_info.alive_lane" row>
            <v-radio
              v-for="(item, i) in is_alive_lane_enum"
              :key="i"
              :label="item.text"
              :value="item.value"
              :disabled="readOnly"
            ></v-radio>
          </v-radio-group>
          <v-btn
            class="my-3"
            icon
            @click="building_info.alive_lane = null"
            :disabled="readOnly"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col md="1" cols="6" class="px-1">
        <common-label label="建物面寬"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.width"
          suffix="米"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="6" class="px-1">
        <common-label label="建物深度"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.depth"
          suffix="米"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="mx-2 my-2">
      <v-col md="1" cols="6" class="px-1">
        <common-label label="每層幾戶"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.units"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="6" class="px-1">
        <common-label label="電梯幾部"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.elevators"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="建築完工日期"></common-label>
        <v-menu
          v-model="building_info.completion_date_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="readOnly"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              outlined
              class="font-size-input border border-radius-md mt-2"
              :value="displayChineseYear(building_info.completion_date_str)"
              v-bind="attrs"
              v-on="on"
              :disabled="readOnly"
              clearable
              @click:clear="building_info.completion_date_str = null"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="building_info.completion_date_str"
            :year-format="formatChineseYear"
            locale="zh-tw"
            @input="building_info.completion_date_menu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="3" cols="12" class="px-0">
        <common-label label="居住現況"></common-label>
        <v-row class="px-4">
          <v-radio-group
            row
            v-model="building_info.living_status"
            :disabled="readOnly"
          >
            <v-radio
              v-for="(item, i) in living_status_enum_str"
              :key="i + item"
              :label="item.text"
              :value="item.value"
              class="mr-2"
            ></v-radio>
          </v-radio-group>
          <v-btn
            class="my-3"
            icon
            @click="building_info.living_status = null"
            :disabled="readOnly"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col md="1" cols="12" class="px-1">
        <common-label label="總戶數"></common-label>
        <v-text-field
          hide-details
          outlined
          class="font-size-input border border-radius-md mt-2"
          v-model="building_info.total_units"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="4" cols="12" class="px-1">
        <v-row>
          <v-col md="5" cols="12" class="px-0">
            <common-label label="警衛管理"></common-label>
            <v-row class="px-4">
              <v-radio-group
                row
                v-model="building_info.guard"
                :disabled="readOnly"
              >
                <v-radio
                  v-for="(item, i) in guard_status_enum"
                  :key="i + item"
                  :label="item.text"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
              <v-btn
                class="my-3"
                icon
                @click="building_info.guard = null"
                :disabled="readOnly"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col md="7" cols="12" class="px-0">
            <common-label label="帶看方式"></common-label>
            <v-row class="px-4">
              <v-radio-group
                row
                v-model="building_info.guide_status"
                :disabled="readOnly"
              >
                <v-radio
                  v-for="(item, i) in guide_status_enum"
                  :key="i + item"
                  :label="item.text"
                  :value="item.value"
                  class="mx-0 pa-0"
                ></v-radio>
              </v-radio-group>
              <v-btn
                class="my-3"
                icon
                @click="building_info.guide_status = null"
                :disabled="readOnly"
              >
                <v-icon>mdi-close</v-icon></v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row class="mx-2">
      <v-col md="6" cols="12" class="px-0">
        <v-row>
          <v-col md="6" cols="12">
            <common-label label="汽車車位現況"></common-label>
            <v-row class="px-4">
              <v-checkbox
                v-for="(item, index) in parking_status_enum_str"
                :key="index"
                v-model="parking_status"
                :label="item.text"
                :value="item.value"
                :disabled="readOnly"
                class="mx-0 mr-3"
                @click="checkParkingStatus(item.value)"
              ></v-checkbox>
            </v-row>
          </v-col>
          <v-col md="4" cols="12" class="px-0">
            <common-label label="車位編號"></common-label>
            <v-text-field
              hide-details
              outlined
              class="font-size-input border border-radius-md mt-2"
              v-model="building_info.parking_no"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="6" cols="12" class="px-0">
        <v-row>
          <v-col md="4" cols="12">
            <common-label label="機車位現況"></common-label>
            <v-row>
              <v-checkbox
                v-for="(item, index) in motor_parking_status_enum_str"
                :key="index"
                class="px-1"
                v-model="motor_parking_status"
                :label="item.text"
                :value="item.value"
                :disabled="readOnly"
                @click="checkMotorParkingStatus(item.value)"
              ></v-checkbox>
            </v-row>
          </v-col>

          <v-col md="4" cols="12" class="px-0">
            <common-label label="機車位編號"></common-label>
            <v-text-field
              hide-details
              outlined
              class="font-size-input border border-radius-md mt-2"
              v-model="building_info.motor_parking_no"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col md="2" cols="12" class="px-0">
        <common-label label="交屋情形"></common-label>
        <v-row class="px-4">
          <v-radio-group
            row
            v-model="building_info.deliver"
            :disabled="readOnly"
          >
            <v-radio
              v-for="(item, i) in deliver_enum"
              :key="i + item"
              :label="item.text"
              :value="item.value"
              class="mx-0"
            ></v-radio>
          </v-radio-group>
          <v-btn
            class="my-3"
            icon
            @click="building_info.deliver = null"
            :disabled="readOnly"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col md="2" cols="12" class="px-0">
        <common-label label="中庭"></common-label>
        <v-row class="px-4">
          <v-radio-group
            row
            v-model="building_info.atrium"
            :disabled="readOnly"
          >
            <v-radio
              v-for="(item, i) in atrium_status_enum"
              :key="i + item"
              :label="item.text"
              :value="item.value"
              class="mx-0"
            ></v-radio>
          </v-radio-group>
          <v-btn
            class="my-3"
            icon
            @click="building_info.atrium = null"
            :disabled="readOnly"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col md="2" cols="12" class="px-0">
        <common-label label="管理費"></common-label>
        <v-row class="pl-4">
          <v-radio-group
            row
            v-model="building_info.has_management_fee"
            @change="checkManagementCost()"
            :disabled="readOnly"
          >
            <v-radio
              v-for="(item, i) in management_fee_enum"
              :key="i + item"
              :label="item.text"
              :value="item.value"
              class="mx-0"
            ></v-radio>
          </v-radio-group>
          <v-btn
            class="my-3"
            icon
            @click="
              building_info.has_management_fee = null;
              building_info.management_fee = null;
            "
            :disabled="readOnly"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-col cols="5" class="mx-0 px-0">
            <v-text-field
              hide-details
              outlined
              :disabled="!building_info.has_management_fee || readOnly"
              class="font-size-input border border-radius-md"
              v-model="building_info.management_fee"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="6" cols="12" class="px-0">
        <v-row class="px-0">
          <v-col md="7" cols="12">
            <common-label label="外牆外飾"></common-label>
            <v-row>
              <v-checkbox
                class="px-1"
                v-for="(item, index) in exteriors_enum"
                :key="index"
                v-model="exteriors"
                :label="item.text"
                :value="item.value"
                :disabled="readOnly"
              ></v-checkbox>
            </v-row>
          </v-col>
          <v-col md="5" cols="12">
            <common-label label="地板建材"></common-label>
            <v-row>
              <v-checkbox
                class="px-1"
                v-for="(item, index) in floor_materials_enum"
                :key="index"
                v-model="floor_materials"
                :label="item.text"
                :value="item.value"
                :disabled="readOnly"
              ></v-checkbox>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="px-0">
        <common-label label="附贈設備"></common-label>
        <v-row>
          <v-checkbox
            v-for="(item, index) in equipments_enum"
            :key="index"
            class="px-1"
            v-model="equipments"
            :label="item.text"
            :value="item.value"
            :disabled="readOnly"
          ></v-checkbox>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { edit_mode_enum } from "@/definitions.js";
import {
  building_style_enum_str,
  building_purpose_enum_str,
  living_status_enum_str,
  parking_status_enum_str,
  motor_parking_status_enum_str,
  object_type_enum,
  orientation_enum_str,
  case_related_type,
  has_keys_enum,
  management_fee_enum,
  is_alive_lane_enum,
  guard_status_enum,
  guide_status_enum,
  deliver_enum,
  atrium_status_enum,
  exteriors_enum,
  floor_materials_enum,
  equipments_enum,
  stringToArray,
  arrayToString,
  motor_parking_status_enum,
  parking_status_enum,
  default_building,
} from "@/case.js";
import { city_info, getRoadName, getCityName, getDistrictName } from "@/cdr.js";
import CDRMixin from "@/components/CRM/CDRMixin.vue";
import CaseMixin from "@/components/CRM/CaseMixin.vue";
import CommonUtility from "@/util/CommonUtility.js";
import CommonLabel from "@/components/CRM/CommonLabel.vue";
import { Loader } from "@googlemaps/js-api-loader";
import _ from "lodash";

export default {
  name: "case-building-info",
  props: {
    propEditMode: {
      type: Number,
    },
    propInfo: {
      type: Object,
      default: () => ({}),
    },
    prop_index: {
      type: Number,
    },
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CDRMixin, CaseMixin],
  data() {
    return {
      building_info: {},
      city_info: city_info,
      default_building: default_building,
      building_style_enum_str: building_style_enum_str,
      building_purpose_enum_str: building_purpose_enum_str,
      living_status_enum_str: living_status_enum_str,
      parking_status_enum: parking_status_enum,
      parking_status_enum_str: parking_status_enum_str,
      motor_parking_status_enum: motor_parking_status_enum,
      motor_parking_status_enum_str: motor_parking_status_enum_str,
      edit_mode_enum: edit_mode_enum,
      object_type_enum: object_type_enum,
      orientation_enum_str: orientation_enum_str,
      case_related_type: case_related_type,
      has_keys_enum: has_keys_enum,
      is_alive_lane_enum: is_alive_lane_enum,
      guard_status_enum: guard_status_enum,
      guide_status_enum: guide_status_enum,
      deliver_enum: deliver_enum,
      atrium_status_enum: atrium_status_enum,
      management_fee_enum: management_fee_enum,
      exteriors_enum: exteriors_enum,
      floor_materials_enum: floor_materials_enum,
      equipments_enum: equipments_enum,
      stringToArray: stringToArray,
      arrayToString: arrayToString,
      styles: [],
      purposes: [],
      parking_status: [],
      motor_parking_status: [],
      floor_materials: [],
      exteriors: [],
      equipments: [],
      colors: {
        disable: "grey",
        enable: "red",
      },
    };
  },
  components: {
    CommonLabel,
  },
  mounted() {
    this.initMap();
    this.pareBuildingInfo(this.propInfo);
  },
  watch: {
    propInfo: {
      handler: function (val) {
        this.pareBuildingInfo(val);
      },
      deep: true,
    },
  },
  computed: {
    total_building_area() {
      const mainBuilding = parseFloat(this.building_info.main_building);
      const subBuilding = parseFloat(this.building_info.sub_building);
      const publicSpace = parseFloat(this.building_info.public_space);

      // Check if the variables are valid numbers, if not, set them to 0
      const mainBuildingArea = isNaN(mainBuilding) ? 0 : mainBuilding;
      const subBuildingArea = isNaN(subBuilding) ? 0 : subBuilding;
      const publicSpaceArea = isNaN(publicSpace) ? 0 : publicSpace;

      let total_building_area =
        mainBuildingArea + subBuildingArea + publicSpaceArea;

      let total_building_area_str = total_building_area.toFixed(2);

      // Calculate the total building area and return as a number
      return total_building_area_str;
    },
    total_building_area_with_parking() {
      const mainBuilding = parseFloat(this.building_info.main_building);
      const subBuilding = parseFloat(this.building_info.sub_building);
      const publicSpace = parseFloat(this.building_info.public_space);

      // Check if the variables are valid numbers, if not, set them to 0
      const mainBuildingArea = isNaN(mainBuilding) ? 0 : mainBuilding;
      const subBuildingArea = isNaN(subBuilding) ? 0 : subBuilding;
      const publicSpaceArea = isNaN(publicSpace) ? 0 : publicSpace;
      let total_building_area =
        mainBuildingArea + subBuildingArea + publicSpaceArea;
      const parking = parseFloat(this.building_info.parking_space);
      const parkingArea = isNaN(parking) ? 0 : parking;
      let val = total_building_area + parkingArea;
      return val.toFixed(2);
    },
    readOnly() {
      return this.propEditMode == this.edit_mode_enum.View || this.propReadOnly;
    },
  },
  methods: {
    coverStringToArray() {
      // iterator the building info and if the key in stringToArray, covert it to array
      for (let key in this.building_info) {
        if (stringToArray.includes(key)) {
          const new_key = key.replace("_str", "");
          //  if the key in the this object, covert it to array
          if (this[new_key]) {
            this[new_key] = this.building_info[key]
              ? this.building_info[key].split(",").map(Number)
              : [];
          }
        }
      }
    },
    pareBuildingInfo(val) {
      this.building_info = _.cloneDeep(val);
      this.coverStringToArray();

      if (this.building_info.city) {
        this.districts = this.district_info[this.building_info.city];
        this.initRegions();
        if (this.building_info.city && this.building_info.district) {
          this.onSelectDistrict(
            this.building_info.city,
            this.building_info.district,
            false
          );
        }
      }
    },
    initRegions() {
      if (
        this.building_info.road != null &&
        this.building_info.road != "" &&
        this.building_info.road_name != null &&
        this.building_info.road_name != ""
      ) {
        this.roads = [
          {
            road_code: this.building_info.road,
            road_name: this.building_info.road_name,
          },
        ];
      }
      // lbkey

      if (this.building_info.lbkey) {
        const keys = this.building_info.lbkey.split("_");
        const nos = keys[3].split("-");
        this.building_info.mno = nos[0];
        this.building_info.sno = nos[1];
      }
    },
    checkManagementCost() {
      if (this.building_info.has_management_fee == false) {
        this.building_info.management_fee = null;
      }
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
    clear() {
      this.building_info = {};
      this.resetCDR();
    },
    checkParkingStatus(newVal) {
      if (newVal == this.parking_status_enum.No) {
        this.parking_status = [this.parking_status_enum.No];
      } else {
        // remove the this.parking_status_enum.No from the array
        this.parking_status = this.parking_status.filter(
          (item) => item != this.parking_status_enum.No
        );
      }
    },
    checkMotorParkingStatus(newVal) {
      if (newVal == this.motor_parking_status_enum.No) {
        this.motor_parking_status = [this.motor_parking_status_enum.No];
      } else {
        // remove the this.parking_status_enum.No from the array
        this.motor_parking_status = this.motor_parking_status.filter(
          (item) => item != this.motor_parking_status_enum.No
        );
      }
    },
    getInfo() {
      this.arrayToString.forEach((key) => {
        if (this[key]) {
          const new_key = key + "_str";
          this.building_info[new_key] = this[key].join(",");
        }
      });

      this.building_info["road_name"] = getRoadName(
        this.roads,
        this.building_info.road
      );

      this.building_info["cdr"] = CommonUtility.getCDR(this.building_info);
      this.building_info["lbkey"] = CommonUtility.getLandKey(
        this.building_info
      );
      let val = _.cloneDeep(this.building_info);
      // clear the empty to null
      const check_array = [
        "floors",
        "basements",
        "floor",
        "rooms",
        "living_rooms",
        "bathrooms",
        "road_width",
        "width",
        "depth",
        "units",
        "elevators",
        "total_units",
      ];
      for (let key in val) {
        if (val[key] === "") {
          val[key] = null;
        }
      }
      return val;
    },
    remove_building() {
      if (this.prop_index > 0) {
        this.$emit("remove_building", this.prop_index);
        console.log("remove writable land info", this.prop_index);
      }
    },
    checkHasKey(value) {
      if (value == false || value == null) {
        this.building_info.has_key_extra = null;
      }
    },
    // map
    initMap() {
      // 透過 Map 物件建構子建立新地圖 map 物件實例，並將地圖呈現在 id 為 map 的元素中
      this.loader = new Loader({
        apiKey: "AIzaSyCPaLwQnuJtzgHwQ_Fv44bNg1K61_jaFUw",
        version: "weekly",
      });
      this.loader.load().then(async () => {
        const { Map } = await google.maps.importLibrary("maps");
      });
    },
    getAddress() {
      const info = this.building_info;
      const city = getCityName(info.city);
      const district = getDistrictName(info.city, info.district);
      const road = this.roads.find((item) => item.road_code == info.road);
      const currentObj = this;
      if (city && district && info.address != null) {
        const address = `${city.text}${district.text}${road.road_name}${info.address}`;
        console.log("get address", address);
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: address }, function (results, status) {
          if (status === "OK") {
            let latitude = results[0].geometry.location.lat();
            let longitude = results[0].geometry.location.lng();
            console.log("緯度" + latitude + ", 經度:" + longitude);
            // 在这里可以使用经纬度坐标进行后续操作
            currentObj.showMap = true;
            currentObj.lat = latitude;
            currentObj.lng = longitude;

            currentObj.building_info.latitude = latitude;
            currentObj.building_info.longitude = longitude;
          } else {
            alert("找不到該地址" + status);
          }
        });
      }
    },
    openMap(latitude, longitude) {
      console.log(`open map ${latitude} ${longitude}`);
      if (latitude == null || longitude == null) {
        return;
      }
      // <a href="https://www.google.com/maps?q=25.034,121.545" target="_blank">Open Google Maps</a>
      const linkElement = document.createElement("a");
      // 设置 href 属性为 Google 地图链接
      linkElement.href = `https://www.google.com/maps?q=${latitude},${longitude}`;
      // 设置 target 属性为 "_blank"，在新的标签页中打开链接
      linkElement.target = "_blank";
      // 设置 <a> 元素的文本内容
      linkElement.textContent = "Open Google Maps";
      window.open(linkElement.href, "_blank");
    },
  },
};
</script>
