<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <v-card-title>
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("Address") }}</h5>
      <v-spacer></v-spacer>
      <v-btn
        v-if="propEditMode > 1"
        :ripple="false"
        elevation="0"
        color="#fff"
        class="py-5 px-6 btn-danger bg-gradient-primary"
        small
        @click="addPhone()"
      >
        {{ $t("Add Address") }}
        <v-icon right dark> mdi-road </v-icon>
      </v-btn>
    </v-card-title>
    <div class="px-6 pb-2 pt-0">
      <v-row
        v-for="(item, index) in addressInfo"
        :key="index"
        class="py-0 my-2"
      >
        <v-col cols="1" class="py-0">
          <v-text-field
            hide-details
            outlined
            class="text-md font-weight-bolder mx-2"
            :value="address_type_title"
          >
          </v-text-field>
        </v-col>

        <v-col cols="9" class="py-0">
          <v-text-field
            hide-details
            outlined
            class="font-size-input border border-radius-md"
            v-model="item.address"
          >
          </v-text-field>
        </v-col>
        <v-col self-align="end" class="text-right px-1 py-0" v-show="!readonly">
          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="py-5 px-6 btn-danger bg-gradient-danger"
            small
            @click="removePhone(item)"
          >
            刪除地址
            <v-icon right dark> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import { address_type, edit_mode_enum } from "@/definitions.js";
export default {
  name: "customer-address-info",
  props: {
    propEditMode: {
      type: Number,
      default: 0,
    },
    addressInfo: {
      type: Array,
      default: () => [
        {
          address: null,
        },
      ],
    },
  },

  data(vm) {
    return {
      edit_mode_enum: edit_mode_enum,
      address_type: address_type,
      address_type_title: vm.$i18n.t("Address"),
    };
  },
  computed: {
    readonly() {
      return (
        this.propEditMode === edit_mode_enum.View ||
        this.propEditMode === edit_mode_enum.Hidden
      );
    },
  },
  methods: {
    addPhone() {
      this.$emit("addAddress");
    },
    removePhone(item) {
      const index = this.addressInfo.indexOf(item);
      this.$emit("removeAddress", index);
    },
  },
  mounted() {},
};
</script>
