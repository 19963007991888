<template>
  <v-card class="card-shadow">
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <v-form>
      <v-card class="card-shadow">
        <v-row class="mx-0">
          <v-col md="1" cols="12" class="py-1" v-show="!isDraftMode()">
            <label class="font-weight-bolder ms-1"> 案件編號 </label>
            <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              v-model="case_info.serial_number"
              :maxlength="6"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="1" cols="12" class="py-1">
            <label class="font-weight-bolder ms-1">土地/建物</label>
            <v-select
              :items="object_type_enum_str"
              v-model="case_info.type"
              :disabled="propEditMode == edit_mode_enum.Edit"
              class="
                input-style
                font-size-input
                border-radius-md
                select-style
                mt-2
              "
              hide-details
              outlined
              single-line
              @change="checkType()"
            >
            </v-select>
          </v-col>

          <v-col md="1" cols="12" class="py-1">
            <label class="font-weight-bolder ms-1">案件類型</label>
            <v-select
              :items="case_type_enum_str"
              v-model="case_info.case_type"
              :class="text_edit_class"
              hide-details
              outlined
              single-line
              :disabled="readOnly"
            >
            </v-select>
          </v-col>
          <v-col md="3" cols="12" class="py-1">
            <label class="font-weight-bolder ms-1">案名</label>
            <v-text-field
              hide-details
              outlined
              placeholder="ex:航空城安置街廓"
              :class="text_edit_class"
              v-model="case_info.name"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="12" class="py-1">
            <label class="font-weight-bolder ms-1">案件來源</label>
            <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              v-model="case_info.source"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <!--  -->
          <v-col md="2" cols="5" class="py-1" v-if="isDraftMode()">
            <label class="font-weight-bolder ms-1">送審</label>
            <v-row class="pl-2">
              <v-radio-group
                row
                v-model="case_info.review_status"
                :disabled="readOnly"
              >
                <v-radio
                  :ripple="false"
                  v-for="(item, i) in review_status_draft_enum_str"
                  :key="i + item"
                  :label="item.text"
                  :value="item.value"
                  dense
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-col>
          <v-col md="2" cols="5" class="py-1" v-else>
            <label class="font-weight-bolder ms-1">上架</label>
            <v-row class="pl-2">
              <v-radio-group
                row
                v-model="case_info.shelf_status"
                :disabled="readOnly"
              >
                <v-radio
                  :ripple="false"
                  v-for="(item, i) in shelf_status_str"
                  :key="i + item"
                  :label="item.text"
                  :value="item.value"
                  dense
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-col>
          <!-- 審核結果 or 交易狀態 -->
          <v-col md="2" cols="7" class="py-1" v-if="isDraftMode()">
            <label class="font-weight-bolder ms-1">審核未通過原因</label>
            <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              :disabled="propCaseMode != case_mode_enum.Manage"
              v-model="this.case_info.review_comment"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="7" class="py-1" v-else>
            <label class="font-weight-bolder ms-1">交易狀態</label>
            <v-row class="pl-2">
              <v-radio-group
                row
                v-model="case_info.deal_status"
                :disabled="readOnly"
              >
                <v-radio
                  :ripple="false"
                  v-for="(item, i) in deal_status_str"
                  :key="i + item"
                  :label="item.text"
                  :value="item.value"
                  dense
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <v-col
            md="2"
            cols="12"
            class="py-1"
            v-if="case_info.sale_or_rent == sale_or_rent_enum.Sale"
          >
            <label class="font-weight-bolder ms-1">單價(萬)</label>
            <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              v-model="case_info.unit_price"
              type="number"
              suffix="萬"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="py-1"
            v-if="case_info.sale_or_rent == sale_or_rent_enum.Sale"
          >
            <label class="font-weight-bolder ms-1">委託總價(萬)</label>
            <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              v-model="case_info.total_price"
              type="number"
              suffix="萬"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col md="2" cols="12" class="py-1" v-else>
            <label class="font-weight-bolder ms-1">出租價格(萬)</label>
            <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              v-model="case_info.total_price"
              type="number"
              suffix="萬"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="py-1"
            v-if="case_info.sale_or_rent == sale_or_rent_enum.Sale"
          >
            <label class="font-weight-bolder ms-1">貸款</label>
            <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              v-model="case_info.debt"
              :disabled="readOnly"
            ></v-text-field>
          </v-col>
          <v-col md="2" cols="12" class="py-1">
            <label class="font-weight-bolder ms-1">座向</label>
            <v-select
              :items="orientation_enum_str"
              v-model="case_info.orientation"
              class="
                input-style
                font-size-input
                border-radius-md
                select-style
                mt-2
              "
              hide-details
              outlined
              single-line
              :disabled="readOnly"
            >
            </v-select>
          </v-col>
          <v-col
            md="2"
            cols="12"
            class="py-1"
            v-if="case_info.sale_or_rent == sale_or_rent_enum.Sale"
          >
            <label class="font-weight-bolder ms-1">銷售原因</label>
            <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              v-model="case_info.sale_reason"
              :disabled="readOnly"
            >
            </v-text-field>
          </v-col>
          <v-col
            md="2"
            cols="5"
            class="py-1"
            :offset-md="
              case_info.sale_or_rent == sale_or_rent_enum.Sale ? 0 : 6
            "
          >
            <label class="font-weight-bolder ms-1">銷售類型</label>
            <v-row class="pl-2">
              <v-radio-group
                row
                v-model="case_info.sale_or_rent"
                :disabled="readOnly"
              >
                <v-radio
                  :ripple="false"
                  v-for="(item, i) in sale_or_rent_enum_str"
                  :key="i + item"
                  :label="item.text"
                  :value="item.value"
                  dense
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <case-features
        ref="features"
        :features="case_info.features_list"
        :propEditMode="propEditMode"
        :propReadOnly="readOnly"
      ></case-features>

      <v-card class="card-shadow mt-6">
        <v-tabs v-model="activeTab" grow>
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="tab in rbacTabItems"
            :key="tab.name"
            :disabled="isDisabled(tab)"
          >
            <v-icon class="me-2" large>{{ tab.icon }}</v-icon>
            {{ tab.name }}
          </v-tab>
          <v-tab-item key="建物資訊" class="py-2">
            <v-container fluid class="pt-6">
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  <v-card
                    class="
                      card-shadow
                      px-4
                      py-4
                      overflow-hidden
                      border-radius-xl
                    "
                  >
                    <v-row>
                      <v-col cols="auto" align-self="center">
                        <h5 class="text-h5 text-grt font-weight-bold">
                          建物資訊
                        </h5>
                      </v-col>
                      <v-spacer></v-spacer>

                      <v-btn
                        v-if="!readOnly"
                        :ripple="false"
                        elevation="0"
                        color="#fff"
                        @click="add_building_info"
                        class="
                          font-weight-bolder
                          btn-primary
                          bg-gradient-primary
                          py-5
                          px-6
                          mx-4
                          ms-2
                        "
                        small
                      >
                        新增建物資訊
                      </v-btn>
                    </v-row>
                  </v-card>
                  <case-building-info
                    v-for="(building, b) in case_info.buildings"
                    ref="building_info_main"
                    :key="b"
                    :prop_index="b"
                    :propInfo="building"
                    :propEditMode="propEditMode"
                    :propReadOnly="readOnly"
                    v-on:remove_building="remove_building_info"
                  ></case-building-info>
                  <v-card
                    class="
                      card-shadow
                      px-4
                      py-4
                      overflow-hidden
                      border-radius-xl
                    "
                  >
                    <v-row>
                      <v-spacer></v-spacer>

                      <v-btn
                        v-if="!readOnly"
                        :ripple="false"
                        elevation="0"
                        color="#fff"
                        @click="add_building_info"
                        class="
                          font-weight-bolder
                          btn-primary
                          bg-gradient-primary
                          py-5
                          px-6
                          mx-4
                          ms-2
                        "
                        small
                      >
                        新增建物資訊
                      </v-btn>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <!-- land 土地 -->
          <v-tab-item key="土地資訊" class="py-2">
            <v-container fluid class="pt-6">
              <v-row class="py-0 my-0">
                <v-col class="py-0 my-0">
                  <v-card
                    class="
                      card-shadow
                      px-4
                      py-4
                      overflow-hidden
                      border-radius-xl
                    "
                  >
                    <v-row>
                      <v-col cols="auto" align-self="center">
                        <h5 class="text-h5 text-grt font-weight-bold">
                          土地資訊
                        </h5>
                      </v-col>
                      <v-spacer></v-spacer>

                      <v-btn
                        v-if="!readOnly"
                        :ripple="false"
                        elevation="0"
                        color="#fff"
                        @click="add_land_info"
                        class="
                          font-weight-bolder
                          btn-primary
                          bg-gradient-primary
                          py-5
                          px-6
                          mx-4
                          ms-2
                        "
                        small
                      >
                        新增土地資訊
                      </v-btn>
                    </v-row>
                  </v-card>
                  <case-land-info
                    v-for="(land, l) in case_info.lands"
                    ref="land_info_main"
                    :key="l"
                    :prop_index="l"
                    :info="land"
                    :propEditMode="propEditMode"
                    :propReadOnly="readOnly"
                    v-on:remove_land="remove_land_info"
                  >
                  </case-land-info>
                  <v-card
                    class="
                      card-shadow
                      px-4
                      py-4
                      overflow-hidden
                      border-radius-xl
                    "
                  >
                    <v-row>
                      <!-- <v-col cols="auto" align-self="center">
                        <h5 class="text-h5 text-grt font-weight-bold">
                          土地資訊
                        </h5>
                      </v-col> -->
                      <v-spacer></v-spacer>

                      <v-btn
                        v-if="!readOnly"
                        :ripple="false"
                        elevation="0"
                        color="#fff"
                        @click="add_land_info"
                        class="
                          font-weight-bolder
                          btn-primary
                          bg-gradient-primary
                          py-5
                          px-6
                          mx-4
                          ms-2
                        "
                        small
                      >
                        新增土地資訊
                      </v-btn>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item key="委託人" class="py-2" v-if="isPrincipalsTabActive()">
            <case-principals
              ref="case_principals"
              :propInfo="case_info.principals"
              :propRelatedPeople="case_info.related_people"
              :propEditMode="propEditMode"
              :propCaseMode="propCaseMode"
              :propReadOnly="readOnly"
              v-on:removeClient="remove_client_confirm"
              v-on:removeOwner="remove_owner_confirm"
              v-on:removeAgent="remove_agent_confirm"
            ></case-principals>
          </v-tab-item>
          <v-tab-item key="詢問紀錄" class="py-2">
            <case-inquires
              ref="case_inquire_customer"
              :propID="case_info.id"
              :propInfo="case_info.inquires"
              :propType="case_related_type.Inquires"
              :propCaseName="case_info.name"
              :propCaseType="case_info.type"
              :propSales="modified_sales"
              :propEditMode="propEditMode"
              :propCaseMode="propCaseMode"
              :propReadOnly="readOnly"
            >
            </case-inquires>
          </v-tab-item>
          <v-tab-item key="參與人員" class="py-2" v-if="isSalesTabActive()">
            <case-sale
              ref="case_sale"
              :propInfo="modified_sales"
              :propEditMode="propEditMode"
              :prop_case_id="case_info.id"
              :propCaseName="case_info.name"
              :propCaseType="case_info.type"
              :propReadOnly="readOnly"
              v-on:addSale="addSale"
              v-on:removeSale="removeSale"
            >
            </case-sale>
          </v-tab-item>
          <v-tab-item
            key="交易紀錄"
            class="py-2"
            v-if="isTransactionTabActive()"
          >
            <case-transactions
              ref="case_transaction_customer"
              :propInfo="case_info.transactions"
              :propSales="modified_sales"
              :propType="case_related_type.Transactions"
              :propCaseName="case_info.name"
              :propCaseType="case_info.type"
              :propEditMode="propEditMode"
              :propReadOnly="readOnly"
              :propRelatedPeople="case_info.related_people"
              v-on:onPerformanceChange="onPerformanceChange"
            >
            </case-transactions>
          </v-tab-item>

          <v-tab-item key="圖片" class="py-2">
            <v-lazy>
              <case-gallery
                ref="case_gallery"
                :photos="case_info.photos"
                :photo_base="case_info.photo_base"
                :prop_case_id="case_info.id"
                :propEditMode="propEditMode"
                :propReadOnly="readOnly"
                v-on:reload="reload"
              ></case-gallery>
            </v-lazy>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-form>
  </v-card>
</template>
<script>
import {
  case_mode_enum,
  case_mode_enum_str,
  case_related_type,
  case_type_enum_str,
  commission_enum_str,
  deal_status_enum,
  deal_status_str,
  default_building,
  default_land,
  land_ownership_enum_str,
  object_type_enum,
  object_type_enum_str,
  orientation_enum_str,
  review_status_draft_enum_str,
  shelf_status_enum,
  shelf_status_str,
  sale_or_rent_enum,
  sale_or_rent_enum_str,
  review_status_enum,
} from "@/case.js";
import {
  common_text_edit_class,
  edit_mode_enum,
  edit_mode_enum_str,
} from "@/definitions.js";
import { mapGetters, mapState } from "vuex";

import { city_info, getCityName, getDistrictName } from "@/cdr.js";
import CDRMixin from "@/components/CRM/CDRMixin.vue";
import CaseMixin from "@/components/CRM/CaseMixin.vue";
import CommonUtility from "@/util/CommonUtility.js";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import _ from "lodash";
import CaseBuildingInfo from "./CaseBuildingInfo.vue";
import CaseFeatures from "./CaseFeatures.vue";
import CaseGallery from "./CaseGallery.vue";
import CaseInquires from "./CaseInquires.vue";
import CaseLandInfo from "./CaseLandInfo.vue";
import CasePrincipals from "./CasePrincipals.vue";
import CaseRelatedCustomer from "./CaseRelatedCustomer.vue";
import CaseSale from "./CaseSale.vue";
import CaseTransactions from "./CaseTransactions.vue";

export default {
  name: "case-basic-info",
  props: {
    propEditMode: {
      type: Number,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    propCaseMode: {
      type: Number,
    },
  },
  components: {
    CaseRelatedCustomer,
    CaseGallery,
    CaseSale,
    CaseFeatures,
    CasePrincipals,
    CaseInquires,
    CaseTransactions,
    CaseLandInfo,
    CaseBuildingInfo,
    CommonConfirm,
  },
  mixins: [CaseMixin, CDRMixin],
  data() {
    return {
      case_info: {},
      review_status_enum: review_status_enum,
      edit_mode_enum: edit_mode_enum,
      edit_mode_enum_str: edit_mode_enum_str,
      city_info: city_info,
      case_type_enum_str: case_type_enum_str,
      land_ownership_enum_str: land_ownership_enum_str,
      object_type_enum: object_type_enum,
      object_type_enum_str: object_type_enum_str,
      object_type_enum: object_type_enum,
      shelf_status_str: shelf_status_str,
      deal_status_str: deal_status_str,
      deal_status_enum: deal_status_enum,
      shelf_status_enum: shelf_status_enum,
      commission_enum_str: commission_enum_str,
      orientation_enum_str: orientation_enum_str,
      case_related_type: case_related_type,
      case_mode_enum: case_mode_enum,
      case_mode_enum_str: case_mode_enum_str,
      review_status_draft_enum_str: review_status_draft_enum_str,
      sale_or_rent_enum: sale_or_rent_enum,
      sale_or_rent_enum_str: sale_or_rent_enum_str,
      tabItems: [
        { name: "建物資訊", icon: "mdi-office-building-marker" },
        { name: "土地資訊", icon: "mdi-land-plots" },
        { name: "委託人", icon: "mdi-calendar-import-outline" },
        { name: "詢問紀錄", icon: "mdi-comment-question-outline" },
        { name: "參與人員", icon: "mdi-account-multiple-plus-outline" },
        { name: "交易紀錄", icon: "mdi-handshake" },
        { name: "圖片", icon: "mdi-image-multiple-outline" },
      ],
      activeTab: 0,
      // map
      // 預設經緯度在信義區附近
      loader: null,
      lat: 24.989095,
      lng: 121.3143541,
      marks: [],
      showMap: false,
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
      default_land: default_land,
      default_building: default_building,
      modified_sales: [],
      text_edit_class: common_text_edit_class,
      remove_info: {
        buildings: [],
        lands: [],
        sales: [],
        related_people: [],
      },
    };
  },
  mounted() {
    this.paresInfo(this.info);
  },
  computed: {
    ...mapState(["user_id", "last_name", "first_name"]),
    // tabItm
    rbacTabItems() {
      let tabItems = _.cloneDeep(this.tabItems);
      if (this.isTransactionTabActive() == false) {
        tabItems = tabItems.filter((item) => item.name != "交易紀錄");
      }
      // console.log("isPrincipalsTabActive", this.isPrincipalsTabActive());
      // console.log("this.isAllowPrincipalsCase()", this.isAllowPrincipalsCase());
      // console.log("propCaseMode", this.propCaseMode);
      if (this.isAllowViewInquiresCaseTab() == false) {
        tabItems = tabItems.filter((item) => item.name != "詢問紀錄");
      }
      if (this.isPrincipalsTabActive() == false) {
        tabItems = tabItems.filter((item) => item.name != "委託人");
      }
      if (this.isSalesTabActive() == false) {
        tabItems = tabItems.filter((item) => item.name != "參與人員");
      }
      return tabItems;
    },
    shelf_options() {
      return this.shelf_status_str;
    },
    readOnly() {
      if (
        this.info != null &&
        this.info != undefined &&
        this.propCaseMode != this.case_mode_enum.Manage &&
        this.info.review_status == this.review_status_enum.Submitting
      ) {
        return true;
      }
      return this.propEditMode == this.edit_mode_enum.View;
    },
  },
  watch: {
    info: {
      handler: function (val) {
        this.paresInfo(val);
      },
      deep: true,
    },
    activeTab(newVal, oldVal) {
      const tab = this.rbacTabItems[newVal];
      if (tab.name == "委託人" && this.$refs.case_principals) {
        this.$refs.case_principals.parseRelatedInfo(
          this.case_info.principals,
          this.case_info.related_people
        );
      } else if (tab.name == "詢問紀錄" && this.$refs.case_inquire_customer) {
        this.$refs.case_inquire_customer.parseRelatedInfo(
          this.case_info.inquires
        );
        this.$refs.case_inquire_customer.setSales(this.modified_sales);
      } else if (
        tab.name == "交易紀錄" &&
        this.$refs.case_transaction_customer
      ) {
        console.log("set detail from case_info.transaction");

        this.$refs.case_transaction_customer.parseRelatedInfo(
          this.case_info.transactions
        );
        this.$refs.case_transaction_customer.parseSaleInfo(this.modified_sales);
        if (this.case_info != null && this.case_info.related_people != null) {
          this.$refs.case_transaction_customer.parseBuyerInfo(
            this.case_info.related_people
          );
        }
      } else if (tab.name == "參與人員" && this.$refs.case_sale) {
        this.$refs.case_sale.parseRelatedInfo(this.modified_sales);
      }
    },
  },
  methods: {
    ...mapGetters([
      "isAllowViewAllTransactionCase",
      "isAllowPrincipalsCase",
      "isAllowViewAllPrincipalsCase",
      "isAllowViewTransactionCase",
      "isAllowViewInquiresCase",
      "isAllowViewAllInquiresCase",
    ]),
    dump() {
      console.log(
        "--> Edit mode:",
        CommonUtility.getText(this.propEditMode, edit_mode_enum_str),
        " Case mode:",
        CommonUtility.getText(this.propCaseMode, case_mode_enum_str)
      );
    },
    isDraftMode() {
      return this.propCaseMode == case_mode_enum.Draft;
    },

    isTransactionTabActive() {
      if (this.isAllowViewAllTransactionCase() == true) {
        return true;
      }
      if (this.propCaseMode == case_mode_enum.Draft) {
        return false;
      }
      // show the transaction tab
      if (this.modified_sales.length > 0) {
        let sales = this.modified_sales.filter(
          (sale) => sale.user_id == this.user_id
        );
        if (sales.length > 0 && this.isAllowViewTransactionCase()) {
          return true;
        }
      }
      return false;
    },
    isPrincipalsTabActive() {
      if (this.propCaseMode == case_mode_enum.Draft) {
        return true;
      }
      if (this.isAllowViewAllPrincipalsCase()) {
        return true;
      }
      // show the principals tab 委託人
      if (this.modified_sales.length > 0) {
        let sales = this.modified_sales.filter(
          (sale) => sale.user_id == this.user_id && sale.business_type == 0
        );
        if (sales.length > 0 && this.isAllowPrincipalsCase()) {
          return true;
        }
      }
      return false;
    },
    isAllowViewInquiresCaseTab() {
      if (this.propCaseMode == case_mode_enum.Draft) {
        return true;
      }
      return (
        this.isAllowViewInquiresCase() || this.isAllowViewAllInquiresCase()
      );
    },
    isSalesTabActive() {
      return this.propCaseMode != case_mode_enum.Draft;
    },
    paresInfo(val) {
      // console.log("Parse Basic info", val);
      this.case_info = _.cloneDeep(val);
      this.checkType();
      if (this.isDraftMode() && this.modified_sales.length == 0) {
        this.modified_sales.push({
          user_id: this.user_id,
          user: {
            last_name: this.last_name,
            first_name: this.first_name,
          },
          readonly: true,
        });
      }
    },
    checkType() {
      if (this.case_info.type == object_type_enum.Land) {
        this.activeTab = 1;
      } else {
        this.activeTab = 0;
      }
      this.resetCDR();
      if (this.propEditMode == this.edit_mode_enum.Create) {
        console.log("reset select only for create mode");
        this.case_info.city = null;
        this.case_info.district = null;
        this.case_info.region = null;
      }

      if (this.case_info != null && this.case_info.id) {
        this.getCaseByID(this.case_info.id);
      }
    },
    setCaseDetail(data) {
      // console.log("set case detail", data);
      this.case_info = _.cloneDeep(data);
      if (data.features) {
        let features = data.features.split("||");
        this.case_info.features_list = [];
        for (let i = 0; i < features.length; i++) {
          this.case_info.features_list.push({ text: features[i] });
        }
      } else {
        this.case_info.features_list = [
          { text: null },
          { text: null },
          { text: null },
          { text: null },
        ];
      }

      if (this.propEditMode != this.edit_mode_enum.Create) {
        if (this.case_info.city) {
          this.districts = this.district_info[this.case_info.city];
        }
      }
      // check buildings
      if (
        this.case_info.buildings != null &&
        this.case_info.buildings.length == 0
      ) {
        this.case_info.buildings = [_.cloneDeep(this.default_building)];
      }
      // check lands
      if (this.case_info.lands != null && this.case_info.lands.length == 0) {
        this.case_info.lands = [_.cloneDeep(this.default_land)];
      }
      // check sales
      this.modified_sales = _.cloneDeep(this.case_info.sales);
      this.modified_sales.forEach((sale) => {
        sale.readonly = true;
      });
      // console.log("set case detail", this.modified_sales);
    },

    checkCommission() {
      if (this.case_info.has_commission == false) {
        this.case_info.commission_ratio = null;
      }
    },
    getRegion() {
      if (this.case_info.region) {
        let item = this.regions.find(
          (item) => item.region_code == this.case_info.region
        );
        if (item) {
          return `${item.region_name},${item.region_code}`;
        }
      }
      return null;
    },
    getRoad() {
      if (this.case_info.road) {
        let item = this.roads.find(
          (item) => item.road_code == this.case_info.road
        );
        if (item) {
          return `${item.road_name},${item.road_code}`;
        }
      }
      return null;
    },
    hasPendingPhotos() {
      if (this.$refs.case_gallery) {
        return this.$refs.case_gallery.hasPendingPhotos;
      }
      return false;
    },
    uploadPhotos(case_id, created) {
      if (this.$refs.case_gallery) {
        this.$refs.case_gallery.uploadPhotos(case_id, created);
      }
    },
    getInfo() {
      let new_info = _.cloneDeep(this.case_info);
      new_info.inquires = this.$refs.case_inquire_customer
        ? this.$refs.case_inquire_customer.getRelatedInfo()
        : [];
      new_info.transactions = this.$refs.case_transaction_customer
        ? this.$refs.case_transaction_customer.getRelatedInfo()
        : [];

      if (new_info.transactions.length > 0) {
        if (
          this.info.shelf_status == shelf_status_enum.ON_SHELF &&
          this.info.deal_status == deal_status_enum.NOT_COMPLETED
        ) {
          console.log("Change to off shelf and transaction completed");
          new_info.deal_status = deal_status_enum.COMPLETED;
          new_info.shelf_status = shelf_status_enum.OFF_SHELF;
        }
      }

      // if (this.$refs.case_sale) {
      new_info.sales = this.modified_sales;

      if (this.$refs.case_principals) {
        new_info.principals = this.$refs.case_principals.getInfo();
        new_info.related_people = this.$refs.case_principals.getRelatedPeople();
      } else {
        new_info.principals = [];
        new_info.related_people = [];
      }
      // get the buyers
      let buyers = this.$refs.case_transaction_customer
        ? this.$refs.case_transaction_customer.getBuyers()
        : [];
      if (buyers.length > 0) {
        new_info.related_people = new_info.related_people.concat(buyers);
      }

      new_info.lands = [];
      if (this.$refs.land_info_main) {
        for (let i = 0; i < this.$refs.land_info_main.length; i++) {
          let tmp = this.$refs.land_info_main[i].getInfo();
          new_info.lands.push(tmp);
        }
      }
      new_info.buildings = [];
      if (this.$refs.building_info_main) {
        for (let i = 0; i < this.$refs.building_info_main.length; i++) {
          let tmp = this.$refs.building_info_main[i].getInfo();
          new_info.buildings.push(tmp);
        }
      }

      new_info.features = this.$refs.features.getInfo();
      new_info.region = this.getRegion();
      new_info.road = this.getRoad();
      // remove null value of new_info
      new_info.total_area = CommonUtility.getTotalArea(new_info);
      // CommonUtility.removeEmpty(new_info);
      // merge the remove_info  into new_info
      new_info["remove_info"] = this.remove_info;
      console.log("new info", new_info);
      return new_info;
    },
    clear() {
      // clear
      // console.log("basic clear all ");
      if (this.$refs.case_inquire_customer) {
        this.$refs.case_inquire_customer.clear();
      }
      if (this.$refs.case_transaction_customer) {
        this.$refs.case_transaction_customer.clear();
      }
      if (this.$refs.case_gallery) {
        this.$refs.case_gallery.clear();
      }

      if (this.$refs.case_sale) {
        this.$refs.case_sale.clear();
      }
      if (this.$refs.case_principals) {
        this.$refs.case_principals.clear();
      }
      if (this.$refs.land_info_main) {
        for (let i = 0; i < this.$refs.land_info_main.length; i++) {
          this.$refs.land_info_main[i].clear();
        }
      }
      if (this.$refs.building_info_main) {
        for (let i = 0; i < this.$refs.building_info_main.length; i++) {
          this.$refs.building_info_main[i].clear();
        }
      }
      this.activeTab = 0;
      this.marks.forEach((mark) => {
        mark.setMap(null);
      });
      this.showMap = false;
      this.modified_sales = [];
      this.remove_info = {
        buildings: [],
        lands: [],
        sales: [],
        related_people: [],
      };
    },
    isDisabled(tab) {
      if (this.propEditMode == this.edit_mode_enum.Edit) {
        if (
          tab == "土地資訊" &&
          this.case_info.type == object_type_enum.Building
        ) {
          return true;
        }
        if (tab == "建物資訊" && this.case_info.type == object_type_enum.Land) {
          return true;
        }
      }

      return false;
    },
    reload(val) {
      console.log("reload", val);
      this.$emit("reload", val);
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
    initMap() {
      // 透過 Map 物件建構子建立新地圖 map 物件實例，並將地圖呈現在 id 為 map 的元素中
      this.loader = new Loader({
        apiKey: "AIzaSyCPaLwQnuJtzgHwQ_Fv44bNg1K61_jaFUw",
        version: "weekly",
      });
      this.loader.load().then(async () => {
        const { Map } = await google.maps.importLibrary("maps");
      });
    },
    getAddress() {
      const info = this.case_info;
      const city = getCityName(info.city);
      const district = getDistrictName(info.city, info.district);
      const road = this.roads.find((item) => item.road_code == info.road);
      const currentObj = this;
      if (city && district && info.address != null) {
        const address = `${city.text}${district.text}${road.road_name}${info.address}`;
        console.log("get address", address);
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: address }, function (results, status) {
          if (status === "OK") {
            let latitude = results[0].geometry.location.lat();
            let longitude = results[0].geometry.location.lng();
            console.log("緯度" + latitude + ", 經度:" + longitude);
            // 在这里可以使用经纬度坐标进行后续操作
            currentObj.showMap = true;
            currentObj.lat = latitude;
            currentObj.lng = longitude;
            // currentObj.displayMap();
            currentObj.case_info.latitude = latitude;
            currentObj.case_info.longitude = longitude;
          } else {
            alert("找不到該地址" + status);
          }
        });
      }
    },
    openMap(latitude, longitude) {
      console.log(`open map ${latitude} ${longitude}`);
      if (latitude == null || longitude == null) {
        return;
      }
      // <a href="https://www.google.com/maps?q=25.034,121.545" target="_blank">Open Google Maps</a>
      const linkElement = document.createElement("a");
      // 设置 href 属性为 Google 地图链接
      linkElement.href = `https://www.google.com/maps?q=${latitude},${longitude}`;
      // 设置 target 属性为 "_blank"，在新的标签页中打开链接
      linkElement.target = "_blank";
      // 设置 <a> 元素的文本内容
      linkElement.textContent = "Open Google Maps";
      window.open(linkElement.href, "_blank");
    },
    displayMap() {
      const mapOptions = {
        center: { lat: this.lat, lng: this.lng },
        zoom: 15,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: true,
      };
      const currentObj = this;
      this.loader.load().then(async () => {
        const { Map } = await google.maps.importLibrary("maps");
        let map = new Map(document.getElementById("map"), mapOptions);
        let mark = new google.maps.Marker({
          position: { lat: this.lat, lng: this.lng },
          map,
          title: this.case_info.name,
        });
        currentObj.marks.push(mark);
      });
    },
    // 建立地標
    setMarker() {
      // 建立一個新地標
      console.log("set marker");
      const marker = new google.maps.Marker({
        // 設定地標的座標
        position: { lat: this.lat, lng: this.lng },
        // 設定地標要放在哪一個地圖
        map: this.map,
      });
    },
    setInquires(inquires) {
      this.case_info.inquires = inquires;
      if (this.$refs.case_inquire_customer != null) {
        this.$refs.case_inquire_customer.parseRelatedInfo(inquires);
      }
    },
    queryInquires() {
      console.log("query inquires", this.case_info.id);
      // this.$refs.inquires.queryInquires();
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/${this.case_info.id}/inquires`;
      this.doHttpGet(url, null, this.setInquires);
    },
    add_land_info() {
      this.case_info.lands.push(_.cloneDeep(this.default_land));
    },
    remove_land_info(index) {
      // this.case_info.lands.splice(index, 1);
      this.confirm.title = "移除土地資訊";
      this.confirm.message = `確定要移除這筆資料嗎?`;
      this.confirm.function = () => {
        this.confirm.dialog = false;
        let tmp = this.case_info.lands[index];
        if (tmp.id != null) {
          this.remove_info.lands.push(tmp.id);
        }
        this.case_info.lands.splice(index, 1);
      };
      this.confirm.dialog = true;
    },
    add_building_info() {
      this.case_info.buildings.push(_.cloneDeep(this.default_building));
    },
    remove_building_info(index) {
      this.confirm.title = "移除建物資訊";
      this.confirm.message = `確定要移除這筆資料嗎?`;
      this.confirm.function = () => {
        this.confirm.dialog = false;
        // get the index of the item of case_info.buildings
        let tmp = this.case_info.buildings[index];
        if (tmp.id != null) {
          this.remove_info.buildings.push(tmp.id);
        }

        this.case_info.buildings.splice(index, 1);
      };
      this.confirm.dialog = true;
    },

    addSale(sale) {
      console.log("basic addSale", sale);
      if (sale.id != null) {
        // if sale id is in remove list, remove it
        this.remove_info.sales = this.remove_info.sales.filter(
          (x) => x.user_id !== sale.user_id
        );
      }
      this.modified_sales.push(sale);
    },
    removeSale(sale) {
      if (sale.id != null) {
        this.remove_info.sales.push(sale.id);
      }
      this.modified_sales = this.modified_sales.filter(
        (x) => x.user_id !== sale.user_id
      );
    },
    remove_client_confirm(index) {
      this.confirm.title = "移除委託人";
      this.confirm.message = `確定要移除這筆委託人資料嗎?`;
      this.confirm.function = () => {
        // call
        this.confirm.dialog = false;
        let person_id = this.$refs.case_principals.removeClient(index);
        this.removeRelatedPeople(person_id);
      };
      this.confirm.dialog = true;
    },

    removeRelatedPeople(person_id) {
      if (person_id != null) {
        this.remove_info.related_people.push(person_id);
      }
    },
    remove_owner_confirm(index) {
      this.confirm.title = "移除屋主";
      this.confirm.message = `確定要移除這筆屋主資料嗎?`;
      this.confirm.function = () => {
        // call
        this.confirm.dialog = false;
        let person_id = this.$refs.case_principals.removeOwner(index);
        this.removeRelatedPeople(person_id);
      };
      this.confirm.dialog = true;
    },
    remove_agent_confirm(index) {
      this.confirm.title = "移除代理人";
      this.confirm.message = `確定要移除這筆代理人資料嗎?`;
      this.confirm.function = () => {
        // call
        this.confirm.dialog = false;
        let person_id = this.$refs.case_principals.removeAgent(index);
        this.removeRelatedPeople(person_id);
      };
      this.confirm.dialog = true;
    },

    onPerformanceChange(item) {
      this.modified_sales.find((x) => x.user_id == item.user_id).performance =
        item.performance;
    },
    setReviewResult(status, comment) {
      this.case_info.review_status = status;
      this.case_info.review_comment = comment;
    },
  },
};
</script>
