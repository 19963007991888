<template>
  <v-dialog
    v-model="loading_dialog"
    :width="dialogWidth"
    @keydown.esc="$emit('confirm')"
    @click:outside="$emit('confirm')"
  >
    <v-card>
      <v-card-title class="text-h5 justify-center">
        <v-img
          src="@/assets/img/GRT橫.jpg"
          class="navbar-brand-img ms-3"
          max-width="240"
        >
        </v-img
      ></v-card-title>
      <v-card-text class="justify-center">
        <div class="px-6 py-6 text-center">
          <v-progress-circular
            v-if="show_loading"
            indeterminate
          ></v-progress-circular>
          <span v-if="message && message.length > 0" class="text-h4 px-6">{{
            message
          }}</span>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions v-if="show_confirm" class="justify-center">
        <v-spacer></v-spacer>
        <v-btn
          class="
            font-weight-bold
            text-xs
            bg-gradient-primary
            py-5
            px-6
            mt-6
            mb-2
            ms-auto
            text-white
          "
          @click="
            confirm_function();
            $emit('confirm');
          "
        >
          確定
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "common-loading",
  props: {
    title: {
      type: String,
      default: "處理中",
    },
    message: {
      type: String,
      default: "請稍後...",
    },
    loading_dialog: {
      type: Boolean,
      default: false,
    },
    show_loading: {
      type: Boolean,
      default: true,
    },
    show_confirm: {
      type: Boolean,
      default: false,
    },
    confirm_function: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    dialogWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "80%";
      } else {
        return "40%";
      }
    },
  },
  watch: {
    show_confirm: {
      handler: function (val, oldVal) {
        // console.log("show_confirm", val);
      },
      deep: true,
    },
  },
  methods: {},
};
</script>

<style scoped>
.mobile-width {
  width: 100% !important;
}
.desktop-width {
  width: 50% !important;
}
</style>
