const defaultCase = {
  type: "B",
  case_type: "A",
  name: null,
  deal_status: 0,
  shelf_status: 0,
  review_status: 0,
  sale_or_rent: 0,
  unit_price: null,
  total_price: null,
  total_area: null,
  lands: [{
    city: null,
    district: null,
    region: null,
    lbkey: null,
    mno: null, // main 母號
    sno: null, // sub
    land_area: null,
    land_ownership_portion: null,
    total_area: null,
    land_category_str: null,
    previous_transfer_date: null,
    previous_value: null,
    current_value: null,
    current_value_date: null,
    land_value_increment_tax: null,
    land_ownerships_str: null,
    distance_from_the_road: null,
    road_conditions: null,
    zone: null,
    category: null,
    reason: null,
    reason_date_str: null,
    urban_plan: null,
    latitude: null,
    longitude: null,
  }],
  buildings: [
    {
      city: null,
      district: null,
      region: null,
      lbkey: null,
      address: null,
      orientation: null,
      debt: null,
      sale_reason: null,
      width: null,
      depth: null,
      latitude: null,
      longitude: null,
      styles_str: null,
      purposes_str: null,
      parking_status_str: null,
      motor_parking_status_str: null,
      exteriors_str: null,
      equipments_str: null,
      floor_materials_str: null,
      has_management_fee: null,
      management_fee: null,
      object_name: null,
      parking_price: null,
      has_key: null,
      has_key_extra: null,
      total_building: null,
      main_building: null,
      sub_building: null,
      public_space: null,
      parking_space: null,
      use_space: null,
      increase: null,
      floors: null,
      basements: null,
      floor: null,
      rooms: null,
      living_rooms: null,
      bathrooms: null,
      road_width: null,
      alive_lane: null,
      units: null,
      total_units: null,
      elevators: null,
      completion_date_str: null,
      living_status: null,
      parking_no: null,
      motor_parking_no: null,
      guide_status: null,
      guard: null,
      deliver: null,
      atrium: null,
      source: null,
    }
  ],

  // cover: null,
  // location: null,
  // cadastral_map: null,
  // features: null,
  features_list: [{ text: null }, { text: null }, { text: null }, { text: null },],
  inquires: [], // 詢問
  transactions: [], //交易
  land_category: [], //土地類別
  sales: [],
  principals: {
    lowest_price: null,
    service_fee: null,
    has_commission: null,
    commission_ratio: null,
    same_owner: false,
  },
  related_people: [

    {
      id: null,
      name: null,
      type: 0,
      phone: null,
      mobile: null,
      address: null,
    },
    {
      id: null,
      name: null,
      type: 1,
      phone: null,
      mobile: null,
      address: null,
    },
    {
      id: null,
      name: null,
      type: 2,
      phone: null,
      mobile: null,
      address: null,
    },
  ],
};

const default_land = {
  city: null,
  district: null,
  region: null,
  lbkey: null,
  mno: null, // main 母號
  sno: null, // sub
  land_area: null,
  land_ownership_portion: null,
  total_area: null,
  land_category_str: null,
  previous_transfer_date: null,
  previous_value: null,
  current_value: null,
  current_value_date: null,
  land_value_increment_tax: null,
  land_ownerships_str: null,
  distance_from_the_road: null,
  road_conditions: null,
  zone: null,
  category: null,
  reason: null,
  reason_date_str: null,
  urban_plan: null,
  latitude: null,
  longitude: null,
};

const default_building = {
  city: null,
  district: null,
  region: null,
  lbkey: null,
  address: null,
  orientation: null,
  debt: null,
  sale_reason: null,
  width: null,
  depth: null,
  latitude: null,
  longitude: null,
  styles_str: null,
  purposes_str: null,
  parking_status_str: null,
  motor_parking_status_str: null,
  exteriors_str: null,
  equipments_str: null,
  floor_materials_str: null,
  has_management_fee: null,
  management_fee: null,
  object_name: null,
  parking_price: null,
  has_key: null,
  has_key_extra: null,
  total_building: null,
  main_building: null,
  sub_building: null,
  public_space: null,
  parking_space: null,
  use_space: null,
  increase: null,
  floors: null,
  basements: null,
  floor: null,
  rooms: null,
  living_rooms: null,
  bathrooms: null,
  road_width: null,
  alive_lane: null,
  units: null,
  total_units: null,
  elevators: null,
  completion_date_str: null,
  living_status: null,
  parking_no: null,
  motor_parking_no: null,
  guide_status: null,
  guard: null,
  deliver: null,
  atrium: null,
  source: null,
};

const inquire_headers = [
  {
    text: "姓名",
    sortable: false,
    value: "name",
    width: "10%",
    class: "font-weight-bolder border-bottom ",
  },
  {
    text: "電話",

    value: "phone",
    width: "10%",
    class: "font-weight-bolder  border-bottom",
    sortable: false,
  },
  {
    text: "行動電話",

    value: "mobile",
    width: "10%",
    sortable: false,
    class: "font-weight-bolder  border-bottom",
  },
  {
    text: "地址",

    value: "address",
    width: "20%",
    class: "font-weight-bolder  border-bottom",
    sortable: false,
  },
  {
    text: "email",

    value: "email",
    width: "10%",
    class: "font-weight-bolder  border-bottom",
    sortable: false,
  },
  {
    text: "詢問事項",

    value: "context",
    sortable: false,
    width: "20%",
    class: "font-weight-bolder  border-bottom",
  },
  {
    text: "詢問日期",

    value: "date",
    width: "12%",
    sortable: false,
    class: "font-weight-bolder  border-bottom",
  },
  {
    text: "詢問對象",

    value: "sale_id",
    width: "10%",
    sortable: false,
    class: "font-weight-bolder  border-bottom",
  },
  {
    text: "動作",
    value: "actions",
    align: "end",
    sortable: false,
    width: "5%",
    class: "font-weight-bolder  border-bottom",
  },
];
const readonly_inquire_headers = [
  {
    text: "姓名",
    cellClass: "border-bottom",
    sortable: false,
    value: "name",
    width: "10%",
    class: "font-weight-bolder  border-bottom",
  },
  {
    text: "電話",
    cellClass: "border-bottom",
    value: "phone",
    width: "10%",
    class: "font-weight-bolder  border-bottom",
    sortable: false,
  },
  {
    text: "行動電話",
    cellClass: "border-bottom",
    value: "mobile",
    width: "10%",
    sortable: false,
    class: "font-weight-bolder  border-bottom",
  },
  {
    text: "地址",
    cellClass: "border-bottom",
    value: "address",
    width: "15%",
    class: "font-weight-bolder  border-bottom",
    sortable: false,
  },
  {
    text: "email",
    cellClass: "border-bottom",
    value: "email",
    width: "10%",
    class: "font-weight-bolder  border-bottom",
    sortable: false,
  },
  {
    text: "詢問事項",
    cellClass: "border-bottom",
    value: "inquires",
    sortable: false,
    width: "25%",
    class: "font-weight-bolder  border-bottom",
  },
  {
    text: "詢問日期",
    cellClass: "border-bottom",
    value: "inquire_date",
    width: "10%",
    sortable: false,
    class: "font-weight-bolder  border-bottom",
  }
];

const transaction_headers = [
  {
    text: "姓名",
    align: "start",
    cellClass: "border-bottom",
    sortable: false,
    value: "full_name",
    class: "text-secondary font-weight-bolder text-md border-bottom",
  },
  // {
  //   text: "身份證或統編",
  //   cellClass: "border-bottom",
  //   value: "id_full",
  //   class: "text-secondary font-weight-bolder text-md border-bottom",
  //   sortable: false,
  // },
  // {
  //   text: "Email",
  //   cellClass: "border-bottom",
  //   value: "email",
  //   class: "text-secondary font-weight-bolder text-md border-bottom",
  //   sortable: false,
  // },
  {
    text: "地址",
    cellClass: "border-bottom",
    value: "address_1",
    class: "text-secondary font-weight-bolder text-md border-bottom",
    sortable: false,
  },

  // {
  //   text: "市話",
  //   cellClass: "border-bottom",
  //   value: "home",
  //   class: "text-secondary font-weight-bolder text-md border-bottom",
  //   sortable: false,
  // },
  {
    text: "行動電話",
    cellClass: "border-bottom",
    value: "mobile",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md border-bottom",
  },
  {
    text: "總金額",
    cellClass: "border-bottom",
    value: "total_price",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md border-bottom",
  },
  {
    text: "簽約金",
    cellClass: "border-bottom",
    value: "singing_price",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md border-bottom",
  },
  {
    text: "簽約日",
    cellClass: "border-bottom",
    value: "signing_date",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md border-bottom",
  },
  {
    text: "動作",
    cellClass: "border-bottom",
    value: "actions",
    align: "end",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md border-bottom",
  },
];

const has_keys_enum = [
  { text: "無鑰匙", value: false },
  { text: "鑰匙", value: true },
];

const is_alive_lane_enum = [
  { text: "活巷", value: true },
  { text: "死巷", value: false },
];
const guard_status_enum = [
  { text: "有", value: true },
  { text: "無", value: false },
];
const guide_status_enum = [
  { text: "洽開發", value: 0 },
  { text: "自行前往", value: 1 },
  { text: "鑰匙", value: 2 },
];
const deliver_enum = [
  { text: "立即", value: 0 },
  { text: "商談", value: 1 },
];
const atrium_status_enum = [
  { text: "無", value: false },
  { text: "有", value: true },
];
const management_fee_enum = [
  { text: "無", value: false },
  { text: "有", value: true },
];

const exteriors_enum = [
  { text: "二丁掛", value: 0 },
  { text: "方塊磚", value: 1 },
  { text: "馬賽克", value: 2 },
  { text: "洗石子", value: 3 },
  { text: "其他", value: 4 },
];

const floor_materials_enum = [
  { text: "木板", value: 0 },
  { text: "大理石", value: 1 },
  { text: "磁磚", value: 2 },
  { text: "其他", value: 3 },
];

const photo_type_enum = {
  Cover: 0,
  Location: 1,
  CadastralMap: 2,
  Other: 3,
};

const photo_type_enum_str = [
  { id: photo_type_enum.Cover, name: "封面" },
  { id: photo_type_enum.Location, name: "地圖位置" },
  { id: photo_type_enum.CadastralMap, name: "地籍圖 or 格局圖" },
  { id: photo_type_enum.Other, name: "一般" },
];

const photo_status = {
  Empty: 0, // 空
  Pending: 1, // 等待上傳
  Uploading: 2, //上傳中
  Done: 3, // 已上傳
  Fail: 4, // 上傳失敗 需要重試
};

const ocr_status_enum = {
  Upload: 0,
  Processing: 1,
  Done: 2,
  FailUpload: 8,
  NoQuota: 11,
};

const ocr_status_enum_str = [
  { text: "準備上傳", value: ocr_status_enum.Upload },
  { text: "處理中", value: ocr_status_enum.Processing },
  { text: "完成", value: ocr_status_enum.Done },
  { text: "上傳失敗", value: ocr_status_enum.FailUpload },
  { text: "無額度", value: ocr_status_enum.NoQuota },
];
// class BusinessType(IntEnum):
const business_type_enum = {
  Development: 0,
  Sale: 1,
};

const business_type_str = [
  { text: "開發業務", value: business_type_enum.Development },
  { text: "銷售業務", value: business_type_enum.Sale },
];

const case_related_type = {
  Inquires: 0,
  Transactions: 1,
  Photos: 2,
  Owners: 3,
};

// class DealStatus(IntEnum):
//     NOT_COMPLETED = 0
//     COMPLETED = 1

// class ShelfStatus(IntEnum):
//     OFF_SHELF = 0
//     ON_SHELF = 1
const deal_status_enum = {
  NOT_COMPLETED: 0,
  COMPLETED: 1,
  ALL: 2,
};

const deal_status_str = [
  { text: "未成交", value: deal_status_enum.NOT_COMPLETED },
  { text: "已成交", value: deal_status_enum.COMPLETED },
];

const deal_status_filter = [
  { text: "未成交", value: deal_status_enum.NOT_COMPLETED },
  { text: "已成交", value: deal_status_enum.COMPLETED },
  { text: "全部", value: null },
];

const shelf_status_enum = {
  OFF_SHELF: 0,
  ON_SHELF: 1,
};

const review_status_enum = {
  Draft: 0,
  Submitting: 1,
  Pass: 2,
  Failed: 3,
};

const review_status_enum_str = [
  { text: "未送審", value: review_status_enum.Draft },
  { text: "送審中", value: review_status_enum.Submitting },
  { text: "通過", value: review_status_enum.Pass },
  { text: "退件", value: review_status_enum.Failed },
];


const review_status_draft_enum_str = [
  { text: "未送審", value: review_status_enum.Draft },
  { text: "送審中", value: review_status_enum.Submitting },
];
const review_status_manage_enum_str = [
  { text: "通過", value: review_status_enum.Pass },
  { text: "退件", value: review_status_enum.Failed },
];

const shelf_status_str = [
  { text: "下架", value: shelf_status_enum.OFF_SHELF },
  { text: "上架", value: shelf_status_enum.ON_SHELF },
];

const shelf_status_filter = [
  { text: "上架", value: shelf_status_enum.ON_SHELF },
  { text: "下架", value: shelf_status_enum.OFF_SHELF },
  { text: "全部", value: null },
];

const sale_shelf_status_filter = [
  { text: "上架", value: shelf_status_enum.ON_SHELF },
  { text: "下架(僅本人)", value: shelf_status_enum.OFF_SHELF },
];

// class LandCategoryEnum(IntEnum):
//     FarmLand = 0  # 農地
//     BuildingLand = 1  # 建地
//     Road = 2  # 道路
//     WaterResourcesLand = 3  # 水利地
const land_category_enum = {
  FarmLand: 0,
  BuildingLand: 1,
  Road: 2,
  WaterResourcesLand: 3,
};

const land_category_enum_str = [
  { text: "農地", value: land_category_enum.FarmLand },
  { text: "建地", value: land_category_enum.BuildingLand },
  { text: "道路", value: land_category_enum.Road },
  { text: "水利地", value: land_category_enum.WaterResourcesLand },
];

const land_ownership_enum_str = [
  { text: "所有權全部", value: 0 },
  { text: "所有權持分", value: 1 },
  { text: "地上權", value: 2 },
  { text: "租任權", value: 3 },
];

const road_condition_enum_str = [
  { text: "雙面臨路", value: 0 },
  { text: "臨路", value: 1 },
  { text: "其他", value: 2 },
];

const urban_plan_enum_str = [
  { text: "都計內", value: true },
  { text: "都計外", value: false },
];

const commission_enum_str = [
  { text: "不佔佣", value: false },
  { text: "佔佣", value: true },
];

const same_principal_name_str = [
  { text: "同委託人", value: true },
  { text: "自行輸入", value: false },
];

const equipments_enum = [
  { text: "廚具", value: 0 },
  { text: "餐桌", value: 1 },
  { text: "流理台", value: 2 },
  { text: "瓦斯爐", value: 3 },
  { text: "熱水器", value: 4 },
  { text: "洗衣機", value: 5 },
  { text: "沙發", value: 6 },
  { text: "冷氣", value: 7 },
  { text: "燈飾", value: 8 },
  { text: "床組", value: 9 },
  { text: "衣櫃", value: 10 },
  { text: "依現況", value: 11 },
];

const parking_status_enum = {
  No: 0,
  Convenient: 1,
  Own: 2,
  Flat: 3,
  Mechanical: 4,
  Rent: 5,
};

const parking_status_enum_str = [
  { text: "無", value: parking_status_enum.No },
  { text: "方便", value: parking_status_enum.Convenient },
  { text: "自有", value: parking_status_enum.Own },
  { text: "平面", value: parking_status_enum.Flat },
  { text: "機械", value: parking_status_enum.Mechanical },
  { text: "可租", value: parking_status_enum.Rent },
]


const orientation_enum = {
  North: 0,
  South: 1,
  East: 2,
  West: 3,
  NorthEast: 4, // 西南朝東北
  NorthWest: 5, // 東南朝西北
  SouthEast: 6, // 西北朝東南
  SouthWest: 7, // 東北朝西南
};
const orientation_enum_str = [
  { text: "座南朝北", value: orientation_enum.North },
  { text: "座北朝南", value: orientation_enum.South },
  { text: "座西朝東", value: orientation_enum.East },
  { text: "座東朝西", value: orientation_enum.West },
  { text: "座西南朝東北", value: orientation_enum.NorthEast },
  { text: "座東南朝西北", value: orientation_enum.NorthWest },
  { text: "座西北朝東南", value: orientation_enum.SouthEast },
  { text: "座東北朝西南", value: orientation_enum.SouthWest },
];

const motor_parking_status_enum = {
  No: 0,
  Convenient: 1,
  Rent: 2,
  Yes: 3,
};

const motor_parking_status_enum_str = [
  { text: "無", value: motor_parking_status_enum.No },
  { text: "有", value: motor_parking_status_enum.Yes },
  { text: "方便", value: motor_parking_status_enum.Convenient },
  { text: "可租", value: motor_parking_status_enum.Rent },
];

const building_style_enum = {
  Elevator: 0,
  House: 1,
  Villa: 2,
  Apartment: 3,
  Suites: 4,
  Store: 5,
  Factory: 6,
  FarmHouse: 7,
}
const building_style_enum_str = [
  { text: "電梯", value: building_style_enum.Elevator },
  { text: "透天", value: building_style_enum.House },
  { text: "別墅", value: building_style_enum.Villa },
  { text: "公寓", value: building_style_enum.Apartment },
  { text: "套房", value: building_style_enum.Suites },
  { text: "店面", value: building_style_enum.Store },
  { text: "廠房", value: building_style_enum.Factory },
  { text: "農舍", value: building_style_enum.FarmHouse },
];

const building_purpose_enum = {
  Residential: 0,
  Office: 1,
  Store: 2,
  ResidentialOffice: 3,
  ResidentialStore: 4,
  ParkingSpace: 5,
  Suite: 6,
  FactoryOffice: 7,
};

const building_purpose_enum_str = [
  { text: "住宅", value: building_purpose_enum.Residential },
  { text: "辦公", value: building_purpose_enum.Office },
  { text: "店面", value: building_purpose_enum.Store },
  { text: "住辦", value: building_purpose_enum.ResidentialOffice },
  { text: "住店", value: building_purpose_enum.ResidentialStore },
  { text: "車位", value: building_purpose_enum.ParkingSpace },
  { text: "套房", value: building_purpose_enum.Suite },
  { text: "廠辦", value: building_purpose_enum.FactoryOffice },
];


const living_status_enum = {
  Empty: 0,
  SelfHousing: 1,
  Rent: 2,
  FullRent: 3,
};

const living_status_enum_str = [
  { text: "空屋", value: living_status_enum.Empty },
  { text: "自住", value: living_status_enum.SelfHousing },
  { text: "出租", value: living_status_enum.Rent },
  { text: "滿租", value: living_status_enum.FullRent },
];

const object_type_enum = {
  Land: "L",
  Building: "B",
};

const object_type_enum_str = [
  { text: "建物", value: object_type_enum.Building },
  { text: "土地", value: object_type_enum.Land },
];

const case_type_enum_str = [
  { text: "A", value: "A" },
  { text: "B", value: "B" },
  { text: "C", value: "C" },
]

const stringToArray = [
  "land_ownerships_str",
  "land_category_str",
  "styles_str",
  "purposes_str",
  "parking_status_str",
  "motor_parking_status_str",
  "exteriors_str",
  "equipments_str",
  "floor_materials_str",
];

const arrayToString = [
  "land_ownerships",
  "styles",
  "purposes",
  "parking_status",
  "motor_parking_status",
  "exteriors",
  "equipments",
  "floor_materials",
  "land_category",
];

const draft_case_headers = [
  {
    text: "類型",
    value: "type",
    width: "6%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "審核",
    value: "review_status",
    width: "5%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "案名",
    value: "name",
    width: "10%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "地址/地號",
    value: "address",
    width: "20%",
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "封面",
    value: "cover",
    width: "100px",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "單價",
    value: "unit_price",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "總價",
    value: "total_price",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "面積",
    value: "total_area",
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "建立時間",
    value: "create_time",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "操作",
    value: "actions",
    align: "end",
    sortable: false,
    sortable: false,
    class: "text-secondary font-weight-bolder text-md ",
  },
];

const case_headers = [
  {
    text: "編號",
    value: "serial_number",
    width: "5%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "類型",
    value: "type",
    width: "6%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },

  {
    text: "狀態",
    value: "deal_status",
    width: "8%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "上架",
    value: "shelf_status",
    width: "5%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "案名",
    value: "name",
    width: "10%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "地址/地號",
    value: "address",
    width: "20%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "封面",
    value: "cover",
    width: "100px",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "單價",
    value: "unit_price",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "總價",
    value: "total_price",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "面積",
    value: "total_area",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "詢問",
    value: "inquire_count",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "建立時間",
    value: "create_time",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "操作",
    value: "actions",
    align: "end",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
];

const manage_case_headers = [
  {
    text: "編號",
    value: "serial_number",
    width: "6%",
    sortable: true,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "類型",
    value: "type",
    width: "6%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },

  {
    text: "狀態",
    value: "deal_status",
    width: "6%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "上架",
    value: "shelf_status",
    width: "6%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "審核",
    value: "review_status",
    width: "6%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "案名",
    value: "name",
    width: "10%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "地址/地號",
    value: "address",
    width: "20%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "封面",
    value: "cover",
    width: "100px",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "單價",
    value: "unit_price",
    width: "5%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "總價",
    value: "total_price",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "面積",
    value: "total_area",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "詢問",
    value: "inquire_count",
    sortable: false,
    width: "6%",
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "建立",
    value: "create_time",
    width: "9%",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
  {
    text: "操作",
    value: "actions",
    align: "end",
    sortable: false,
    class: "text-secondary font-weight-bolder text-md",
  },
];

const case_mode_enum = {
  List: 0,
  Draft: 1,
  Manage: 2,
};
// dump case mode enum
const case_mode_enum_str = [
  { text: "列表", value: case_mode_enum.List },
  { text: "草稿", value: case_mode_enum.Draft },
  { text: "管理", value: case_mode_enum.Manage },
];

// class RelatedPersonType(IntEnum):
//     Client = 0  # 委託人
//     Owner = 1  # 地主
//     Agent = 2  # 中介人
const related_person_type_enum = {
  Client: 0,
  Owner: 1,
  Agent: 2,
  Buyer: 3,
  Inquire: 4,
};

const related_person_type_enum_str = [
  { text: "委託人", value: related_person_type_enum.Client },
  { text: "屋主/地主", value: related_person_type_enum.Owner },
  { text: "中介人", value: related_person_type_enum.Agent },
  { text: "買方", value: related_person_type_enum.Buyer },
  { text: "詢問人", value: related_person_type_enum.Inquire },
];

const sale_or_rent_enum = {
  Sale: 0,
  Rent: 1,
};

const sale_or_rent_enum_str = [
  { text: "銷售", value: sale_or_rent_enum.Sale },
  { text: "出租", value: sale_or_rent_enum.Rent },
];

const case_template_type_enum = {
  Land: "L",
  Building: "B",
  LandRent: "LR",
  BuildingRent: "BR",
  Cover: "C",
  WaterMark: "W",
  WaterImage: "WI",
};

const case_template_type_enum_str = [
  { text: "土地", value: case_template_type_enum.Land },
  { text: "建物", value: case_template_type_enum.Building },
  { text: "土地出租", value: case_template_type_enum.LandRent },
  { text: "建物出租", value: case_template_type_enum.BuildingRent },
  { text: "封面", value: case_template_type_enum.Cover },
  { text: "浮水印", value: case_template_type_enum.WaterMark },
  { text: "格局圖浮水印", value: case_template_type_enum.WaterImage },
];

export {
  defaultCase,
  inquire_headers,
  transaction_headers,
  photo_type_enum,
  photo_type_enum_str,
  photo_status,
  ocr_status_enum,
  ocr_status_enum_str,
  has_keys_enum,
  is_alive_lane_enum,
  guard_status_enum,
  guide_status_enum,
  deliver_enum,
  atrium_status_enum,
  management_fee_enum,
  exteriors_enum,
  floor_materials_enum,
  business_type_enum,
  business_type_str,
  case_related_type,
  deal_status_enum,
  deal_status_str,
  deal_status_filter,
  shelf_status_enum,
  shelf_status_str,
  shelf_status_filter,
  sale_shelf_status_filter,
  land_category_enum,
  land_category_enum_str,
  land_ownership_enum_str,
  road_condition_enum_str,
  urban_plan_enum_str,
  commission_enum_str,
  same_principal_name_str,
  readonly_inquire_headers,
  equipments_enum,
  parking_status_enum,
  parking_status_enum_str,
  orientation_enum,
  orientation_enum_str,
  motor_parking_status_enum,
  motor_parking_status_enum_str,
  building_style_enum,
  building_style_enum_str,
  building_purpose_enum,
  building_purpose_enum_str,
  living_status_enum,
  living_status_enum_str,
  object_type_enum,
  object_type_enum_str,
  case_type_enum_str,
  stringToArray,
  arrayToString,
  default_building,
  default_land,
  draft_case_headers,
  case_headers,
  manage_case_headers,
  case_mode_enum,
  case_mode_enum_str,
  review_status_enum,
  review_status_enum_str,
  review_status_draft_enum_str,
  review_status_manage_enum_str,
  related_person_type_enum,
  related_person_type_enum_str,
  sale_or_rent_enum,
  sale_or_rent_enum_str,
  case_template_type_enum,
  case_template_type_enum_str,
};
