<template>
  <label class="text-sm font-weight-bolder ms-1">{{ label }}</label>
</template>
<script>
export default {
  name: "Common-Label",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
