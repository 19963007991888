<template>
  <v-card class="mt-2">
    <div class="px-4 py-4 pt-0">
      <v-row>
        <v-col
          sm="2"
          cols="12"
          v-for="(feature, index) in feature_list"
          :key="index"
          class="py-1 pb-2"
        >
          <label class="font-weight-bolder">訴求重點 {{ index + 1 }}</label>
          <v-row>
            <v-col cols="11" class="px-2">
              <v-textarea
                hide-details
                outlined
                dense
                rows="3"
                class="input-style font-size-input border border-radius-md mt-2"
                v-model="feature.text"
                :disabled="readOnly"
              >
              </v-textarea>
            </v-col>
            <v-col
              cols="1"
              class="px-1"
              v-if="propEditMode != edit_mode_enum.View"
            >
              <v-icon
                v-if="feature_list.length > 1"
                small
                @click="removeFeature(index)"
                >mdi-close</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="2" cols="12" v-if="propEditMode > edit_mode_enum.View">
          <v-btn
            color="blue"
            class="mt-4 btn-primary bg-gradient-primary"
            @click="addFeature()"
          >
            新增訴求
            <v-icon right dark> mdi-star-four-points-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import { edit_mode_enum } from "@/definitions.js";
export default {
  name: "case-features",
  props: {
    features: {
      type: Array,
      default: () => [{ text: null }],
    },
    propEditMode: {
      type: Number,
      default: 0,
    },
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data(vm) {
    return {
      edit_mode_enum: edit_mode_enum,
      feature_list: [],
    };
  },
  watch: {
    features: {
      handler(val) {
        this.setFeature(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.setFeature(this.features);
  },
  computed: {
    readOnly() {
      return this.propEditMode == this.edit_mode_enum.View || this.propReadOnly;
    },
  },
  methods: {
    setFeature(val) {
      this.feature_list = JSON.parse(JSON.stringify(val));
    },
    addFeature() {
      this.feature_list.push({ text: null });
    },
    removeFeature(index) {
      this.feature_list.splice(index, 1);
    },
    getInfo() {
      let features_string = "";
      if (this.feature_list.length > 0) {
        for (let i = 0; i < this.feature_list.length; i++) {
          if (this.feature_list[i].text != null) {
            if (features_string != "") {
              features_string += "||" + this.feature_list[i].text;
            } else {
              features_string += this.feature_list[i].text;
            }
          }
        }
      }
      return features_string;
    },
  },
};
</script>
