<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <v-card-title>
      <h5 class="text-h5 font-weight-bold text-typo">
        {{ $t("Transaction") }}
      </h5>
      <v-spacer></v-spacer>
      <!-- <v-btn color="blue" class="ma-2 white--text" @click="addTransaction()">
        {{ $t("Add Transaction") }}
        <v-icon right dark> mdi-application-edit-outline </v-icon>
      </v-btn> -->
    </v-card-title>
    <div class="px-6 pb-6 pt-0">
      <v-row v-for="(item, index) in transactions" :key="index" dense>
        <v-col cols="1" class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("TransactionType")
          }}</label>

          <v-text-field
            hide-details
            outlined
            class="font-size-input border border-radius-md mt-2"
            :value="getTypeName(item.type)"
            disabled
          >
          </v-text-field>
        </v-col>

        <v-col class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >案件名稱</label
          >
          <v-text-field
            hide-details
            outlined
            placeholder="ex:桃園市大園區客運二段 "
            class="font-size-input border border-radius-md mt-2"
            v-model="item.case_name"
          >
          </v-text-field>
        </v-col>
        <v-col class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("Transaction Date")
          }}</label>
          <v-menu
            v-model="item.menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                outlined
                class="font-size-input border border-radius-md mt-2"
                v-model="item.date"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="item.date"
              locale="zh-tw"
              @input="item.menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1">總價</label>
          <v-text-field
            hide-details
            outlined
            class="font-size-input border border-radius-md mt-2"
            v-model="item.total_price"
          >
          </v-text-field>
        </v-col>
        <v-col class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >簽約日</label
          >
          <v-text-field
            hide-details
            outlined
            class="font-size-input border border-radius-md mt-2"
            v-model="item.signing_date"
          >
          </v-text-field>
        </v-col>
        <v-col class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1">佣金</label>
          <v-text-field
            hide-details
            outlined
            class="font-size-input border border-radius-md mt-2"
            v-model="item.commission"
          >
          </v-text-field>
        </v-col>

        <v-col cols="1" class="text-right py-0">
          <v-btn
            :ripple="false"
            elevation="0"
            class="mt-6 py-5 px-6 btn-danger bg-gradient-default"
            small
            @click="$emit('view_Case', item)"
          >
            檢視
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import {
  transaction_type_enum,
  transaction_type_enum_str,
} from "@/definitions.js";
import { object_type_enum, object_type_enum_str } from "@/case.js";
import CommonUtility from "@/util/CommonUtility.js";
export default {
  name: "customer-transaction",
  props: {
    transactionInfo: {
      type: Array,
      default: () => [
        {
          type: 0,
          date: null,
          address: "",
          lkey_str: "",
          area: 0,
          price: 0,
        },
      ],
    },
  },

  data(vm) {
    return {
      transaction_type_enum: transaction_type_enum,
      transaction_type_enum_str: transaction_type_enum_str,
      object_type_enum: object_type_enum,
      object_type_enum_str: object_type_enum_str,
      phone_type_title: vm.$i18n.t("PhoneType"),
      transactions: [],
    };
  },
  mounted() {
    this.transactions = this.transactionInfo;
  },
  watch: {
    transactionInfo: {
      handler(val) {
        this.transactions = val;
      },
      deep: true,
    },
  },
  methods: {
    reset() {},
    addTransaction() {
      this.$emit("addTransaction");
    },
    removeTransaction(item) {
      const index = this.transactionInfo.indexOf(item);
      this.$emit("removeTransaction", index);
    },
    getTypeName(type) {
      return CommonUtility.getText(type, object_type_enum_str);
    },
  },
};
</script>
