<template>
  <v-dialog
    v-model="confirm_dialog"
    scrollable
    :width="dialogWidth"
    @click:outside="$emit('cancel')"
    @keydown.esc="$emit('cancel')"
  >
    <v-card>
      <v-card-title class="text-h5 justify-center">
        <v-img
          src="@/assets/img/GRT橫.jpg"
          class="navbar-brand-img ms-3"
          max-width="240"
        >
        </v-img
      ></v-card-title>
      <v-card-text class="justify-center py-4">
        <div class="text-center">
          <span class="text-h4">{{ message }}</span>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          class="
            font-weight-bold
            text-xs
            btn-default
            bg-gradient-secondary
            py-5
            px-6
            mt-6
            mb-2
            me-2
            ms-auto
            text-white
          "
          @click="$emit('cancel')"
        >
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="
            font-weight-bold
            text-xs
            bg-gradient-primary
            py-5
            px-6
            mt-6
            mb-2
            me-2
            ms-auto
            text-white
          "
          @click="confirm_function()"
        >
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "common-confirm",
  props: {
    title: {
      type: String,
      default: "確認",
    },
    message: {
      type: String,
      default: "確定執行此動作",
    },
    confirm_dialog: {
      type: Boolean,
      default: false,
    },
    confirm_function: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    dialogWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "90%" : "50%";
    },
  },
  methods: {},
};
</script>
