<template>
  <v-card class="card-shadow border-radius-xl py-1 my-2">
    <!-- lbkey -->
    <v-card-title class="justify-center ml-2 mr-4 py-2">
      <h4 class="text-md text-grt font-weight-bolder">
        土地 ({{ prop_index + 1 }})
      </h4>
      <v-spacer></v-spacer>
      <v-btn v-if="prop_index > 0" icon @click="remove_land()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="mx-2 py-2">
      <v-col md="1" cols="12" class="py-1 px-1">
        <common-label label="縣市"></common-label>
        <v-autocomplete
          :items="city_info"
          v-model="land_info.city"
          class="input-style font-size-input border-radius-md select-style mt-2"
          hide-details
          outlined
          single-line
          @change="onSelectCity(land_info.city)"
          :disabled="readOnly"
        >
        </v-autocomplete>
      </v-col>
      <v-col md="1" cols="12" class="py-1 px-1">
        <common-label label="行政區"></common-label>
        <v-autocomplete
          :items="districts"
          v-model="land_info.district"
          class="input-style font-size-input border-radius-md select-style mt-2"
          hide-details
          outlined
          single-line
          @change="onSelectDistrict(land_info.city, land_info.district, true)"
          :disabled="readOnly"
        >
        </v-autocomplete>
      </v-col>
      <v-col md="2" cols="12" class="py-1 px-1">
        <common-label label="地段小段"></common-label>
        <v-autocomplete
          :items="regions"
          v-model="land_info.region"
          class="input-style font-size-input border-radius-md select-style mt-2"
          hide-details
          outlined
          single-line
          item-value="region_code"
          :item-text="(item) => item.region_name + '(' + item.region_code + ')'"
          :disabled="readOnly"
        >
        </v-autocomplete>
      </v-col>
      <v-col md="1" cols="12" class="py-1 px-1">
        <common-label label="母號"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.mno"
          maxlength="4"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="12" class="py-1 px-1">
        <common-label label="子號"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.sno"
          maxlength="4"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="py-1 px-1">
        <common-label label="北緯"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.latitude"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="4" class="py-1 px-1">
        <common-label label="東經"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.longitude"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col cols="1" class="py-1" align-self="end">
        <v-icon
          large
          :color="getColor(land_info)"
          :disabled="!isAllowLandLocation"
          @click="openMap(land_info.latitude, land_info.longitude)"
          >mdi-map-marker</v-icon
        >
      </v-col>
    </v-row>
    <v-row class="mx-2 py-2">
      <v-col md="1" cols="12" class="px-1">
        <common-label label="土地面積"></common-label>
        <v-text-field
          hide-details
          hide-spin-buttons
          outlined
          :class="common_text_edit_class"
          v-model="land_info.land_area"
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="12" class="px-1">
        <common-label label="持分"></common-label>
        <v-text-field
          hide-details
          hide-spin-buttons
          outlined
          :class="common_text_edit_class"
          v-model="land_info.land_ownership_portion"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="12" class="px-1">
        <common-label label="總面積"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.total_area"
          suffix="坪"
          type="number"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="12" class="px-1">
        <common-label label="面寬"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.width"
          suffix="米"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="12" class="px-1">
        <common-label label="深度"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.depth"
          suffix="米"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="4" cols="12" class="px-1">
        <common-label label="土地所屬">土地所屬</common-label>
        <v-row class="ml-2">
          <v-checkbox
            @click="onOwnershipChange"
            v-for="(item, index) in land_ownership_enum_str"
            :key="index"
            v-model="land_ownerships"
            :label="item.text"
            :value="item.value"
            :disabled="readOnly"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-col md="3" cols="12" class="px-1">
        <common-label label="土地類別"></common-label>
        <v-row class="ml-2">
          <v-checkbox
            @change="onCategoryChange"
            v-for="(item, j) in land_category_enum_str"
            :key="j"
            v-model="land_category"
            :label="item.text"
            :value="item.value"
            :disabled="readOnly"
          ></v-checkbox>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col md="2" cols="12" class="px-1">
        <common-label label="土地前次移轉日期"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.previous_transfer_date"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="前次現值"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.previous_value"
          suffix="/平方公尺"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="公告現值"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.current_value"
          suffix="/平方公尺"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="日期"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.current_value_date"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="1" cols="12">
        <common-label label="臨路"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.distance_from_the_road"
          suffix="米"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="3" cols="12" class="px-1">
        <common-label label="臨路狀況"></common-label>
        <v-row class="ml-2">
          <v-radio-group row v-model="land_info.road_conditions">
            <v-radio
              v-for="(item, i) in road_condition_enum_str"
              :key="i + item"
              :label="item.text"
              :value="item.value"
              :disabled="readOnly"
            ></v-radio>
          </v-radio-group>
          <v-btn
            class="my-3"
            icon
            @click="land_info.road_conditions = null"
            :disabled="readOnly"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col md="2" cols="12" class="px-1">
        <common-label label="使用分區"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.zone"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="使用地類別"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.category"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="登記原因"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.reason"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="取得日"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.reason_date_str"
          :disabled="readOnly"
        >
        </v-text-field>
        <!-- <v-menu
          v-model="land_info.reason_date_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="readOnly"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              outlined
              :class="common_text_edit_class"
              :value="displayChineseYear(land_info.reason_date_str)"
              v-bind="attrs"
              v-on="on"
              :disabled="readOnly"
              clearable
              @click:clear="land_info.reason_date_str = null"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="land_info.reason_date_str"
            :year-format="formatChineseYear"
            locale="zh-tw"
            @input="land_info.reason_date_menu = false"
          >
          </v-date-picker>
        </v-menu> -->
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="都市計畫"></common-label>
        <v-row class="mx-2">
          <v-radio-group
            row
            v-model="land_info.urban_plan"
            :disabled="readOnly"
          >
            <v-radio
              v-for="(item, i) in urban_plan_enum_str"
              :key="item + i"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
          <v-btn class="my-3" icon @click="land_info.urban_plan = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col md="2" cols="12" class="px-1">
        <common-label label="增值稅概算"></common-label>
        <v-text-field
          hide-details
          outlined
          :class="common_text_edit_class"
          v-model="land_info.land_value_increment_tax"
          :disabled="readOnly"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { edit_mode_enum, common_text_edit_class } from "@/definitions.js";

import {
  case_related_type,
  land_category_enum_str,
  land_ownership_enum_str,
  road_condition_enum_str,
  urban_plan_enum_str,
  case_mode_enum,
} from "@/case.js";

import { city_info, getRegionName } from "@/cdr.js";
import CDRMixin from "@/components/CRM/CDRMixin.vue";
import CaseMixin from "@/components/CRM/CaseMixin.vue";
import CommonLabel from "@/components/CRM/CommonLabel.vue";
import CommonUtility from "@/util/CommonUtility.js";

import _ from "lodash";

export default {
  name: "case-basic-info",
  props: {
    propEditMode: {
      type: Number,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    prop_index: {
      type: Number,
    },
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CDRMixin, CaseMixin],
  components: {
    CommonLabel,
  },
  data() {
    return {
      land_category: [],
      land_ownerships: [],
      land_info: {},
      city_info: city_info,
      land_ownership_enum_str: land_ownership_enum_str,
      road_condition_enum_str: road_condition_enum_str,
      urban_plan_enum_str: urban_plan_enum_str,
      land_category_enum_str: land_category_enum_str,
      edit_mode_enum: edit_mode_enum,
      case_mode_enum: case_mode_enum,
      case_related_type: case_related_type,
      common_text_edit_class: common_text_edit_class,
      colors: {
        disable: "gre",
        enable: "red",
      },
    };
  },
  mounted() {
    this.parseLandInfo(this.info);
  },
  watch: {
    info: {
      handler: function (val, oldVal) {
        this.parseLandInfo(val);
      },
      deep: true,
    },
  },
  computed: {
    isAllowLandLocation() {
      return (
        this.land_info != null &&
        this.land_info.latitude != null &&
        this.land_info.longitude != null &&
        this.land_info.latitude != "" &&
        this.land_info.longitude != ""
      );
    },
    readOnly() {
      return this.propEditMode == this.edit_mode_enum.View || this.propReadOnly;
    },
  },
  methods: {
    parseLandInfo(val) {
      // console.log("parse changed", val, "propEditMode", this.propEditMode);
      // copy all values from val to land_info
      this.land_info = _.cloneDeep(val);

      this.land_category = this.land_info.land_category_str
        ? this.land_info.land_category_str.split(",").map(Number)
        : [];
      this.land_ownerships = this.land_info.land_ownerships_str
        ? this.land_info.land_ownerships_str.split(",").map(Number)
        : [];
      if (this.land_info.city) {
        this.districts = this.district_info[this.land_info.city];
        this.initRegions();
        if (this.land_info.city && this.land_info.district) {
          this.onSelectDistrict(
            this.land_info.city,
            this.land_info.district,
            true
          );
        }
      }
    },
    initRegions() {
      if (
        this.land_info.region != null &&
        this.land_info.region != "" &&
        this.land_info.region_name != null &&
        this.land_info.region_name != ""
      ) {
        this.regions = [
          {
            region_code: this.land_info.region,
            region_name: this.land_info.region_name,
          },
        ];
      }
      // lbkey

      if (this.land_info.lbkey) {
        const keys = this.land_info.lbkey.split("_");
        const nos = keys[3].split("-");
        this.land_info.mno = nos[0];
        this.land_info.sno = nos[1];
      }
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
    clear() {
      this.land_info = {};
      this.resetCDR();
    },
    getInfo() {
      this.land_info.land_category_str = this.land_category.sort().join(",");
      this.land_info.land_ownerships_str = this.land_ownerships
        .sort()
        .join(",");

      // get the region_name
      this.land_info["region_name"] = getRegionName(
        this.regions,
        this.land_info.region
      );
      this.land_info["cdr"] = CommonUtility.getCDR(this.land_info);
      this.land_info["lbkey"] = CommonUtility.getLandKey(this.land_info);
      let val = _.cloneDeep(this.land_info);
      for (let key in val) {
        if (val[key] === "") {
          val[key] = null;
        }
      }
      return val;
    },
    onCategoryChange() {
      console.log("onCategoryChange", this.land_category.sort());
    },
    onOwnershipChange() {
      console.log("onOwnerShip", this.land_ownerships.sort());
    },
    remove_land() {
      if (this.prop_index > 0) {
        this.$emit("remove_land", this.prop_index);
        console.log("remove writable land info", this.prop_index);
      }
    },
    openMap(latitude, longitude) {
      console.log(`open map ${latitude} ${longitude}`);
      if (latitude == null || longitude == null) {
        return;
      }
      // <a href="https://www.google.com/maps?q=25.034,121.545" target="_blank">Open Google Maps</a>
      const linkElement = document.createElement("a");
      // 设置 href 属性为 Google 地图链接
      linkElement.href = `https://www.google.com/maps?q=${latitude},${longitude}`;
      // 设置 target 属性为 "_blank"，在新的标签页中打开链接
      linkElement.target = "_blank";
      // 设置 <a> 元素的文本内容
      linkElement.textContent = "Open Google Maps";
      window.open(linkElement.href, "_blank");
    },
  },
};
</script>
