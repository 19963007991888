const defaultCustomer = {
  full_name: null,
  id_full: null,
  email: null,
  id: null,
  gender: 0,
  company: null,
  job: null,
  customer_source: null,
  customer_type: null,
  data_source: null,
  birth: null,
  menu: false,
  addresses: [
    {
      address: null,
      type: 0,
    },
    {
      address: null,
      type: 0,
    },
    {
      address: null,
      type: 0,
    },
    {
      address: null,
      type: 0,
    },
  ],
  phones: [
    {
      number: null,
      type: 0,
    },
    {
      number: null,
      type: 0,
    },
    {
      address: null,
      type: 0,
    },
    {
      address: null,
      type: 0,
    },
  ],
  transactions: [
    {
      type: null,
      date: null,
      address: "",
      lkey_str: "",
      area: 0,
      price: 0,
    },
  ],
  inquires: [
    {
      type: null,
      comment: null,
      date: null,
      menu: false,
    },
  ],
};

const bind_type_enum = {
  Unknown: 0,
  Auto: 1,
  Manual: 2,
};

const bind_type_enum_str = [
  { text: "未指定", value: bind_type_enum.Unknown },
  { text: "自動合併", value: bind_type_enum.Auto },
  { text: "智能關聯", value: bind_type_enum.Manual },
];


export {
  defaultCustomer,
  bind_type_enum,
  bind_type_enum_str,
};