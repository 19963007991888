<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <div class="px-6 pt-2">
      <h5 class="text-h5 font-weight-bold text-typo">
        {{ title }}
      </h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <div class="d-sm-flex align-center ms-2">
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          @click="onCloseClick"
          class="font-weight-bolder btn-outline-secondary py-5 px-6 ms-auto"
          small
        >
          關閉
        </v-btn>
        <v-btn
          v-if="export_pdf"
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-primary
            bg-gradient-default
            py-5
            px-6
            ms-2
          "
          small
          @click="onExportPDF"
        >
          匯出PDF
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "customer-update-account",
  props: {
    title: {
      type: String,
      default: "",
    },
    export_pdf: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onCloseClick() {
      this.$emit("onClose");
    },
    onExportPDF() {
      this.$emit("onExportPDF");
    },
  },
};
</script>
