<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <v-row>
      <v-col cols="9">
        <h5 class="mx-4 text-h5 font-weight-bold text-grt">客戶詢問</h5>
      </v-col>
      <v-col cols="3" class="text-right">
        <v-btn
          v-if="propEditMode > edit_mode_enum.View"
          :ripple="false"
          elevation="0"
          class="mx-4 font-weight-bolder btn-default bg-gradient-primary"
          @click="onCreateInquire()"
        >
          新增詢問
          <v-icon right dark> mdi-account-plus-outline </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="related_headers"
          :options.sync="options"
          :items="related_info"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.name="{ item }" class="px-0">
            <v-text-field
              v-model="item.name"
              hide-details
              outlined
              :class="getClass(item)"
              :readonly="readOnly"
            >
            </v-text-field
          ></template>

          <template v-slot:item.phone="{ item }">
            <v-text-field
              v-model="item.phone"
              hide-details
              outlined
              :class="getClass(item)"
              :readonly="readOnly"
            >
            </v-text-field
          ></template>
          <template v-slot:item.mobile="{ item }">
            <v-text-field
              v-model="item.mobile"
              hide-details
              outlined
              :class="getClass(item)"
              :readonly="readOnly"
            >
            </v-text-field
          ></template>
          <template v-slot:item.address="{ item }">
            <v-text-field
              v-model="item.address"
              hide-details
              outlined
              :class="getClass(item)"
              :readonly="readOnly"
            >
            </v-text-field
          ></template>
          <template v-slot:item.email="{ item }">
            <v-text-field
              v-model="item.email"
              hide-details
              outlined
              :class="getClass(item)"
              :readonly="readOnly"
            >
            </v-text-field
          ></template>
          <template v-slot:item.context="{ item }">
            <v-text-field
              v-model="item.context"
              hide-details
              outlined
              :class="getClass(item)"
              :readonly="readOnly"
            >
            </v-text-field>
          </template>
          <template v-slot:item.sale_id="{ item }">
            <v-text-field
              v-if="readOnly"
              v-model="item.sale_name"
              hide-details
              outlined
              :class="getClass(item)"
            >
            </v-text-field>
            <v-autocomplete
              v-else
              :items="sales"
              v-model="item.sale_id"
              class="font-size-input border border-radius-md"
              outlined
              single-line
              hide-details
              item-value="user_id"
              :item-text="(item) => item.user.last_name + item.user.first_name"
              @change="onSaleSelect(item)"
            >
            </v-autocomplete>
          </template>
          <template v-slot:item.date="{ item }">
            <v-menu
              v-model="item.inquire_date_menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="readOnly"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  outlined
                  :value="displayChineseYear(item.date)"
                  v-bind="attrs"
                  v-on="on"
                  :class="getClass(item)"
                  :readonly="readOnly"
                  clearable
                  @click:clear="item.date = null"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="item.date"
                :year-format="formatChineseYear"
                locale="zh-tw"
                @input="item.inquire_date_menu = false"
              >
              </v-date-picker>
            </v-menu>
          </template>
          <template v-slot:item.total_price="{ item }">
            <v-text-field
              v-model="item.total_price"
              hide-details
              outlined
              placeholder="總價"
              class="font-size-input placeholder-er border border-radius-md"
              :disabled="readOnly"
              suffix="萬"
            >
            </v-text-field>
          </template>
          <template v-slot:item.signing_date="{ item }">
            <v-menu
              v-model="item.inquire_date_menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="readOnly"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  outlined
                  class="font-size-input placeholder-er border border-radius-md"
                  :value="displayChineseYear(item.signing_date)"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="readOnly"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="item.signing_date"
                :year-format="formatChineseYear"
                locale="zh-tw"
                @input="item.signing_date_menu = false"
              >
              </v-date-picker>
            </v-menu>
          </template>
          <template v-slot:item.singing_price="{ item }">
            <v-text-field
              v-model="item.singing_price"
              hide-details
              outlined
              placeholder="簽約金額"
              class="font-size-input placeholder-er border border-radius-md"
              :disabled="readOnly"
              suffix="萬"
            >
            </v-text-field>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip v-if="!readOnly && item.id == null" bottom>
              <template v-slot:activator="{ on }">
                <v-icon large v-on="on" @click.stop="removeRelated(item)"
                  >mdi-close</v-icon
                >
              </template>
              <span>移除</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- TODO inquire 換頁 -->
        <!-- <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#cb0c9f"
          v-model="page"
          :length="getPageCount()"
          circle
          :total-visible="15"
        ></v-pagination> -->
      </v-col>
    </v-row>

    <v-card-text v-if="false">
      <h6 class="text-h6 font-weight-bold text-typo">搜尋客戶</h6>
      <v-row>
        <v-col>
          <v-text-field
            v-model="customerInfo.full_name"
            hide-details
            outlined
            placeholder="姓名"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="customerInfo.full_id"
            hide-details
            outlined
            placeholder="身份證字號"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="customerInfo.company"
            hide-details
            outlined
            :placeholder="$t('Company')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="customerInfo.address"
            hide-details
            outlined
            :placeholder="$t('Address')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="customerInfo.phone"
            hide-details
            outlined
            :placeholder="$t('Phone')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn
            class="font-weight-bolder btn-default bg-gradient-default mr-2"
            :ripple="false"
            elevation="0"
            @click="searchCustomers()"
          >
            {{ $t("Search Customer") }}
            <v-icon right dark> mdi-account-search-outline </v-icon>
          </v-btn>
          <v-btn
            class="font-weight-bolder btn-outline-secondary mr-2"
            :ripple="false"
            elevation="0"
            @click="resetSearch()"
          >
            {{ $t("Reset") }}
            <v-icon right dark> mdi-restart </v-icon>
          </v-btn>
          <v-btn
            :ripple="false"
            elevation="0"
            class="font-weight-bolder btn-default bg-gradient-primary"
          >
            新增客戶
            <v-icon right dark> mdi-account-plus-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="items"
            :server-items-length="items_count"
            :loading="loading"
            hide-default-footer
            disable-pagination
            v-model="selected_customer"
            @click:row="rowClick"
            single-select
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-primary
                  px-6
                  mx-2
                  my-2
                  text-end
                "
                @click="addRelatedItem(item, false)"
              >
                {{ add_title }}
              </v-btn>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-default
                  px-6
                  mx-2
                  my-2
                  text-end
                "
                @click="onViewCustomer(item)"
              >
                檢視
              </v-btn>
            </template>
          </v-data-table>
          <v-pagination
            prev-icon="fa fa-angle-left"
            next-icon="fa fa-angle-right"
            class="pagination"
            color="#cb0c9f"
            v-model="page"
            :length="getPageCount()"
            circle
            :total-visible="10"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {
  case_related_type,
  inquire_headers,
  transaction_headers,
  readonly_inquire_headers,
  case_mode_enum,
} from "@/case.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { defaultCustomer } from "@/customers.js";
import { edit_mode_enum } from "@/definitions.js";
import CommonUtility from "@/util/CommonUtility.js";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import CustomerMixin from "./CustomerMixin.vue";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import UserMixin from "@/components/CRM/UserMixin.vue";
Vue.use(VueSweetalert2);
export default {
  name: "case-inquires",
  props: {
    propID: {
      type: Number,
      default: 0,
    },
    propInfo: {
      type: Array,
      default: () => [],
    },
    propType: {
      type: Number,
      default: 0,
    },
    propCaseName: {
      type: String,
      default: "",
    },
    propCaseType: {
      type: String,
      default: "",
    },
    propEditMode: {
      type: Number,
      default: edit_mode_enum.edit,
    },
    propSales: {
      type: Array,
      default: () => [],
    },
    propCaseMode: {
      type: Number,
      default: 0,
    },
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CustomerMixin, HttpCommonMixin, UserMixin],
  computed: {
    ...mapState(["user_id", "is_superuser"]),
    add_title() {
      return "詢問";
    },
    related_headers() {
      if (this.propEditMode <= this.edit_mode_enum.View) {
        return this.readonly_inquire_headers;
      }

      return this.inquire_headers;
    },
    readOnly() {
      return this.propEditMode == this.edit_mode_enum.View || this.propReadOnly;
    },
  },
  data() {
    return {
      case_related_type: case_related_type,
      inquire_headers: inquire_headers,
      transaction_headers: transaction_headers,
      readonly_inquire_headers: readonly_inquire_headers,
      case_mode_enum: case_mode_enum,
      selected_customer: [],
      customerInfo: {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
      },
      // edit
      edit_mode_enum: edit_mode_enum,
      edit_customer_mode: edit_mode_enum.View,
      defaultCustomer: defaultCustomer,
      edited_customer_item: {}, // for customer
      // inquires, transactions,..etc
      related_info: [
        {
          name: null,
          phone: null,
          mobile: null,
          email: null,
          address: null,
          customer_id: null,
        },
      ],
      related_info_count: 0,
      sales: [],
      init: false,
      // debug
      debug: false,
    };
  },
  mounted() {
    this.parseRelatedInfo(this.propInfo);
    this.setSales(this.propSales);
  },
  methods: {
    ...mapGetters(["isAllowViewAllInquiresCase"]),
    parseRelatedInfo(val) {
      console.log("parseRelatedInfo", val);
      if (this.init == true) {
        return;
      }
      this.init = true;
      if (val == null || val.length == 0) {
        if (this.propEditMode == edit_mode_enum.View) {
          return;
        }
        this.print_log("parseRelatedInfo", "val is null or empty");
        this.related_info = [
          {
            name: null,
            phone: null,
            mobile: null,
            email: null,
            address: null,
            customer_id: null,
            sale_id: this.isDraftMode() ? this.user_id : null,
            sale_name: null,
          },
        ];
        return;
      }
      console.log("set related info", val);
      if (this.isAllowViewAllInquiresCase()) {
        this.related_info = _.cloneDeep(val);
        // console.log("also allow view all principals", this.related_info);
      } else {
        this.related_info = _.cloneDeep(
          val.filter((x) => x.sale_id == this.user_id)
        );
        // console.log("only allow view my principals", this.related_info);
      }

      this.related_info_count = this.related_info.length;
      // console.log("[", this.title, "] parse related info", this.related_info);
    },
    setSales(val) {
      this.sales = _.cloneDeep(val);
    },
    resetSearch() {
      this.customerInfo = {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
      };
      this.items = [];
      this.items_count = 0;
    },
    searchCustomers() {
      this.getCustomerByCondition(this.customerInfo);
    },
    close() {
      this.edit_customer_mode = this.edit_mode_enum.View;
      this.print_log("close edit mode");
    },
    rowClick(item, row) {
      this.selected_customer = [item];
    },
    onCreateCustomerDone(item) {
      // this.items.push(item);
      // this.items_count = this.items.length;
    },
    onCreateInquire() {
      this.related_info.push({
        name: null,
        phone: null,
        mobile: null,
        email: null,
        address: null,
        customer_id: null,
        context: null,
        date: null,
        sale_id: this.isDraftMode() ? this.user_id : null,
        sale_name: null,
      });
    },
    onViewCustomer(item) {
      console.log("onViewCustomer", item);
      // const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/${item.id}`;
      // this.doHttpGet(url, null, (res) => {
      //   console.log("get customer", res);
      //   this.edited_customer_item = res;
      //   this.edit_customer_mode = this.edit_mode_enum.View;
      // });
    },
    addRelatedItem(item) {
      console.log("addRelatedItem", item);
      if (this.related_info.find((x) => x.customer_id === item.id)) {
        console.log("already exist");
        return;
      }

      item.inquire_date_menu = false;
      item.signing_date_menu = false;
      this.related_info.push(item);
      this.related_info_count = this.related_info.length;
    },
    removeRelated(item) {
      this.related_info = this.related_info.filter((x) => x.id != item.id);
      this.related_info_count = this.related_info.length;
    },
    getRelatedInfo() {
      let val = [];
      if (this.related_info == null || this.related_info.length == 0) {
        return val;
      }
      this.related_info.forEach((item) => {
        // if name is not empty
        if (item.name != null) {
          item["case_name"] = this.propCaseName;
          item["object_type"] = this.propCaseType;
          val.push(item);
        }
      });

      this.print_log("get related info", val);
      for (let key in val) {
        if (val[key] === "") {
          val[key] = null;
        }
      }
      return val;
    },
    print_log(...args) {
      if (this.debug) {
        console.log("[", this.title, "]", ...args);
      }
    },
    clear() {
      this.print_log("clear case related customer");
      this.items = [];
      this.items_count = 0;
      this.selected_customer = [];
      this.related_info = [];
      this.related_info_count = 0;
      this.sales = [];
      this.init = false;
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
    getClass() {
      if (this.readOnly) {
        return "";
      }
      return "font-size-input placeholder-er border border-radius-md";
    },
    onSaleSelect(item) {
      const sale_id = item.sale_id;
      let sale = this.sales.find((x) => x.user_id == sale_id);
      if (sale && sale.user) {
        item.sale_name = sale.user.last_name + sale.user.first_name;
      }
      console.log("onSaleSelect", item);
    },
    isDraftMode() {
      return this.propCaseMode == case_mode_enum.Draft;
    },
  },
};
</script>

<style scoped>
/deep/ tr.v-data-table__selected {
  background: #7d92f5 !important;
}
</style>
