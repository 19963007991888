<script>
import { mapGetters } from "vuex";
import CommonUtility from "@/util/CommonUtility.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
export default {
  name: "customer-base",
  data: (vm) => ({
    searchInfo: {
      full_name: null,
      id_full: null,
      company: null,
      address: null,
      phone: null,
      data_source: null,
      group: null,
    },
    page: 1,
    pageCount: 20,
    items_count: 0,
    itemsPerPage: 20,
    items: [],
    options: {},
    loading: false,
    keyword: null,
    headers: [
      {
        text: vm.$i18n.t("Full Name"),
        align: "start",
        cellClass: "border-bottom",
        sortable: false,
        value: "full_name",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        width: "15%",
      },
      {
        text: vm.$i18n.t("ID FULL"),
        cellClass: "border-bottom",
        value: "id_full",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        sortable: false,
        width: "15%",
      },
      {
        text: vm.$i18n.t("Address"),
        cellClass: "border-bottom",
        value: "address_1",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        sortable: false,
        width: "20%",
      },
      {
        text: vm.$i18n.t("Home"),
        cellClass: "border-bottom",
        value: "home",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        sortable: false,
        width: "15%",
      },
      {
        text: vm.$i18n.t("Mobile"),
        cellClass: "border-bottom",
        value: "mobile",
        sortable: false,
        width: "15%",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("Data Source"),
        cellClass: "border-bottom",
        value: "data_source",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
      {
        text: vm.$i18n.t("actions"),
        cellClass: "border-bottom",
        value: "actions",
        align: "end",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7 border-bottom",
      },
    ],
  }),
  mixins: [HttpCommonMixin],
  watch: {
    page(val) {
      this.onPageChange(val);
    },
  },
  methods: {
    ...mapGetters(["getToken"]),
    onPageChange(val) {
      this.getCustomerByCondition(this.searchInfo);
    },
    getCustomers() {
      const url = process.env.VUE_APP_SERVER_URL + "/api/v1/customers/";
      const params = {
        page: this.page,
        size: this.itemsPerPage,
      };

      this.loading = true;
      this.doHttpGet(
        url,
        params,
        this.setResult,
        (error) => console.log(error),
        () => (this.loading = false)
      );
    },
    getCustomer(id) {
      console.log(`try to get the customer detail by ${id}`);
      const token = this.getToken();
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/${id}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      this.loading = true;
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.setResultDetail(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateField(value, minLength, errorMessage) {
      if (value == null || value == undefined) {
        return false;
      }
      if (value.length < minLength) {
        alert(errorMessage);
        return false;
      }
      return true;
    },
    validateFullName(full_name) {
      return this.validateField(full_name, 1, "姓名至少要有1個字元");
    },
    validateAddress(address) {
      if (address == null || address == undefined) {
        return false;
      }
      if (address.indexOf("街") == -1 && address.indexOf("路") == -1) {
        alert("地址要有街或路");
        return false;
      }
      return true;
    },
    validateID(id) {
      return this.validateField(id, 6, "身份證至少六碼");
    },
    validateSearchInfo(info) {
      //  TODO remove this function later
      console.log("validateSearchInfo", info);
      if (info.full_name != null && info.full_name != undefined) {
        if (this.validateFullName(info.full_name) == false) {
          return false;
        }
      }
      if (info.address != null && info.address != undefined) {
        if (this.validateAddress(info.address) == false) {
          return false;
        }
      }
      if (info.id_full != null && info.id_full != undefined) {
        if (this.validateID(info.id_full) == false) {
          return false;
        }
      }
      return true;
    },
    getCustomerByCondition(info) {
      console.log("getCustomerByCondition", info);
      // let msg = CommonUtility.validateSearchInfo(info);
      // if (msg.length > 0) {
      //   return;
      // }
      const token = this.getToken();
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/`;
      this.searchInfo = info;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        params: {
          page: this.page,
          size: this.itemsPerPage,
        },
      };
      // iterator the info if the value is not null add into config.params[key] = value
      for (const [key, value] of Object.entries(info)) {
        if (value != null) {
          config["params"][key] = value;
        }
      }
      this.loading = true;
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getManualBind(id) {
      console.log("getManualBind", id);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-bind/${id}/preview-bind`;
      this.doHttpGet(
        url,
        {},
        this.onGetManualBindDone,
        (error) => console.log(error),
        () => (this.loading = false)
      );
    },
    onGetManualBindDone(data) {
      console.log("onGetManualBindDone", data);
      // console.log("setResult", result.items);

      data.customers.forEach((item) => {
        item["related"] = false;
        item["same"] = false;
      });
      let customers = data.customers;
      this.items_count = customers.length;
      this.items = customers;
    },
    getCaseRelationship(id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/${id}/case-relationship`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };
      this.doHttpGet(
        url,
        params,
        this.onRelatedChildren,
        (error) => console.log(error),
        () => (this.loading = false)
      );
    },
    getMergedChildren(id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/${id}/merged-children`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };
      this.doHttpGet(url, params, this.onMergedChildren, (error) =>
        console.log(error)
      );
    },
    onMergedChildren(data) {
      // set parent_relationships.relationship into each item of data.items
      data.items.forEach((item) => {
        item["relationship"] = "合併";
        item["cause"] = "";
        item["same"] = true;
        item["related"] = true;
        item["merged"] = true;
      });
      this.items_count = data.total;
      this.items = data.items;
    },
    onRelatedChildren(data) {
      // set parent_relationships.relationship into each item of data.items
      data.items.forEach((item) => {
        item["relationship"] = item.parent_relationships.relationship;
        item["cause"] = item.parent_relationships.cause;
        item["same"] = item.parent_relationships.same;
        item["related"] = true;
      });
      // console.log("onRelatedChildren", data.items);
      this.items_count = data.total;
      this.items = data.items;
    },

    bindCustomer(customer_id, related_id, relationship, cause, same) {
      const token = this.getToken();
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-bind/${customer_id}/manual-bind`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      let data = {
        related_id: related_id,
        relationship: relationship,
        cause: cause,
        same: same,
      };
      console.log("data", data);

      this.axios
        .put(url, data, config)
        .then(function (response) {
          currentObj.onBindDone("Bind Done", customer_id);
        })
        .catch(function (error) {
          console.log(error);
          currentObj.onBindFail(error);
        });
    },
    onBindFail(error) {
      console.log("onBindFail", error);
    },
    unbindCustomer(id, child_id) {
      const token = this.getToken();
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-bind/${id}/unbind/${child_id}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      let data = {};

      this.axios
        .put(url, data, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.onUnBindDone("UnBind Done");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setResult(result) {
      this.items_count = result.total;
      this.items = result.items;
    },
    un_merge_customer(id) {
      const token = this.getToken();
      // /api/v1/customers-bind/{customer_id}/un-merge
      const currentObj = this;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-bind/${id}/un-merge`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      this.axios.post(url, {}, config).then(function (response) {
        console.log(response.data);
        currentObj.onUnMergeDone(response.data);
      });
    },
    onUnMergeDone(data) {},
    setResultDetail(result) {
      console.log("setResultDetail", result);
    },
    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    clearItems() {
      this.items_count = 1;
      this.items = [];
    },
  },
};
</script>
