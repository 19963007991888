<template>
  <!-- card dropzone -->

  <v-card class="px-4 mx-2 py-2">
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"
    >
    </common-loading>
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <v-dialog v-model="preview_dialog" max-width="80%" max-height="800">
      <v-card class="rounded-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="preview_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img contain :src="preview_photo" max-height="800"></v-img>
      </v-card>
    </v-dialog>
    <v-card-title>
      <h5 class="text-h5 font-weight-bold text-grt">圖片</h5>
      <div class="ms-auto" v-show="isEditable">
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-5
            mx-2
            ms-auto
          "
          small
          @click="addFile()"
        >
          <v-icon class="me-2" size="12">fa fa-plus</v-icon>
          新增圖片
        </v-btn>
        <!-- <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="font-weight-bolder btn-default bg-gradient-primary py-5"
          small
          @click="uploadPhotos(prop_case_id)"
          :disabled="uploading"
          >上傳並儲存</v-btn
        > -->
      </div>
    </v-card-title>

    <!-- <v-row class="d-flex jus" > -->
    <v-row class="d-flex">
      <v-col cols="3" v-for="(item, index) in gallery" :key="index">
        <v-row class="mx-2">
          <label class="text-md font-weight-bolder my-2">{{
            item.title
          }}</label>
          <v-btn
            v-show="isEditable"
            class="ms-auto"
            icon
            small
            :color="item.id != null ? 'red' : 'grey'"
            @click="removeFile(item)"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-row>
        <dropzone
          v-show="item.status != photo_status.Done"
          v-model="item.files"
          ref="dropZones"
          disabled
        ></dropzone>
        <div
          v-show="item.status == photo_status.Done"
          class="
            dropzone
            mb-3
            mt-3
            dz-clickable
            dropzone-single
            dz-max-files-reached
          "
          @click="showImage(item, index)"
        >
          <div class="dz-preview dz-preview-single">
            <div class="dz-preview-cover dz-image-preview">
              <v-img class="dz-preview-img" :src="getFile(item.files)"></v-img>
            </div>
          </div>
          <div class="dz-default dz-message">
            <button class="dz-button" type="button">檢視</button>
          </div>
        </div>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="item.status == photo_status.Pending"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import { edit_mode_enum } from "@/definitions.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import Dropzone from "./Dropzone.vue";
import { mapGetters } from "vuex";
import { photo_type_enum, photo_type_enum_str, photo_status } from "@/case.js";
import CommonUtility from "@/util/CommonUtility.js";
export default {
  name: "case-gallery",
  // multiple drop zones
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    photo_base: {
      type: String,
      default: "",
    },
    prop_case_id: {
      type: Number,
      default: -1,
    },
    propEditMode: {
      type: Number,
      default: edit_mode_enum.View,
    },
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit_mode_enum: edit_mode_enum,
      del_dialog: false,
      del_photo_id: null,
      preview_dialog: false,
      preview_photo: null,
      preview_title: null,
      preview_files: [],
      preview_model: 0,
      photo_type_enum: photo_type_enum,
      photo_type_enum_str: photo_type_enum_str,
      photo_status: photo_status,
      uploading: false,
      columns: 4,
      case_id: -1,
      fileSingle: [],
      uploadFiles: [],
      defaultFiles: [
        {
          title: "首圖(照片)",
          type: photo_type_enum.Cover,
          files: [],
          status: photo_status.Empty,
          id: null,
          order: 0,
        },
        {
          title: "地圖/地籍圖",
          type: photo_type_enum.Location,
          files: [],
          status: photo_status.Empty,
          id: null,
          order: 1,
        },
        {
          title: "格局圖",
          type: photo_type_enum.CadastralMap,
          files: [],
          status: photo_status.Empty,
          id: null,
          order: 2,
        },

        {
          title: "圖片",
          type: photo_type_enum.Other,
          files: [],
          status: photo_status.Empty,
          id: null,
          order: 3,
        },
        {
          title: "圖片",
          type: photo_type_enum.Other,
          files: [],
          status: photo_status.Empty,
          id: null,
          order: 4,
        },
      ],
      thumbnailUrl: null,
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
    };
  },
  mixins: [HttpCommonMixin],
  mounted: function () {
    this.parsePhotoInfo(this.photos);
    this.case_id = this.prop_case_id;
  },
  watch: {
    photos: {
      handler: function (val, oldVal) {
        this.parsePhotoInfo(val);
      },
      deep: true,
    },
    prop_case_id: {
      handler: function (val, oldVal) {
        this.case_id = val;
      },
    },
  },
  components: { Dropzone, CommonConfirm, CommonLoading },
  computed: {
    gallery() {
      if (this.propEditMode == edit_mode_enum.View || this.propReadOnly == true) {
        return this.uploadFiles.filter((item) => this.hasFile(item));
      }
      return this.uploadFiles;
    },
    isEditable() {
      if(this.propReadOnly == true){
        return false;
      }
      return (
        this.propEditMode == edit_mode_enum.Edit ||
        this.propEditMode == edit_mode_enum.Create
      );
    },
    hasPendingPhotos() {
      // iterate the upload files , if the item.id is null and item.files.length > 0, return true
      let res = this.uploadFiles.some((item) => {
        if (item.id == null && item.files.length > 0) {
          return true;
        }
      });
      return res;
    },
  },
  methods: {
    ...mapGetters(["getToken"]),
    clear() {
      if (this.$refs.dropZones) {
        console.log("clear gallery", this.$refs.dropZones.length);
        for (let i = 0; i < this.$refs.dropZones.length; i++) {
          this.$refs.dropZones[i].dropzone.removeAllFiles();
        }
      }
      this.uploadFiles = JSON.parse(JSON.stringify(this.defaultFiles));
    },
    parsePhotoInfo(val) {
      this.uploadFiles = JSON.parse(JSON.stringify(this.defaultFiles));
      if (val.length == 0) {
        console.log(this.uploadFiles);
        return;
      }
      console.log("pares photo info", val);
      let photos = JSON.parse(JSON.stringify(val));
      // sort the photos by order
      photos.sort((a, b) => a.order - b.order);

      this.uploadFiles.forEach((item) => {
        const tmp = photos.find((x) => x.type == item.type);
        if (tmp != null) {
          item.id = tmp.id;
          item.files = [this.photo_base + tmp.key];
          item.status = photo_status.Done;
          photos.splice(photos.indexOf(tmp), 1);
        }
      });
      photos.forEach((item) => {
        this.uploadFiles.push({
          id: item.id,
          title: "圖片",
          type: item.type,
          files: [this.photo_base + item.key],
          status: photo_status.Done,
        });
      });
      // console.log("parsePhotoInfo==>", this.uploadFiles);
    },
    addFile() {
      this.uploadFiles.push({
        title: "圖片",
        type: photo_type_enum.Other,
        files: [],
        status: photo_status.Empty,
        id: null,
        order: null,
      });
    },
    hasFile(item) {
      return item.files.length > 0;
    },
    removeFile(item) {
      switch (item.status) {
        case photo_status.Empty:
        case photo_status.Pending:
          {
            const index = this.uploadFiles.indexOf(item);
            if (
              this.$refs.dropZones[index] &&
              this.$refs.dropZones[index].dropzone
            ) {
              this.$refs.dropZones[index].dropzone.removeAllFiles();
            }
            console.log("clear item", item);
          }
          break;
        case photo_status.Done:
          console.log("Delete by s3", item.id);
          // if the uploadFiles is only 1, not allow to remove
          // get the files count of uploadFiles
          let count = 0;
          this.uploadFiles.forEach((item) => {
            if (item.files.length > 0 && item.id != null) {
              count += 1;
            }
          });

          if (count == 1) {
            this.loading = {
              dialog: true,
              title: "錯誤",
              message: "至少要有一張圖片",
              show_loading: false,
              show_confirm: true,
            };
            return;
          }
          if (item.id != null) {
            this.deletePhoto(item.id);
          }

          break;
      }
    },
    uploadPhotos(case_id, created) {
      console.log(this.uploadFiles);
      if (this.hasPendingPhotos == false) {
        console.log("no pending.");
        return;
      }
      this.uploading = true;
      const promises = this.uploadFiles.map((item, index) => {
        if (item.files.length === 0 || item.status === photo_status.Done) {
          return Promise.resolve(); // Resolve immediately if no file or status is Done
        }
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.getToken(),
          },
        };

        item.status = photo_status.Pending;
        const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/${case_id}/upload`;
        const formData = new FormData();
        formData.append("type", item.type);
        formData.append("file", item.files[item.files.length - 1]); //get the last one
        formData.append("order", index);
        // 2. 返回上传请求的promise
        return this.axios
          .post(url, formData, config)
          .then((response) => (item.id = response.data.id))
          .catch((error) => console.error(error))
          .finally(() => (item.status = photo_status.Done));
      });
      Promise.all(promises)
        .then(() => {
          // All uploadFileSuccess completed
          // Call the done function here
          console.log(
            "All uploadFileSuccess completed.",
            this.prop_case_id,
            " para:",
            case_id
          );

          this.onAllFilesUploaded(created);
        })
        .catch((error) => {
          // Handle any errors that occurred during the upload process
          console.error("Error occurred during upload:", error);
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    onAllFilesUploaded(created) {
      console.log("all upload done", created);
      this.$emit("reload", created);
    },
    deletePhoto(photo_id) {
      this.del_photo_id = photo_id;
      // this.del_dialog = true;
      this.confirm.title = "刪除圖片";
      this.confirm.message = "確定要刪除圖片嗎?";
      this.confirm.dialog = true;
      this.confirm.function = this.confirmDeletePhoto;
    },
    confirmDeletePhoto() {
      this.confirm.dialog = false;
      this.loading = {
        dialog: true,
        title: "刪除中",
        message: "刪除中...",
        show_loading: true,
        show_confirm: false,
      };
      this.del_dialog = false;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/photo/${this.del_photo_id}`;
      this.doHttpDelete(url, this.onDeleteSuccess, this.onDeleteFail, () => {
        this.reloadFiles();
        this.del_photo_id = null;
      });
    },
    onDeleteSuccess(data) {
      // this.$emit("reload");
      this.loading = {
        dialog: true,
        message: "刪除成功",
        show_loading: false,
        show_confirm: true,
      };
    },
    onDeleteFail(data) {
      const msg = CommonUtility.parseError(data.response.data.detail);
      this.loading = {
        dialog: true,
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
    },
    reloadFiles() {
      // /api/v1/cases/{case_id}/photo/
      console.log("Reload files...");
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/${this.case_id}/photo/`;
      this.doHttpGet(url, {}, this.onReloadFiles);
    },
    onReloadFiles(data) {
      console.log("reload files", data);
      this.clear();
      this.parsePhotoInfo(data.photos);
    },
    getFile(files) {
      if (files.length == 0) {
        return null;
      }
      const item = files[files.length - 1];
      if (item instanceof File) {
        return item.dataURL;
      }

      return item;
    },
    showImage(item, index) {
      console.log("show image", this.uploadFiles);
      // get the files value from this.uploadFiles
      this.preview_files = [];
      this.uploadFiles.forEach((item) => {
        console.log(item.files);
        if (item.files.length == 0) {
          return;
        }
        let obj = item.files[item.files.length - 1];
        if (obj instanceof File) {
          this.preview_files.push(obj.dataURL);
          return;
        } else {
          this.preview_files.push(obj);
        }
      });
      this.preview_model = index;

      let filename = this.getFile(item.files);
      this.preview_title = item.title;
      this.preview_photo = filename;
      this.preview_dialog = true;
    },
  },
};
</script>
<style></style>
