<template></template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import CommonUtility from "@/util/CommonUtility.js";
export default {
  name: "CaseMixin",
  data() {
    return {
      case_items: [],
      case_items_count: 0,
      page: 1,
      itemsPerPage: 10,
      search: {},
      debug: false,
      options: {},
      colors: {
        disable: "grey",
        enable: "red",
      },
      data_loading: false,
    };
  },
  mixins: [HttpCommonMixin],
  computed: {},
  methods: {
    print_log(...args) {
      if (this.debug === true) {
        console.log("[CaseMixin]", ...args);
      }
    },
    getInfo() {
      let case_info = {};
      return case_info;
    },
    checkInfo(data) {
      // check info
      const check_list =
        data.sale_or_rent == 0
          ? [
              { name: "name", msg: "請輸入案件名稱" },
              { name: "unit_price", msg: "請輸入單價" },
              { name: "total_price", msg: "請輸入總價" },
            ]
          : [
              { name: "name", msg: "請輸入案件名稱" },
              { name: "total_price", msg: "請輸入租金" },
            ];
      for (let i = 0; i < check_list.length; i++) {
        if (data[check_list[i].name] == null) {
          return check_list[i].msg;
        }
      }
      return null;
    },
    createCase(data) {
      this.print_log("create case by mixin");
      let url = process.env.VUE_APP_SERVER_URL + "/api/v1/cases/";
      this.doHttpPost(url, data, this.onCreateDone, this.onCreateFail);
    },
    createMyCase(data) {
      console.log("create my case by mixin");
      let url = process.env.VUE_APP_SERVER_URL + "/api/v1/my-cases/";
      this.doHttpPost(url, data, this.onCreateDone, this.onCreateFail);
    },
    onCreateDone(data) {
      this.print_log("onCreateDone by mixin", data);
    },
    onCreateFail(data) {
      this.print_log("onCreateFail by mixin", data);
    },
    updateMyCase(case_id) {
      this.print_log("update case by mixin");
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/my-cases/${case_id}`;
      const data = this.getInfo();
      this.doHttpPut(url, data, this.onUpdateDone, this.onUpdateDoneFail);
    },
    updateCase(case_id) {
      this.print_log("update case by mixin");
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/${case_id}`;
      const data = this.getInfo();
      this.doHttpPut(url, data, this.onUpdateDone, this.onUpdateDoneFail);
    },
    onUpdateDone(data) {
      this.print_log("onUpdateDone by mixin", data);
    },
    onUpdateDoneFail(data) {
      this.print_log("onUpdateDoneFail by mixin", data);
    },
    getMyCases() {
      return this.getCases(
        process.env.VUE_APP_SERVER_URL + "/api/v1/my-cases/"
      );
    },
    getCases(url = null) {
      this.print_log("Get the case by mixin");
      let target = null;
      if (url == null) {
        target = process.env.VUE_APP_SERVER_URL + "/api/v1/cases/";
      } else {
        target = url;
      }
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };
      const { sortBy, sortDesc } = this.options;
      if (sortBy.length > 0) {
        const sortParas = CommonUtility.getSortParas(sortBy, sortDesc);
        params.ordering = sortParas;
      }
      // console.log("sortBy", sortBy, "sortDesc", sortDesc);
      for (let key in this.search) {
        const val = this.search[key];
        this.print_log("key", key, " type ", typeof val);
        if (val != null) {
          if (typeof val === "number") {
            params[key] = val;
            this.print_log("search number", key, val);
          } else if (Array.isArray(val) && val.length > 0) {
            this.print_log("search array", key, val);
            params[key] = val.join(",");
          } else if (typeof val == "string" && val.length > 0) {
            params[key] = val;
          }
          // bool type
          else if (typeof val == "boolean") {
            params[key] = val;
          }
        } else {
        }
      }
      this.data_loading = true;
      this.doHttpGet(target, params, this.setResult, null, this.getCaseFinal);
    },
    setResult(res) {
      this.case_items = res.items;
      // this.case_items = res.items.map((item) => {
      //   const newItem = { ...item };
      //   // add extra get_date for newItem
      //   newItem.reason_date_menu = false;
      //   newItem.completion_date_menu = false;
      //   newItem.signing_date_menu = false;
      //   newItem.fill_date_menu = false;
      //   return newItem;
      // });
      this.case_items_count = res.total;
      // console.log("get case done");
    },
    getCaseFinal() {
      this.data_loading = false;
    },
    getCaseByID(case_id) {
      this.print_log("Get the case by mixin ", case_id);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/${case_id}`;
      let params = {};
      this.doHttpGet(url, params, this.setCaseDetail, null, this.onFinal);
    },
    setCaseDetail(data) {
      this.print_log("set case detail by mixin", data);
    },
    isAllowLocation(info) {
      return (
        info != null &&
        info.latitude != null &&
        info.longitude != null &&
        info.latitude != "" &&
        info.longitude != ""
      );
    },
    getColor(info) {
      if (this.isAllowLocation(info)) {
        return this.colors.enable;
      }
      return this.colors.disable;
    },
  },
};
</script>
