<template>
  <v-card class="card-shadow border-radius-xl my-2">
    <div class="px-6 pt-2">
      <h5 class="text-h5 font-weight-bold text-typo">
        {{ title }}
      </h5>
    </div>
    <div class="px-6 pb-2 pt-2">
      <div class="d-sm-flex align-center ms-2">
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          @click="onResetClick"
          class="font-weight-bolder btn-outline-secondary py-5 px-6 ms-auto"
          small
        >
          {{ $t("Reset") }}
        </v-btn>
        <v-btn
          :ripple="false"
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-primary
            bg-gradient-primary
            py-5
            px-6
            ms-2
          "
          small
          @click="onUpdateClick"
        >
          {{ $t("Update") }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "customer-update-account",
  props: {
    title: {
      type: String,
      default: "更新",
    },
  },
  data() {
    return {};
  },
  methods: {
    onUpdateClick() {
      this.$emit("onUpdate");
    },
    onResetClick() {
      this.$emit("onRestore");
    },
    onExportClick() {
      this.$emit("onExport");
    },
  },
};
</script>
