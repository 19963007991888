<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <v-card-title>
      <h5 class="text-h5 font-weight-bold text-typo">
        {{ $t("Bind Customer") }}
      </h5>
    </v-card-title>
    <div class="px-6 py-2 pt-0">
      <customer-detail-dialog
        :propEditMode="edit_mode_enum.View"
        :propEditItem="view_customer_item"
        :propDisplay="view_dialog"
        v-on:close="close"
      ></customer-detail-dialog>
      <common-loading
        :title="loading.title"
        :message="loading.message"
        :loading_dialog="loading.dialog"
        :show_loading="loading.show_loading"
        :show_confirm="loading.show_confirm"
        :confirm_function="loading.confirm_function"
        v-on:confirm="loading.dialog = false"
      >
      </common-loading>
      <common-confirm
        :title="confirm.title"
        :message="confirm.message"
        :confirm_dialog="confirm.dialog"
        :confirm_function="confirm.function"
        v-on:confirm="confirm.dialog = false"
        v-on:cancel="confirm.dialog = false"
      ></common-confirm>
      <!-- <v-row no-gutters v-if="propEditMode > 1"> -->
      <v-row no-gutters>
        <v-col cols="12" sm="1" class="py-0 px-0">
          <v-text-field
            hide-details
            outlined
            class="text-md font-weight-bolder mt-2"
            value="群組"
          >
          </v-text-field>
        </v-col>
        <v-chip-group
          v-model="chip_select"
          mandatory
          active-class="primary--text"
          column
        >
          <v-chip
            v-for="chip in chips"
            :key="chip.value"
            @click="filterByGroup(chip.value, true)"
          >
            {{ chip.text }}
          </v-chip>
        </v-chip-group>
        <!-- <v-col>
          <v-text-field
            v-model="searchInfo.full_name"
            hide-details
            outlined
            class="text-md font-weight-bolder mt-2 border border-radius-md"
          >
          </v-text-field>
        </v-col> -->
      </v-row>
      <v-row no-gutters v-if="false">
        <v-col>
          <v-text-field
            v-model="searchInfo.full_name"
            hide-details
            outlined
            placeholder="姓名"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <!-- <v-col>
          <v-text-field
            v-model="searchInfo.full_id"
            hide-details
            outlined
            placeholder="身份證字號"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col> -->
        <v-col>
          <v-text-field
            v-model="searchInfo.company"
            hide-details
            outlined
            :placeholder="$t('Company')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="searchInfo.address"
            hide-details
            outlined
            :placeholder="$t('Address')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="searchInfo.phone"
            hide-details
            outlined
            :placeholder="$t('Phone')"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col cols="auto" class="">
          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="
              font-weight-bolder
              btn-primary
              bg-gradient-default
              py-5
              px-6
              ms-2
            "
            @click="searchCustomers()"
          >
            {{ $t("Search") }}
            <!-- <v-icon right dark> mdi-account-search-outline </v-icon> -->
          </v-btn>
          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="
              font-weight-bolder
              btn-primary
              bg-gradient-primary
              py-5
              px-6
              ms-2
            "
            @click="doSmartSearch()"
          >
            {{ $t("Smart") }}
            <!-- <v-icon right dark> mdi-account-search-outline </v-icon> -->
          </v-btn>
          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="font-weight-bolder btn-outline-secondary py-5 px-6 ms-2"
            small
            @click="reset()"
          >
            {{ $t("Reset") }}
            <v-icon right dark> mdi-close-circle-outline </v-icon>
          </v-btn>
          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="
              font-weight-bolder
              btn-default
              bg-gradient-info
              py-5
              px-6
              ms-2
            "
            small
            @click="getRelated()"
          >
            已關聯
          </v-btn>
        </v-col>
      </v-row>

      <v-col col="12" class="mx-0 px-0">
        <v-data-table
          :headers="bind_headers"
          :options.sync="options"
          :items="items"
          :server-items-length="items_count"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.address_1="{ item }">
            {{ getAddress_1(item) }}
          </template>
          <template v-slot:item.home="{ item }">
            {{ getPhoneByType(item, phone_type_enum.Home) }}
          </template>
          <template v-slot:item.mobile="{ item }">
            {{ getPhoneByType(item, phone_type_enum.Mobile) }}
          </template>
          <template v-slot:item.relationship="{ item }">
            {{ item.relationship }}
          </template>

          <template v-slot:item.cause="{ item }">
            {{ item.cause }}
            <!-- <v-text-field
              hide-details
              outlined
              :class="text_edit_class"
              v-model="item.cause"
              :disabled="item.related == true"
            >
            </v-text-field> -->
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="mx-0 px-0">
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-default
                  py-5
                  px-4
                  mt-2
                  mb-2
                  me-2
                  text-end
                "
                @click="viewCustomer(item.id)"
              >
                {{ $t("View") }}
              </v-btn>
              <v-btn
                v-if="item.merged == true && isAllowUpdateCustomer()"
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-danger
                  py-5
                  px-4
                  mt-2
                  mb-2
                  me-2
                  text-end
                "
                @click="onUnMergeConfirm(item.id)"
              >
                解除合併
              </v-btn>
              <!-- <v-btn
                v-if="item.related == true"
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-danger
                  py-5
                  px-4
                  mt-2
                  mb-2
                  me-2
                  text-end
                "
                @click="onUnbind(item.id)"
              >
                {{ $t("UnBind") }}
              </v-btn>
              <v-btn
                v-else
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-primary
                  py-5
                  px-4
                  mt-2
                  mb-2
                  me-2
                  text-end
                "
                @click="onBindingConfirm(item)"
              >
                {{ $t("Bind") }}
              </v-btn> -->
            </div>
          </template>
        </v-data-table>
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#cb0c9f"
          v-model="page"
          :length="getPageCount()"
          circle
          :total-visible="15"
        ></v-pagination>
      </v-col>
    </div>
  </v-card>
</template>
<script>
import {
  phone_type_enum,
  common_text_edit_class,
  edit_mode_enum,
} from "@/definitions.js";
import CommonUtility from "@/util/CommonUtility.js";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import CustomerMixin from "./CustomerMixin.vue";
import CustomerGroupMixin from "@/views/Pages/CRM/CustomerGroupMixin.vue";
import { mapGetters } from "vuex";
export default {
  name: "customer-bind-customer",
  props: {
    propEditMode: {
      type: Number,
      default: 0,
    },
    propEditInfoID: {
      type: Number,
      default: null,
    },
    propGroups: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [CustomerMixin, CustomerGroupMixin],
  components: {
    CommonLoading,
    CommonConfirm,
    CustomerDetailDialog: () => import("../CustomerDetailDialog.vue"),
  },
  data(vm) {
    return {
      chip_select: -1,
      chips: [
        { text: "案件", value: -1 },
        { text: "合併", value: -100 },
      ],
      chip_group: null,
      name: null,
      name_title: vm.$i18n.t("Name"),
      full_id: null,
      company: null,
      address: null,
      phone: null,
      searchInfo: {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
        current_id: null,
        group: null,
      },
      bind_headers: [
        {
          text: vm.$i18n.t("Full Name"),
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "full_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
          width: "10%",
        },
        {
          text: vm.$i18n.t("Address"),
          cellClass: "border-bottom",
          value: "address_1",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
          sortable: false,
          width: "15%",
        },
        {
          text: vm.$i18n.t("Home"),
          cellClass: "border-bottom",
          value: "home",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
          sortable: false,
          width: "10%",
        },
        {
          text: vm.$i18n.t("Mobile"),
          cellClass: "border-bottom",
          value: "mobile",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
          width: "10%",
        },
        {
          text: "理由",
          cellClass: "border-bottom",
          value: "cause",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7 ",
          width: "10%",
        },
        {
          text: "關係",
          cellClass: "border-bottom",
          value: "relationship",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
          width: "20%",
        },
        {
          text: vm.$i18n.t("actions"),
          cellClass: "border-bottom",
          value: "actions",
          align: "end",
          sortable: false,
          class: "font-weight-bolder opacity-7",
          width: "15%",
        },
      ],
      text_edit_class: common_text_edit_class,
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
      edit_item: null,
      phone_type_enum: phone_type_enum,
      edit_mode_enum: edit_mode_enum,
      view_customer_item: {},
      view_dialog: false,
    };
  },
  watch: {
    propEditInfoID: {
      handler: function () {
        this.getChildren();
      },
    },
    deep: true,
    propGroups: {
      handler: function (val) {
        this.updateClips(val);
      },
    },
  },

  mounted() {
    this.getChildren();
  },
  methods: {
    ...mapGetters(["isAllowUpdateCustomer"]),
    onPageChange(val) {
      if (this.chip_group != null) {
        this.page = val;
        this.filterByGroup(this.chip_group, false);
      }
    },
    updateClips(groups) {
      this.chips = [
        { text: "案件", value: -1 },
        { text: "合併", value: -100 },
      ];
      if (groups == null || groups == undefined) {
        return;
      }
      // iterate groups
      groups.forEach((group) => {
        // check if group has children
        // console.log("update group", group);
        let label = this.getLabelById(group.group_id);
        this.chips.push({ text: label, value: group.group_id });
      });
    },
    getChildren() {
      if (this.propEditInfoID != null && this.propEditInfoID != undefined) {
        this.getCaseRelationship(this.propEditInfoID);
      }
    },
    clear() {
      this.searchInfo = {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
        current_id: null,
        group: null,
      };
      this.chips = [
        { text: "案件", value: -1 },
        { text: "合併", value: -100 },
      ];
      this.chip_select = -1;
      this.chip_group = null;
    },
    searchCustomers() {
      // TODO add condition checker by request
      this.searchInfo.current_id = this.propEditInfoID;
      console.log("search customer", this.searchInfo);
      this.getCustomerByCondition(this.searchInfo);
    },
    onBindingConfirm(item) {
      this.edit_item = item;
      console.log("edit_item", this.edit_item);
      this.confirm = {
        dialog: true,
        title: "關聯",
        message: "確認建立關聯",
        function: this.onBinding,
      };
    },
    onBinding() {
      this.confirm.dialog = false;
      this.loading = {
        dialog: true,
        title: "關聯",
        message: "建立關聯",
        show_loading: true,
        show_confirm: false,
      };
      this.bindCustomer(
        this.propEditInfoID,
        this.edit_item.id,
        this.edit_item.relationship,
        this.edit_item.cause,
        this.edit_item.same
      );
    },
    onBindDone(message, id) {
      console.log("action done", message, " id", id);
      this.name = null;
      this.loading = {
        dialog: true,
        title: "關聯",
        message: "建立關聯",
        show_loading: false,
        show_confirm: true,
        confirm_function: this.refreshCustomer,
      };
    },
    refreshCustomer() {
      console.log("Refresh Customer");
      this.getChildren();
      this.edit_item = null;
      this.$emit("onBindChange", this.propEditInfoID);
    },
    onBindFail(error) {
      let msg = CommonUtility.parseError2(error);
      this.loading = {
        dialog: true,
        title: "關聯失敗",
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
      this.edit_item = null;
    },
    onUnbind(id) {
      console.log("onUnbind", this.propEditInfoID, " id", id);
      this.confirm = {
        dialog: true,
        title: "關聯",
        message: "確認解除關聯",
        // function: this.unbindCustomer(id),
        function: () => {
          this.confirm.dialog = false;
          this.unbindCustomer(this.propEditInfoID, id);
        },
      };
    },
    onUnBindDone() {
      this.loading = {
        dialog: true,
        title: "關聯",
        message: "解除成功",
        show_loading: false,
        show_confirm: true,
        confirm_function: this.refreshCustomer,
      };
    },
    onUnMergeConfirm(id) {
      console.log("UnMerge", this.propEditInfoID, " id", id);
      this.confirm = {
        dialog: true,
        title: "合併",
        message: "確認解除合併",
        function: () => {
          this.confirm.dialog = false;
          this.un_merge_customer(id);
        },
      };
    },
    onUnMergeDone(data) {
      this.loading = {
        dialog: true,
        title: "合併",
        message: "合併解除成功",
        show_loading: false,
        show_confirm: true,
        confirm_function: () => {
          console.log("onUnMergeDone", data);
          this.$emit("unmerged_customer");
        },
      };
    },
    reset() {
      this.clearItems();
    },
    doSmartSearch() {
      console.log("do smart search");
      this.clearItems();
      if (this.propEditInfoID != null) {
        console.log("doSmartSearch", this.propEditInfoID);
        this.getManualBind(this.propEditInfoID);
      }
    },
    viewCustomer(customer_id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/${customer_id}`;
      this.doHttpGet(url, null, (res) => {
        console.log("get customer", res);
        this.view_customer_item = res;
        this.view_dialog = true;
      });
    },
    close() {
      this.view_customer_item = {};
      this.view_dialog = false;
    },
    checkSamePerson(item) {
      console.log("checkSamePerson", item.same);
      if (item.same == true) {
        item.relationship = "本人";
      } else {
        item.relationship = "";
      }
    },
    getRelated() {
      console.log("getRelated", this.propEditInfoID);
      this.getCaseRelationship(this.propEditInfoID);
    },
    getAddress_1(item) {
      if (item.addresses != null && item.addresses.length > 0) {
        return item.addresses[0].address;
      }
      return null;
    },
    getPhoneByType(item, phone_type) {
      if (item.phones == null) {
        return null;
      }
      for (let i = 0; i < item.phones.length; i++) {
        let phone = item.phones[i];

        if (phone.type == phone_type) {
          return phone.number;
        }
      }
      return null;
    },
    filterByGroup(group_id, chip_changed) {
      if (chip_changed == true) {
        this.page = 1;
      }
      this.chip_group = group_id;
      if (group_id == -1) {
        this.getChildren();
        return;
      } else if (group_id == -100) {
        this.getMergedChildren(this.propEditInfoID);
        return;
      }
      // this.getCustomerByGroup(group_id);
      this.searchInfo.group = group_id;
      this.getCustomerByCondition(this.searchInfo);
    },
    setResult(data) {
      let label = this.getLabelById(this.searchInfo.group);
      data.items.forEach((item) => {
        item["relationship"] = label;
        item["cause"] = "群組";
        item["same"] = false;
        item["related"] = true;
      });
      // console.log("onRelatedChildren", data.items);
      this.items_count = data.total;
      this.items = data.items;
    },
  },
};
</script>
