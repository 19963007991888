<template></template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { city_info, district_info } from "@/cdr.js";

export default {
  name: "CaseMixin",
  data() {
    return {
      city_info: city_info,
      district_info: district_info,
      districts: [],
      regions: [],
      roads: [],
    };
  },
  mixins: [HttpCommonMixin],
  methods: {
    onSelectCity(val) {
      this.districts = this.district_info[val];
    },
    onSelectDistrict(city, district, isLand) {
      if (isLand) {
        if (city && district) {
          this.get_regions(city, district);
        }
      } else {
        if (city && district) {
          this.get_roads(city, district);
        }
      }
    },
    get_roads(city, district) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cdrs/roads`;
      let params = {
        city: city,
        district: district,
      };
      this.doHttpGet(url, params, (response) => {
        this.roads = [{ road_name: "", road_code: null }];
        this.roads.push(...response);
      });
    },

    get_regions(city, district) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cdrs/regions`;
      // console.log("Mixin Get region data from server", url);
      let params = {
        city: city,
        district: district,
      };
      this.doHttpGet(url, params, (response) => {
        this.regions = response;
      });
    },
    resetCDR() {
      this.districts = [];
      this.regions = [];
      this.roads = [];
    },
  },
};
</script>
